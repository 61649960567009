import React, { useEffect, useState } from 'react';
import { Mail, Phone, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';



// const ClientInquiryList = () => {
//   const [activeTab, setActiveTab] = useState('All');
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const token = Cookies.get('authToken');
//   const itemsPerPage = 10;
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const tabs = ['All', 'With Meetings', 'Only Message'];

//   useEffect(() => {
//     const fetchData = async () => {
//       let headers = {
//         Authorization: `Bearer ${token}`,
//       };
//       try {
//         const response = await axios.get(process.env.REACT_APP_BASE_URL + `profile/enquiries?page=${currentPage-1}&size=${itemsPerPage}`, { headers: headers });

//         console.log(response)
//         if (response.status === 200) {
//           const data = response?.data?.enquiries;
//           setData(data);
//           setIsLoading(false);
//         } else {
//           toast.warning('Failed to fetch data. Please try again later.');
//           setIsLoading(false);
//         }
//       } catch (error) {
//         toast.warning('Failed to fetch data. Please try again later.');
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);



//   // Pagination Buttons
//   const handlePreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   return (
//     <div className='bg-white h-[90%]'>
//       <div className="p-4">
//         <div className='text-3xl md:text-4xl font-bold mb-5 text-[#1e1e1e]'>Inquiries</div>
//         <div className='flex flex-col md:flex-row justify-between items-center'>
//           <div className="mb-4 flex space-x-2 bg-[#F8F9F8] rounded-md p-1 w-fit">
//             {tabs.map((tab) => (
//               <button
//                 key={tab}
//                 className={`px-4 py-2 rounded-md transition-colors duration-200 ${activeTab === tab
//                   ? 'bg-white text-[#1E1E1E]'
//                   : 'bg-transparent text-[#989898]'
//                   }`}
//                 onClick={() => setActiveTab(tab)}
//               >
//                 {tab}
//               </button>
//             ))}
//           </div>
//           {/* Pagging */}
//           <div className="w-fit flex items-center space-x-2">
//             <button
//               className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
//               onClick={handlePreviousPage}
//               disabled={currentPage === 1}
//             >
//               <ChevronLeft className="w-5 h-5" />
//             </button>
//             <div className='flex items-center w-fit px-2'>
//               <span className="font-medium text-lg">{currentPage}</span>
//               <span className="text-gray-500 font-medium text-lg"> / {totalPages}</span>
//             </div>
//             <button
//               className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
//               onClick={handleNextPage}
//               disabled={currentPage === totalPages}
//             >
//               <ChevronRight className="w-5 h-5" />
//             </button>
//           </div>
//         </div>


//         <div className="overflow-x-auto">
//           <table className="w-full">
//             <thead className='bg-[#E1E5E43D]'>
//               <tr className="text-left text-[#989898]">
//                 <th className="py-2 px-4 w-[30%]">Name & Contact</th>
//                 <th className="py-2 px-4 w-[10%]">Inquiry date</th>
//                 <th className="py-2 px-4 w-[10%]">Contact</th>
//                 <th className="py-2 px-4 w-[50%]">Client Message/ Meeting Link</th>
//               </tr>
//             </thead>
//             <tbody>
//               {clientData.map((client) => (
//                 <tr key={client.id} className="border-t border-gray-200">
//                   <td className="py-4 px-4">
//                     <div className="flex items-center space-x-3">
//                       <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-sm ${client.initial === 'A' ? 'bg-blue-400' : 'bg-orange-300'}`}>
//                         {client.initial}
//                       </div>
//                       <span className="font-medium">{client.name}</span>
//                     </div>
//                   </td>
//                   <td className="py-4 px-4 text-gray-500">{client.inquiryDate}</td>
//                   <td className="py-4 px-4">
//                     <div className="flex flex-col space-y-1">
//                       <div className="flex items-center space-x-2 text-gray-600">
//                         <Mail size={14} />
//                         <span className="text-sm">{client.email}</span>
//                       </div>
//                       <div className="flex items-center space-x-2 text-gray-600">
//                         <Phone size={14} />
//                         <span className="text-sm">{client.phone}</span>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="py-4 px-4">
//                     {client.meetingScheduled ? (
//                       <div className="border-[1px] border-[#E8E8E8] w-fit flex items-center space-x-2 bg-[#fff] p-2 rounded-md text-sm text-[#1e1e1e]">
//                         <Calendar size={14} className="text-gray-600" />
//                         <span className="font-medium">Meeting Scheduled</span>
//                         <span className="text-gray-500">•</span>
//                         <span className="text-gray-600">{client.meetingDate}</span>
//                         <button className="border-[1px] border-[#808B8B33] ml-2 px-3 py-1 bg-[#e1e5e4] text-[#1e1e1e] rounded-md text-xs font-medium">Join</button>
//                       </div>
//                     ) : (
//                       <p className="text-sm text-gray-600">{client.message}</p>
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>

//   );
// };

const ClientInquiryList = () => {
  const [activeTab, setActiveTab] = useState('All');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const token = Cookies.get('authToken');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const tabs = ['All', 'With Meetings', 'Only Message'];

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}profile/enquiries?page=${currentPage - 1}&size=${itemsPerPage}`,
        { headers }
      );

      if (response.status === 200) {
        setData(response.data.enquiries);
        setTotalPages(Math.ceil(response.data.recordsFiltered / itemsPerPage));
        setIsLoading(false);
      } else {
        toast.warning('Failed to fetch data. Please try again later.');
        setIsLoading(false);
      }
    } catch (error) {
      toast.warning('Failed to fetch data. Please try again later.');
      setError(error);
      setIsLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredData = () => {
    switch (activeTab) {
      case 'With Meetings':
        return data.filter((client: any) => client.meetingScheduled);
      case 'Only Message':
        return data.filter((client: any) => !client.meetingScheduled);
      default:
        return data;
    }
  };

  const getInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className='bg-white h-[90%]'>
      <div className="p-4">
        <div className='text-3xl md:text-4xl font-bold mb-5 text-[#1e1e1e]'>Inquiries</div>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className="mb-4 flex space-x-2 bg-[#F8F9F8] rounded-md p-1 w-fit">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-md transition-colors duration-200 ${activeTab === tab
                  ? 'bg-white text-[#1E1E1E]'
                  : 'bg-transparent text-[#989898]'
                  }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="w-fit flex items-center space-x-2">
            <button
              className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <div className='flex items-center w-fit px-2'>
              <span className="font-medium text-lg">{currentPage}</span>
              <span className="text-gray-500 font-medium text-lg"> / {totalPages}</span>
            </div>
            <button
              className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className='bg-[#E1E5E43D]'>
              <tr className="text-left text-[#989898]">
                <th className="py-2 px-4 w-[25%]">Name & Contact</th>
                <th className="py-2 px-4 w-[15%]">Inquiry date</th>
                <th className="py-2 px-4 w-[20%]">Contact</th>
                <th className="py-2 px-4 w-[40%]">Client Message/ Meeting Link</th>
              </tr>
            </thead>
            <tbody>
              {filteredData().map((client: any, index) => (
                <tr key={index} className="border-t border-gray-200">
                  <td className="py-4 px-4">
                    <div className="flex items-center space-x-3">
                      <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-sm ${getInitial(client.name) === 'A' ? 'bg-blue-400' : 'bg-orange-300'
                        }`}>
                        {getInitial(client.name)}
                      </div>
                      <span className="font-medium">{client.name}</span>
                    </div>
                  </td>
                  <td className="py-4 px-4 text-gray-500">
                    {new Date(client.createdOn).toLocaleDateString()}
                  </td>
                  <td className="py-4 px-4">
                    <div className="flex flex-col space-y-1">
                      <div className="flex items-center space-x-2 text-gray-600">
                        <Mail size={14} />
                        <span className="text-sm">{client.email}</span>
                      </div>
                      <div className="flex items-center space-x-2 text-gray-600">
                        <Phone size={14} />
                        <span className="text-sm">{client.phoneNumber}</span>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-4">
                    {client.meetingScheduled ? (
                      <div className="border-[1px] border-[#E8E8E8] w-fit flex items-center space-x-2 bg-[#fff] p-2 rounded-md text-sm text-[#1e1e1e]">
                        <Calendar size={14} className="text-gray-600" />
                        <span className="font-medium">Meeting Scheduled</span>
                        <span className="text-gray-500">•</span>
                        <span className="text-gray-600">{client.meetingDate}</span>
                        <button className="border-[1px] border-[#808B8B33] ml-2 px-3 py-1 bg-[#e1e5e4] text-[#1e1e1e] rounded-md text-xs font-medium">Join</button>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-600 md:max-w-xl">{client.message}</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ClientInquiryList;