import React, { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import './Sidebar.scss';
import Logo from '../../assets/images/icons/logo.svg';
import Cookies from 'js-cookie';
import { CiLogout, CiMenuBurger } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from 'lucide-react';
import menuIcon from '../../assets/images/menuIcon.svg'
const Sidebar = ({
  isAdmin = false,
  isSidebarOpen,
  toggleSidebar,
  menuList,
  admin,
  setSelectedMenuIndex,
  setIsSidebarOpen
}: any) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobile(isMobile)
      setIsSidebarOpen(!isMobile);
    };

    // Set initial state
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      setIsSidebarOpen(false);
    };
  }, [setIsSidebarOpen]);


  const handleMenuItemClick = (index: number, path: string) => {
    if (path) {
      navigate(isAdmin ? `/admin${path}` : `/dashboard${path}`);
      if (isMobile) {
        setIsSidebarOpen(false);
      }
    }
    setCurrentIndex(index);
    setSelectedMenuIndex(index);
  };

  const handleLogout = () => {
    Cookies.set("authToken", '');
    navigate(isAdmin ? "/admin" : "/login");
  };

  return (
    <>
      <div className="mobile-header">
        <div className='flex flex-row gap-2 items-center'>
          <button className="mobile-toggle-sidebar" onClick={toggleSidebar}>
            <img src={menuIcon} alt='menu' className='w-5 h-5' />
          </button>
          <div className='flex flex-col gap-1 mt-3'>
            <img src={Logo} alt="Gaskon Logo" className="mobile-logo" />
            <span className='text-[#989898] font-medium text-md capitalize w-fit'>Admin Panel</span>
          </div>
        </div>
        <button className="mobile-search">
          <SearchIcon />
        </button>
      </div>
      {isSidebarOpen && <div className="sidebar-overlay" />}

      <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? 'open' : 'closed'} ${isMobile ? 'mobile' : ''}`}>
        {!isMobile && (
          <div className="sidebar-header w-full">
            <img src={Logo} alt="Logo" />
            <button className="toggle-sidebar" onClick={toggleSidebar}>
              <CiMenuBurger />
            </button>
          </div>
        )}
        <ul className="menu-list">
          {menuList.map((menuItem: any, index: number) => (
            <li
              key={index}
              className={`menu-item ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleMenuItemClick(index, menuItem.path)}
            >
              <img src={menuItem.src} alt={menuItem.title} className='icon' />
              <span>{menuItem.title}</span>
            </li>
          ))}
        </ul>
        <div className="logout" onClick={handleLogout}>
          <CiLogout />
          <span>Logout</span>
        </div>
      </div>
    </>
  );
};

export default Sidebar;