import React, { useRef, useState } from 'react';
import { Plus, ArrowRight } from 'lucide-react';

const DocumentInput = ({ onSubmit }: any) => {
    const [query, setQuery] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef<any>(null);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (query.trim() || selectedFile) {
            onSubmit({ query, file: selectedFile });
            setQuery('');
            setSelectedFile(null);
        }
    };

    const handleFileSelect = (e: any) => {
        if (e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex items-center w-full max-w-3xl mx-auto bg-white rounded-lg shadow-sm border border-gray-200"
            style={{ boxShadow: '0px 8px 24px 0px #5855610F' }}>
            <div className="flex-grow flex items-center pl-3">
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    className="hidden"
                />
                <button
                    type="button"
                    onClick={() => fileInputRef.current.click()}
                    className="p-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                    aria-label="Select file"
                >
                    <Plus size={20} />
                </button>
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Ask anything & use @ to mention the document"
                    className="w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none"
                />
            </div>
            <button
                type="submit"
                className="bg-gray-100 p-3 rounded-r-lg hover:bg-gray-200 focus:outline-none"
                aria-label="Submit query"
            >
                <ArrowRight size={20} className="text-gray-600" />
            </button>
        </form>
    );
};

export default DocumentInput;