// ContactFormPopup.tsx
import React, { useRef, useEffect, useState } from 'react';
import { Calendar } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';

interface ContactFormData {
    name: string;
    email: string;
    phone: string;
    message: string;
}

interface ContactFormPopupProps {
    isOpen: boolean;
    onClose: () => void;
    bgImage?: string;
}

const initialFormData: ContactFormData = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

const isValidEmail = (email: string) => {
    // Add your email validation logic here
    return /\S+@\S+\.\S+/.test(email);
};

export default function ContactFormPopup({ isOpen, onClose, bgImage }: ContactFormPopupProps) {
    const popupRef = useRef<HTMLDivElement>(null);
    const [formData, setFormData] = useState<ContactFormData>(initialFormData);
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('authToken');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const { name, email, phone, message } = formData;
            setLoading(true);

            if (!name || !email || !phone || !message) {
                toast.error('Please fill in all the required fields.');
                return;
            }

            if (!isValidEmail(email)) {
                toast.error('Please enter a valid email address.');
                return;
            }

            const payload = {
                name,
                email,
                phone,
                message,
                meetingLink: '',
            };

            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}enquiry/${process.env.REACT_APP_CLIENT_NORWOOD_ID}`,
                payload,
                { headers }
            );

            if (response.status === 200) {
                setFormData(initialFormData);
                onClose();
                toast.success('Form submitted successfully!');
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'An error occurred while submitting the form.');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
            <div ref={popupRef} className="bg-white rounded-3xl shadow-xl max-w-[95%] md:max-w-7xl w-full md:max-h-[90%] overflow-hidden p-3">
                <div className="flex flex-col lg:flex-row md:gap-3">
                    <div className="lg:w-[40%] p-3 px-4 md:p-6 bg-[#E6F1FA] rounded-xl overflow-y-auto">
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="text-xl font-bold">GET IN TOUCH WITH US</h2>
                            <button
                                onClick={onClose}
                                className="md:hidden text-white bg-[#686868] rounded-full p-1"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        
                        <p className="text-xs text-[#676767] mb-3 text-start">
                            Fill out the form below or Schedule a meeting with us
                        </p>

                        <form onSubmit={handleSubmit} className="text-start">
                            <div className="mb-4 bg-white rounded-2xl w-full md:max-w-md mx-auto pt-5"
                                style={{ border: '1px solid #E8E8E8' }}>
                                <FormField
                                    label="Name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                />

                                <FormField
                                    label="Email ID"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter Email"
                                />

                                <div className="mb-4 pb-5 px-4 md:px-8 border-b border-b-slate-400">
                                    <label className="block text-gray-700 font-bold mb-2">Phone Number*</label>
                                    <PhoneInput
                                        country={"us"}
                                        value={formData.phone}
                                        onChange={(value: string) => setFormData({ ...formData, phone: value })}
                                        enableSearch={true}
                                        placeholder="Enter phone number"
                                        buttonStyle={{ border: "none" }}
                                        inputStyle={{ border: 'none' }}
                                        inputClass="appearance-none border-0 rounded w-full py-2 pe-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>

                                <div className="mb-4 px-4 md:px-8">
                                    <label className="block text-gray-700 font-bold mb-2">Message*</label>
                                    <textarea
                                        required
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows={2}
                                        placeholder="Leave us your message here"
                                        className="w-full p-2 border-none rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center gap-2 mb-4">
                                <input
                                    required
                                    type="checkbox"
                                    id="privacy-policy"
                                    className="h-5 w-5 text-purple-600 cursor-pointer"
                                />
                                <label htmlFor="privacy-policy" className="text-[#686868] text-sm cursor-pointer">
                                    I agree to Privacy Policy and terms. We promise to keep your information safe and sound.
                                </label>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full bg-white border-2 border-blue-300 text-[#0177D1] font-bold p-3 rounded-xl mb-4 hover:bg-blue-50 transition duration-300"
                            >
                                {loading ? 'Sending ...' : 'Send'}
                            </button>

                            <div className="flex items-center my-6">
                                <div className="flex-grow border-t border-gray-400"></div>
                                <span className="flex-shrink mx-4 text-[#676767] font-bold">or</span>
                                <div className="flex-grow border-t border-gray-400"></div>
                            </div>

                            <button
                                type="button"
                                onClick={() => window.open('https://calendly.com/lussolabs/evolve-energy-investment-enquiry', '_blank')}
                                className="w-full bg-[#1E1E1E] text-white font-bold p-3 rounded-xl hover:bg-gray-800 transition duration-300 flex items-center justify-center gap-2"
                            >
                                <Calendar className="w-5 h-5" />
                                Schedule Meeting
                            </button>
                        </form>
                    </div>

                    <div className="hidden lg:block lg:w-[55%] rounded-xl">
                        <img
                            src={bgImage}
                            alt="Solar Panels"
                            className="w-full h-[99%] object-cover rounded-xl bg-center"
                            style={{ backgroundPosition: 'center' }}
                        />
                    </div>

                    <div className="hidden md:block h-auto">
                        <button
                            onClick={onClose}
                            className="text-white bg-[#686868] rounded-full p-1 hover:bg-gray-600 transition duration-300"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// FormField component for reusable form inputs
interface FormFieldProps {
    label: string;
    type: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
}

const FormField: React.FC<FormFieldProps> = ({ label, type, name, value, onChange, placeholder }) => (
    <div className="mb-4 pb-5 px-4 md:px-8 border-b border-b-slate-400">
        <label htmlFor={name} className="block text-gray-700 font-bold mb-2">{label}*</label>
        <input
            required
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="appearance-none border-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    </div>
);