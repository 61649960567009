import React from 'react'
import PhoneIcon from '../../../../assets/images/phoneIcon.svg';

function RevolveEnergySection() {
    return (
        <div className="w-full flex flex-col lg:flex-row justify-between gap-6">
            {/* Left Side */}
            <div className="w-full lg:w-2/5 flex flex-col gap-6">
                {/* Revenue Model */}
                <div className="p-6 md:p-10 bg-white rounded-2xl" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                    <h3 className="text-4xl font-bold mb-2 uppercase">Revenue Model</h3>
                    <p className="text-[#676767] text-lg"><span className='text-black'>We generate revenue</span> through product sales, installation fees, maintenance services, and leasing programs.</p>
                </div>
                {/* Break-Even Analysis */}
                <div className="p-6 md:p-10 bg-white rounded-2xl" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                    <h3 className="text-4xl font-bold mb-2 uppercase">Break-Even Analysis</h3>
                    <p className="text-[#676767] text-lg">We expect to break even by the end of Year 2, with significant profitability in subsequent years.</p>
                </div>
                {/* Funding Request */}
                <div className="p-6 md:p-10 text-white rounded-2xl" style={{ background: 'linear-gradient(103.99deg, #0294FF 55.01%, #0EFED4 157.18%)' }}>
                    <h3 className="text-5xl font-bold mb-2 uppercase">We SEEK - $5 million</h3>
                    <p className='text-lg'>In funding to scale operations, enhance R&D, and expand marketing efforts. Investors can expect a return of 20% within five years.</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="w-full lg:w-3/5 flex flex-col gap-6">
                {/* Projections */}
                <div className="flex md:flex-row flex-col gap-6 bg-white p-6 rounded-2xl" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                    <div className="md:w-1/2 rounded-lg">
                        <h3 className="text-4xl font-bold mb-2 uppercase">Projections</h3>
                        <p className="text-[#676767] text-lg">Expenses and profit projections align with market expansion and product development.</p>
                    </div>
                    <div className="md:w-1/2 flex flex-col justify-end gap-4">
                        {/* Year 3 */}
                        <div className="relative w-full">
                            <div className="flex items-center justify-start p-4 w-full rounded-2xl" style={{ background: 'linear-gradient(103.99deg, #0294FF 55.01%, #0EFED4 157.18%)' }}>
                                <span className="font-bold text-white">Year 3</span>
                            </div>
                            <span className="absolute right-0 top-1/2 transform -translate-y-1/2 font-bold text-white pr-3">$20 million</span>
                        </div>
                        {/* Year 2 */}
                        <div className="relative w-1/2">
                            <div className="flex items-center justify-start p-4 w-full rounded-2xl bg-gray-100">
                                <span className="font-bold text-black">Year 2</span>
                            </div>
                            <span className="absolute -right-[90px] top-1/2 transform -translate-y-1/2 font-bold text-black">$10 million</span>
                        </div>

                        {/* Year 1 */}
                        <div className="relative w-1/3">
                            <div className="flex items-center justify-start p-4 w-full rounded-2xl bg-gray-100">
                                <span className="font-bold text-black">Year 1</span>
                            </div>
                            <span className="absolute -right-[80px] top-1/2 transform -translate-y-1/2 font-bold text-black">$5 million</span>
                        </div>
                    </div>
                </div>

                {/* Net Worth and Return */}
                <div className="relative bg-white rounded-2xl p-6 md:p-8 flex justify-between items-center w-full" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                    {/* Slanted Line */}
                    <div className="absolute inset-0 flex justify-center">
                        <div className="h-full w-[5px] transform rotate-12 bg-[#EDF1F1]"></div>
                    </div>

                    {/* Left Section (Net Worth) */}
                    <div className="relative text-left z-10">
                        <span className="text-gray-500 text-md">Net Worth</span>
                        <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#0294FF] to-[#0EFED4]">
                            $1,950,000
                        </div>
                    </div>

                    {/* Right Section (Expected Return) */}
                    <div className="relative text-right z-10">
                        <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#0294FF] to-[#0EFED4]">16%</div>
                        <span className="text-gray-500 text-md">Expected Return</span>
                    </div>
                </div>



                {/* Get in Touch */}
                <div className="p-6 bg-white rounded-2xl" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                    <h3 className="text-3xl font-bold mb-2 uppercase">GET IN TOUCH</h3>
                    <p className="text-[#676767] mb-4 text-lg md:w-[65%]">Contact us via email, phone, or social media to discuss opportunities and learn more about our growth potential.</p>
                    <div className="bg-black text-white p-2 ps-4 rounded-full flex items-center justify-between w-fit cursor-pointer">
                        <span className="font-medium">Request a phone call</span>
                        <img src={PhoneIcon} alt="Phone Icon" className="ml-3 p-2 bg-white rounded-full" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RevolveEnergySection