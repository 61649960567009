import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, EllipsisVertical, FileText, Image } from 'lucide-react';
import docIcon from '../../../../assets/images/document/docIcon.svg'
import pptIcon from '../../../../assets/images/document/pptIcon.svg'
import xlsIcon from '../../../../assets/images/document/xlsIcon.svg'
import imgIcon from '../../../../assets/images/document/imgIcon.svg'
import newChatIcon from '../../../../assets/images/document/newChat.svg'

export const fileTypes: any = {
    doc: { color: 'bg-blue-500', icon: docIcon },
    ppt: { color: 'bg-red-500', icon: pptIcon },
    xls: { color: 'bg-green-500', icon: xlsIcon },
    img: { color: 'bg-gray-400', icon: imgIcon },
};

export const files = [
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Pradeep Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'ppt', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'xls', size: '346 KB', uploadedBy: 'Muthu Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'img', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Pradeep Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'ppt', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'xls', size: '346 KB', uploadedBy: 'Muthu Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'img', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Pradeep Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'ppt', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'xls', size: '346 KB', uploadedBy: 'Muthu Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'img', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
    { name: 'Safety Procedures Manual', type: 'doc', size: '346 KB', uploadedBy: 'Abinav Kumar', email: 'abinavkumar@gaskon.com', lastModified: 'Jan 07,2024' },
];

const AllFilesSection = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(files.length / itemsPerPage);
    const tabs = ['All', 'Documents', 'Images'];
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('All');

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const paginatedFiles = files.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    return (
        <div className="bg-white">
            <h2 className="text-3xl font-bold">All Files</h2>
            <div className="flex justify-between items-center my-4">
                <div className="mb-4 flex space-x-2 bg-[#F8F9F8] rounded-md p-1 w-fit -ms-1">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            className={`px-4 py-2 rounded-md transition-colors duration-200 ${activeTab === tab
                                ? 'bg-white text-[#1E1E1E]'
                                : 'bg-transparent text-[#989898]'
                                }`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <button
                            className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                        >
                            <ChevronLeft size={20} />
                        </button>
                        <span className=" font-medium text-lg">{currentPage}</span>
                        <span className=" text-gray-500 font-medium text-lg">{'/' + totalPages}</span>
                        <button
                            className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <ChevronRight size={20} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="text-left text-md bg-[#E1E5E43D] text-[#989898] p-2 border-none">
                            <th className="py-2 font-normal">
                                <input type="checkbox" className="mr-2" />
                                File name
                            </th>
                            <th className="py-2 font-normal">Uploaded by</th>
                            <th className="py-2 font-normal">Last Modified</th>
                            <th className="py-2 font-normal"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedFiles.map((file, index) => {
                            const FileIcon = fileTypes[file.type].icon;
                            return (
                                <tr key={index} className="border-t border-gray-200">
                                    <td className="py-4 flex items-center space-x-3">
                                        <input type="checkbox" />
                                        <div className={`p-2 rounded-md`}>
                                            <img src={FileIcon} alt={'file-' + index} className='w-12 h-12' />
                                        </div>
                                        <div>
                                            <p className="font-medium">{file.name}</p>
                                            <p className="text-sm text-gray-500">{file.size} • {file.type.toUpperCase()}</p>
                                        </div>
                                    </td>
                                    <td className="py-4">
                                        <p>{file.uploadedBy}</p>
                                        <p className="text-sm text-gray-500">{file.email}</p>
                                    </td>
                                    <td className="py-4 text-gray-500">{file.lastModified}</td>
                                    <td className="py-4 text-right flex items-center justify-end">
                                        <button className="px-2 py-1 text-gray-500">
                                            <img src={newChatIcon} alt='newChatIcon' className='w-7 h-7' />
                                        </button>
                                        <button className="px-2 py-1 text-gray-500">
                                            <EllipsisVertical />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllFilesSection;