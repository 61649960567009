import React, { useState } from "react";
import AppleIcon from "../../../assets/images/icons/apple_logo.png.png";
import GoogleIcon from "../../../assets/images/icons/google_symbol.png.png";
import Logo from "../../../assets/images/icons/logo.svg";
import GreenTick from "../../../assets/images/icons/green_tick.svg";
import RedTick from "../../../assets/images/icons/wrongIcon.png";
import RightArrow from "../../../assets/images/icons/arrow-right-cropped.svg";
import EyeIcon from "../../../assets/images/icons/eye-icon.png";
import "./SignUp.scss";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import Cookies from "js-cookie";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [invalidField, setInvalidField] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [rule1, setRule1] = useState<boolean>(false);
  const [rule2, setRule2] = useState<boolean>(false);
  const [rule3, setRule3] = useState<boolean>(false);

  // Validating the email and password
  const validateFields = () => {
    const isValidEmail = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(userName);
    const isValidPassword = rule1 && rule2 && rule3;

    if (!isValidEmail) {
      setInvalidField('Invalid email');
      return false;
    }

    if (!isValidPassword) {
      setInvalidField('Invalid password');
      return false;
    }

    return true;
  };

  // Password validation logic
  const validatePassword = (password: string) => {
    setRule1(password.length >= 8); // Rule 1: At least 8 characters
    setRule2(/[\d@$!%*?&]/.test(password)); // Rule 2: Contain a number or symbol
    setRule3(/^(?=.*[a-z])(?=.*[A-Z])/.test(password)); // Rule 3: Include uppercase & lowercase letters
  };

  const register = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission

    if (!validateFields()) return;

    setLoading(true);
    const body = {
      email: userName,
      password,
      mobileNumber: '+' + mobileNumber,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + 'register', body)
      .then((resp) => {
        const authToken = resp.data?.token;
        Cookies.set('authToken', authToken, { expires: 7 });
        setLoading(false);
        Cookies.set('registeredEmail', userName);
        navigate('/verify');
      })
      .catch((err) => {
        console.log(err?.response?.data)
        setLoading(false);
        if(err?.response?.data?.message){
          toast.warning(err?.response?.data?.message)
        }
        console.error(err);
      });
  };

  return (
    <div className="relative">
    <div className="max-w-xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-4 md:justify-center items-center mb-8">
        <Link to="/home" className="flex items-center text-black hover:text-gray-700 absolute left-6">
          <img src={RightArrow} alt="Back" className="w-4 h-4 mr-2 transform" />
          <span>Back</span>
        </Link>
        <img src={Logo} alt="Gaskon Logo" className="w-32 cursor-pointer" onClick={() => navigate('/home')} />
      </div>

      <h1 className="text-2xl font-bold text-center mb-4">CREATE YOUR ACCOUNT</h1>

      <form onSubmit={register}>
        <div className="mx-auto md:p-6 space-y-1">
          <div
            className="bg-white rounded-lg space-y-4"
            style={{ boxShadow: '0px 24px 54px 0px #18181812', border: '1px solid #E8E8E8' }}
          >
            <div className="space-y-4 py-6">
              <div className="border-b border-gray-300 px-6">
                <label className="block text-sm text-gray-500 mb-1">Email Id*</label>
                <input
                  type="email"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-full p-2 text-lg font-semibold focus:outline-none focus:border-blue-500 focus:bg-transparent"
                />
              </div>

              <div className="border-b border-gray-300 px-6">
                <label className="block text-sm text-gray-500 mb-1">Phone Number*</label>
                <PhoneInput
                  country={'us'}
                  value={mobileNumber}
                  onChange={(phone) => setMobileNumber(phone)}
                  inputStyle={{
                    width: '100%',
                    fontSize: '1.125rem',
                    fontWeight: '600',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: '1px solid transparent',
                    borderRadius: '0',
                    minWidth: '100%',
                  }}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: '1px solid transparent',
                    borderRadius: '0',
                  }}
                />
              </div>

              <div className="px-6">
                <label className="block text-sm text-gray-500 mb-1">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    className="w-full p-2 text-lg font-semibold focus:outline-none focus:border-blue-500 focus:bg-transparent"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img src={EyeIcon} alt="Toggle password visibility" className="h-5 w-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-lg pt-5">
            <div className="password-strength-ct w-full">
              <span className="uppercase">Password Strength: </span>
              <span
                style={{
                  color:
                    rule1 && rule2 && rule3
                      ? 'green'
                      : (rule1 && rule2) || (rule1 && rule3) || (rule2 && rule3)
                        ? '#A78837'
                        : 'red',
                }}
                className="strength-status uppercase"
              >
                {rule1 && rule2 && rule3
                  ? 'Good'
                  : (rule1 && rule2) || (rule1 && rule3) || (rule2 && rule3)
                    ? 'Average'
                    : 'Bad'}
              </span>
              <ul className="text-[#1e1e1e]">
                <li className={`${!rule1 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                  {rule1 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                  At least 8 characters
                </li>
                <li className={`${!rule2 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                  {rule2 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                  Contain a number or symbol
                </li>
                <li className={`${!rule3 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                  {rule3 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                  Include a mix of uppercase & lowercase letters
                </li>
              </ul>
            </div>
          </div>

          {invalidField && <div className="bg-red-100 border-red-400 rounded-lg p-2 px-4 mb-4 text-red-500">{invalidField}</div>}

          <div className="flex justify-center">
            <button
              type="submit"
              disabled={loading}
              className={`relative gap-2 w-full bg-black text-white py-4 font-bold text-lg rounded-lg flex flex-row justify-center items-center ${loading ? 'opacity-50' : ''
                }`}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Continue
                </>
              ) : (
                'Continue'
              )}
            </button>
          </div>


          <div className="text-center py-5">
            <p className="text-gray-500">or</p>
          </div>

          <div className="space-y-4">
            {/* Google Sign-up Button */}
            <button
              type="button"
              className="w-full flex items-center justify-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100"
            >
              <img src={GoogleIcon} alt="Google" className="w-6 h-6 mr-2" />
              <span className="text-gray-600 font-semibold">Sign up with Google</span>
            </button>

            {/* Apple Sign-up Button */}
            <button
              type="button"
              className="w-full flex items-center justify-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100"
            >
              <img src={AppleIcon} alt="Apple" className="w-6 h-6 mr-2" />
              <span className="text-gray-600 font-semibold">Sign up with Apple</span>
            </button>
          </div>

          <div className="text-center mt-6">
            <Link to="/login" className="text-blue-600 font-semibold hover:underline">
              Already having an account? Sign in
            </Link>
          </div>

          <div className="text-center text-xs text-gray-500 pt-8">
            <p>
              By Continuing, you agree to our{' '}
              <Link to="/terms" className="underline">
                Terms and Conditions
              </Link>{' '}
              &{' '}
              <Link to="/privacy" className="underline">
                Privacy Policy
              </Link>
            </p>
          </div>

        </div>
      </form>
    </div>
    </div>

  );
};

export default SignUp;
