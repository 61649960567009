import React from 'react'
import GradientIcon from '../../../../assets/images/icons/GardientIcon.svg';
import bg1 from '../../../../assets/images/nurwood/k1.svg';
import bg2 from '../../../../assets/images/nurwood/k2.svg';
import bg3 from '../../../../assets/images/nurwood/k3.svg';
import bg4 from '../../../../assets/images/nurwood/k4.svg';
import salesIcon from '../../../../assets/images/nurwood/salesStrategy.svg';
import marketIcon from '../../../../assets/images/nurwood/marktplanicon.svg';
import analysisIcon from '../../../../assets/images/nurwood/companalysisIcon.svg';
function KeyInsights() {
    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col lg:flex-row gap-6">
                {/* Target Market Card */}
                <div className="flex-1 rounded-3xl p-2"
                    style={{ background: `url(${bg1})`, backgroundSize: 'cover' }}>
                    <div className="flex flex-col gap-3 bg-white p-4 rounded-3xl h-full">
                        <div className="flex items-center gap-2.5">
                            <div className="bg-[#3BC5A3] p-1.5 rounded-full flex justify-center items-center">
                                <img src={GradientIcon} alt="Target Market" />
                            </div>
                            <span className="text-xl font-semibold text-gray-800">Target Market</span>
                        </div>
                        <p className="text-base font-normal text-gray-500">
                            We utilize a direct sales approach, online sales channels, and strategic partnerships
                            with contractors and energy consultants. Our sales team is trained to highlight the
                            long-term savings and environmental benefits of our products.
                        </p>
                    </div>
                </div>

                {/* Competitive Analysis Card */}
                <div className="flex-1 rounded-3xl p-2"
                    style={{ background: `url(${bg2})`, backgroundSize: 'cover' }}>
                    <div className="flex flex-col gap-3 bg-white p-4 rounded-3xl h-full">
                        <div className="flex items-center gap-2.5">
                            <div className="bg-[#D27DE2] p-1.5 py-3.5 rounded-full flex justify-center items-center">
                                <img src={analysisIcon} alt="Competitive Analysis" />
                            </div>
                            <span className="text-xl font-semibold text-gray-800">Competitive Analysis</span>
                        </div>
                        <p className="text-base font-normal text-gray-500">
                            We utilize a direct sales approach, online sales channels, and strategic partnerships
                            with contractors and energy consultants. Our sales team is trained to highlight the
                            long-term savings and environmental benefits of our products.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-6">
                {/* Marketing Plan Card */}
                <div className="flex-1 rounded-3xl p-2"
                    style={{ background: `url(${bg3})`, backgroundSize: 'cover' }}>
                    <div className="flex flex-col gap-3 bg-white p-4 rounded-3xl h-full">
                        <div className="flex items-center gap-2.5">
                            <div className="bg-[#1B5399] p-1.5 rounded-full flex justify-center items-center">
                                <img src={marketIcon} alt="Marketing Plan" />
                            </div>
                            <span className="text-xl font-semibold text-gray-800">Marketing Plan</span>
                        </div>
                        <p className="text-base font-normal text-gray-500">
                            We utilize a direct sales approach, online sales channels, and strategic partnerships
                            with contractors and energy consultants. Our sales team is trained to highlight the
                            long-term savings and environmental benefits of our products.
                        </p>
                    </div>
                </div>

                {/* Sales Strategy Card */}
                <div className="flex-1 rounded-3xl p-2"
                    style={{ background: `url(${bg4})`, backgroundSize: 'cover' }}>
                    <div className="flex flex-col gap-3 bg-white p-4 rounded-3xl h-full">
                        <div className="flex items-center gap-2.5">
                            <div className="bg-[#F56320] p-1.5 rounded-full flex justify-center items-center">
                                <img src={salesIcon} alt="Sales Strategy" />
                            </div>
                            <span className="text-xl font-semibold text-gray-800">Sales Strategy</span>
                        </div>
                        <p className="text-base font-normal text-gray-500">
                            We utilize a direct sales approach, online sales channels, and strategic partnerships
                            with contractors and energy consultants. Our sales team is trained to highlight the
                            long-term savings and environmental benefits of our products.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default KeyInsights