import React from 'react';
import './FounderSection.scss';
import Nick from '../assets/images/nick_parker.png';
import Highlight from '../assets/images/Highlight_05.png';
import GreenFlag from '../assets/images/GreenFlag.png';
import founderImg from '../assets/images/evolveEnergy/founder.svg'
import blackLine from '../assets/images/evolveEnergy/blackLine.svg'
import signature from '../assets/images/evolveEnergy/signature.svg'

const FounderSection: React.FC = () => {
    return (
        <div className="founder-section flex-col md:flex-row px-[20px] py-[50px] md:py-[100px] md:px-[40px] md:max-w-[90%] md:mx-auto">
            <div className="founder-intro w-full">
                <div className='flex gap-1.5'>
                    <img src={blackLine} alt='black line' className='w-[2px]' />
                    <span className='text-[#676767] font-medium text-xl'>Meet Our</span>
                </div>
                <h1 className='text-4xl md:text-7xl'>FOUNDER</h1>

                <div className='flex flex-col md:flex-row gap-2 md:gap-5 md:items-center'>
                    <div className='md:flex-0'>
                        <div className='ml-2' style={{ position: 'relative' }}>
                            <img src={founderImg} alt="Nick Parker" className='h-[350px] md:h-[450px]' />
                        </div>
                        <div className="founder-details ml-[30px] pt-5 flex flex-col gap-1">
                            <div>
                                <img src={signature} alt='signature' />
                            </div>
                            {/* <h2>Nick Parker</h2> */}
                            <span className='mt-2'>CEO/ Founder, E-Volve Energy</span>
                            <p>10+ YEARS OF EXPERIENCE</p>
                        </div>
                    </div>

                    {/* The section css i need  */}
                    <div className='flexCol md:w-[60%] my-5 md:my-0 ml-3 md:ml-0'>
                        <h4 style={{ textAlign: 'left', margin: 0, paddingLeft: 20 }} className='font-bold'>Professional Milestones</h4>
                        <div className='timeline tl'>
                            <span>
                                He began his career at Green Mountain Energy, earning the Power Player Award in 2012 and 2013.
                            </span>
                            <div className='tl-circle'></div>
                        </div>
                        <div className='flexRow'>
                            <div className='timeline tp'>
                                <span>
                                    Joined Source Power & Gas in 2013, contributing to its rapid growth and subsequent acquisition by ERM Power.
                                </span>
                                <div className='tl-circle'></div>
                            </div>
                            <div className='timeline tp'>
                                <span>
                                    Founded CNRG Group in 2015, focusing on integrating retail power with comprehensive energy solutions.
                                </span>
                                <div className='tl-circle'></div>
                            </div>
                            <div className='timeline '>
                                <span>
                                    Led a significant $12 million energy efficiency project for the Dallas Cowboys, resulting in the formation of Environ Partners and later Pro Star Energy Solutions
                                </span>
                                <div 
                                className='-top-[0px] -left-[5px] md:-top-[6.5px] md:left-[2px]'
                                style={{
                                    position: 'absolute',
                                }}>
                                    <img style={{
                                        height: 18,
                                        width: 18
                                    }} src={GreenFlag} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
};

export default FounderSection;
