import EvolveCover from '../assets/images/logo/EvolveCover.png'
import LussoCover from '../assets/images/logo/LussoCover.png'
import NorwoodCover from '../assets/images/logo/NorwoodCover.png'
import GaskonCover from '../assets/images/logo/GaskonCover.png'
// import CenturyCover from '../assets/images/logo/CenturyCover.png'

export const text = 'test'
export const EvolveEnergyID = process.env.REACT_APP_CLIENT_EVOLVE_ID as string
export const LussoID = process.env.REACT_APP_CLIENT_LUSSO_ID as string
export const NorwoodID = process.env.REACT_APP_CLIENT_NORWOOD_ID as string
export const Century21 = process.env.REACT_APP_CLIENT_CENTURY_ID as string
export const Gaskon = process.env.REACT_APP_CLIENT_GASKON_ID as string

export const clientPaths = {
  [EvolveEnergyID]: '/EvolveEnergy',
  [LussoID]: '/Lusso',
  [NorwoodID]: '/NorwoodEnergy',
  [Century21]: '/Century21',
  [Gaskon]: '/Gaskon',
}

export const clientImagePath = {
    [EvolveEnergyID]: EvolveCover,
    [LussoID]: LussoCover,
    [NorwoodID]: NorwoodCover,
    [Century21]: null, //CenturyCover,
    [Gaskon]: GaskonCover,
  }
  