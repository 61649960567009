import React from "react";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { Button, Checkbox } from "@mantine/core";
import PhoneInput from "react-phone-input-2";
import Contact_usPage from "../../pages/Landing/Contact_us";
import "./css/contactus.scss";
import ContactForm from "./ContactForm";
import FloatingButton from "../common/FloatingButton";

export default function Contact_us() {
  return (
    <>
      {/* <div className="flex md:flex-row flex-col md:justify-around justify-center md:p-[144px_110px] p-[35%_5%_14%_5%] md:items-start items-center">
      <div className="flex flex-col gap-[20px] md:max-w-[40%] max-w-none items-start">
        <div className="w-full text-[#1E1E1E] text-[60px] font-outfit font-semibold uppercase leading-[72px] break-words">
          Get in Touch with us
        </div>
        <div className="flex flex-col md:max-w-[80%] max-w-none gap-[20px]">
          <div className="text-[#686868] text-[14px] font-outfit font-normal leading-[20px] break-words">
            Fill out the form below, and our team will get back to you shortly
            to discuss how we can help you make the switch to solar.
          </div>
          <div className="flex flex-col gap-[30px] items-start">
            <div className="flex flex-row gap-[8px] justify-start items-center">
              <FiPhone color="#0177D1" />
              <div className="text-[#0177D1] text-[16px] font-outfit font-normal leading-[16px] break-words">
                817-880-1304
              </div>
            </div>
            <div className="flex flex-row gap-[8px] justify-start items-center">
              <MdOutlineEmail color="#0177D1" />
              <div className="text-[#0177D1] text-[16px] font-outfit font-normal leading-[16px] break-words">
                info@gaskon.io
              </div>
            </div>
            <div className="flex flex-row gap-[8px] justify-start items-center">
              <CiLocationOn color="#0177D1" />
              <div className="text-[#0177D1] text-[16px] font-outfit font-normal leading-[16px] break-words">
                1203 S White Chapel Blvd, Southlake, TX 76092, USA
              </div>
            </div>
          </div>
          <div className="contact-form-container">
            <div className="contact-input-group">
              <label className="label">Name*</label>
              <input type="text" />
            </div>
            <div className="divider"></div>
            <div className="contact-input-group">
              <label className="label">Email ID*</label>
              <input type="text" />
            </div>
            <div className="divider"></div>
            <div className="contact-input-group">
              <label className="label">Phone Number*</label>
              <div className="contact-phone-input">
                <PhoneInput
                  country={"us"}
                  // value={phone}
                  // onChange={handlePhoneChange}
                  enableSearch={true}
                  placeholder="Enter phone number"
                  inputStyle={{
                    width: "100% !important",
                    padding: "10px",
                    fontSize: "16px",
                    border: "none",
                    paddingLeft: "48px",
                    // borderBottom: "1px solid #e1e5e4",
                  }}
                  containerClass="custom-phone-input"
                  buttonStyle={{
                    border: "1px solid #e1e5e4",
                  }}
                />
              </div>
            </div>
            <div className="divider"></div>
            <div className="contact-input-group">
              <label className="label">Message*</label>
              <textarea placeholder="Leave us your message here" />
            </div>
          </div>
          <div>
            <Checkbox
              defaultChecked
              label=" I agree to  Privacy Policy and terms. We promise to keep your information safe and sound."
              // checked={isChecked}
              // onChange={handleCheckboxChange}
              className="font-medium"
              style={{ color: "#989898" }}
            />
          </div>
          <button
            style={{ background: "#1E1E1E" }}
            className="w-full max-w-[484px] py-4 text-white text-base bg[#1E1E1E] font-outfit font-bold leading-4 rounded-lg border-none cursor-pointer"
          >
            Send Message
          </button>
        </div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.954858973381!2d-97.1585292245336!3d32.92579087360186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864dd449939d1cad%3A0x45f399787dcf7cea!2s1203%20S%20White%20Chapel%20Blvd%2C%20Southlake%2C%20TX%2076092%2C%20USA!5e0!3m2!1sen!2sin!4v1721754208246!5m2!1sen!2sin"
          height="600"
          width="600"
          className="border-0"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div> */}
      {/* <Contact_usPage />  */}
      <div className="flex md:flex-row flex-col md:justify-between justify-center md:items-start items-center">
        <ContactForm />
        <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />
      </div>
    </>
  );
}
