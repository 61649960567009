import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import SparkIcon from "../../assets/images/icons/SparkIcon.svg";
import ClientLandingIcon from "../../assets/images/icons/ClientLandingIcon.svg";
import InnovativeBulbIcon from "../../assets/images/icons/InnovativeBulbIcon.svg";
import TransparentCommunicationIcon from "../../assets/images/icons/TransparentCommunicationIcon.svg";
import SustainGrowth from "../../assets/images/icons/sustainGrowth.svg";
import LongTerm from "../../assets/images/icons/longTerm.svg";
import Arrow from "../../assets/images/icons/arrowIcon.png";
import ArrowIcons from "../../assets/images/icons/group-Icons.png";
import { useNavigate } from "react-router-dom";
import { statisticsData } from "../../utils/helperFunctions";
import FeatureCard from "../../components/FeatureCard";
import "./CommitmentsSection.scss";
const featuresData = [
  {
    title: "Exceptional Results",
    description:
      "We Deliver Actionable Insights And Strategies That Drive Measurable Growth.",
    icon: SparkIcon,
  },
  {
    title: "Client-Centric Approach",
    description:
      "Our Success Is Our Priority. We Tailor Solutions To Meet Your Unique Needs.",
    icon: ClientLandingIcon,
  },
  {
    title: "Innovative Solutions",
    description:
      "Stay Ahead With Our Cutting-Edge Strategies And Industry-Leading Technology.",
    icon: InnovativeBulbIcon,
  },
  {
    title: "Transparent Communication",
    description:
      "Open, Honest, And Clear Communication At Every Phase Of Your Project.",
    icon: TransparentCommunicationIcon,
  },
  {
    title: "Long-Term Partnerships",
    description:
      "We Focus On Building Enduring Relationships With Our Clients, Offering Ongoing Support To Ensure Continuous Success.",
    icon: LongTerm,
  },
  {
    title: "Sustainable Growth",
    description:
      "We Prioritize Sustainable, Scalable Solutions That Adapt To Your Business As It Evolves Over Time.",
    icon: SustainGrowth,
  },
];
const CommitmentsSection: React.FC = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<any>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isReverse, setIsReverse] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const updateSlidesToShow = () => {
    if (window.innerWidth <= 768) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const totalSlides = featuresData.length;
  const lastVisibleSlide = totalSlides - slidesToShow;

  const CustomArrow = () => (
    <div
      className="custom-arrow z-50"
      onClick={handleArrowClick}
      style={{
        transform: isReverse ? "rotate(180deg)" : "rotate(0deg)",
      }}
    >
      <img src={ArrowIcons} alt="Arrow" />
    </div>
  );

  const handleArrowClick = () => {
    if (!isReverse) {
      if (currentSlide < lastVisibleSlide) {
        if (sliderRef.current) {
          sliderRef.current.slickNext();
        }
      }
      if (currentSlide === lastVisibleSlide) {
        setIsReverse(true);
      }
    } else {
      if (currentSlide > 0) {
        if (sliderRef.current) {
          sliderRef.current.slickPrev();
        }
      }
      if (currentSlide === 0) {
        setIsReverse(false);
      }
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current: any) => {
      setCurrentSlide(current);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="commitments-section">
      <article className="commitment-container">
        <div className="header flex flex-row justify-between gap-9">
          <div className="gradient-circles">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="flex items-center justify-between  w-full">
            <div
              style={{
                padding: "1px",
                borderRadius: "16px",
                background:
                  "linear-gradient(92.96deg, rgba(0, 118, 208, 0.75) 37.17%, rgba(14, 254, 212, 0.75) 106.2%)",
              }}
            >
              <div
                style={{
                  borderRadius: "16px",
                  background: "white",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#1E1E1E",
                    fontFamily: "Outfit",
                  }}
                  className="font-normal uppercase text-xs px-4 text-center"
                >
                  Gaskon Promise
                </span>
              </div>
            </div>

            <div>
              {/* <img src={ArrowIcons} alt="arrow" /> */}
              <CustomArrow />
            </div>
          </div>
          <h1
            className="font-semibold text-7xl text-balance w-3/4"
            style={{ color: "#4B4B4B" }}
          >
            We Don't Just Make Deals, We Make Commitments.
          </h1>
          <p className="text-balance">
            We are dedicated to driving your success through tailored solutions,
            innovative strategies, and transparent communication. Our focus is
            on delivering exceptional results and fostering sustainable growth
            with our expert team.
          </p>
        </div>
        <div className="feature-container">
          <Slider ref={sliderRef} {...settings}>
            {featuresData.map((feature, index) => (
              <div key={index} className="features flex items-center">
                <div className="feature-separator flex items-center justify-between gap-2">
                  <div className="feature">
                    <div className="sub-feature flex flex-col gap-y-3 py-7 md:py-auto ">
                      <div
                        className="flex flex-col w-20 h-20 p-6 rounded-full justify-center items-center"
                        style={{
                          border: "3px rgba(176, 213, 241, 0.50) solid",
                        }}
                      >
                        <img src={feature.icon} alt="Icon" />
                      </div>
                      <h5 className="font-satoshi font-bold text-2xl text-justify">
                        {feature.title}
                      </h5>
                      <p
                        className="font-satoshi text-sm font-medium text-balance text-start"
                        style={{ color: "#0C070F" }}
                      >
                        {feature.description}
                      </p>
                    </div>
                  </div>
                  <div className="separator mr-auto md:mr-9 ml-0 lg:ml-16"></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </article>
      <article className="stats-section">
        <div className="stats">
          {statisticsData?.map((i) => (
            <div className="flex flex-col">
              <h2
                className="font-semibold text-7xl"
                style={{ color: "#FFFFFF" }}
              >
                {i?.value}
              </h2>
              <p className="text-xl font-normal justify-center text-balance">
                {i?.label}
              </p>
            </div>
          ))}
        </div>
      </article>
      <div
        className="access-portfolio px-24"
        onClick={() => navigate("/Access")}
      >
        <div className="access font-bold text-2xl">Access Portfolio</div>
        <div
          style={{
            height: "16px",
            width: "16px",
            cursor: "pointer",
          }}
          className="flex justify-center align-middle"
        >
          {/* &rarr; */}
          <img src={Arrow} alt="arrow" />
        </div>
      </div>
      {/* <div className="quarter-circle">
                <img src={quarterCircle} alt="Quarter Circle Gradient" />
            </div> */}
    </div>
  );
};

export default CommitmentsSection;
