import DigitalCommerceSection from './DigitalCommerceSection';
import FoundedLussoSection from './FoundedLussoSection';
import LussoBanner from './LussoBanner';
import LussoDigitalHubIntro from './LussoDigitalHubInfo';
import LussoFounderSection from './LussoFounderSection';
import RevenueStreams from './RevenueStreams';
import Roadmap from './Roadmap';
import SummerySection from './SummerySection';
import imageOne from '../../../../assets/images/lusso/key/1.svg'
import imageTwo from '../../../../assets/images/lusso/key/2.svg'
import imageThree from '../../../../assets/images/lusso/key/3.svg'
import imageFour from '../../../../assets/images/lusso/key/4.svg'
import imageFive from '../../../../assets/images/lusso/key/5.svg'
import imageSix from '../../../../assets/images/lusso/key/6.svg'
import KeyPerformanceSection from './KeyPerformanceSection';
import ScalableSolutions from './ScalableSolutions';
import SuccessSection from './SuccessSection';
import FooterCommon from '../../../../components/common/FooterCommon';
import NewTopHeader from '../../../../components/NewHeader';
import MarketOpportunity from './MarketOpportunity';
import AIPersonalization from './AIPersonalization';
import SuccessStories from './SuccessStories';
import KeyPerformanceIndicators from './KeyPerformanceIndicators';
import FundingHighlight from './FundingHighlight';
import ValuationGrowth from './ValuationGrowth';
import StrategicSolutions from './StrategicSolutions';
import CurrentOpportunities from './CurrentOpportunities';
import CustomerTestimonials from './CustomerTestimonials';
import MeetTheTeam from './MeetTheTeam';
import NewsSection from './NewsSection';
import ConnectSection from './ConnectSection';
import StandOut from './StandOut';

function LussoHome() {
    const investmentModels = [
        {
            number: '01',
            title: 'Customer Acquisition Cost (CAC)',
            description: 'The total cost of acquiring a new customer, including all marketing, sales, and onboarding expenses.',
            image: imageOne,
            backgroundColor: '#6B23E0',
        },
        {
            number: '02',
            title: 'Lifetime Value (LTV)',
            description: 'LussoAI’s innovative, personalized AI services aim to maximize LTV by enhancing customer experience and retention, encouraging long-term loyalty and higher actualized lifetime revenues.',
            image: imageTwo,
            backgroundColor: '#26296E',
        },
        {
            number: '03',
            title: 'Churn Rate (CR)',
            description: 'LussoAI’s advanced retention mechanisms, such as predictive analytics and personalized AI-driven interventions, help lower churn rates by addressing customer concerns early, ensuring they remain engaged and loyal to the platform.',
            image: imageThree,
            backgroundColor: '#6B23E0',
        },
        {
            number: '04',
            title: 'Short-Term Returns',
            description: 'Returns: 15% on a 6-month investment timeline. Ideal for investors unable to use tax credits and not classified as C corporations. Involves pre-approved REAP Grant projects.',
            image: imageFour,
            backgroundColor: '#662BDB',
        },
        {
            number: '05',
            title: 'Tax Credit Purchase',
            description: 'Returns: Purchase tax credits at .90¢ on the dollar plus 10%. Suitable for investors seeking straightforward, immediate ROI. Tax credits are placed and serviced, with investments held in an IRA for security.',
            image: imageFive,
            backgroundColor: '#3F2E8F',
        },
        {
            number: '06',
            title: 'Prepaid Tax Credits',
            description: 'Returns: Purchase tax credits at 75¢ on the dollar, with 20% over 30 years. Requires funding before construction, ideal for long-term partners sharing in project profits from inception.',
            image: imageSix,
            backgroundColor: '#543BC0',
        },
    ];

    return (
        <>
            {/* Header */}
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <div style={{
                    position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
                    background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
                }}>
                    <NewTopHeader />
                </div>
            </div>

            {/* Banner */}
            <LussoBanner />

            {/* Summery */}
            <SummerySection />

            {/* Market Opportunity */}
            <MarketOpportunity />

            {/* AI Power Personalization */}
            <AIPersonalization />

            {/* Key Indicators */}
            <KeyPerformanceIndicators />

            {/* Strategic Solutions */}
            <StrategicSolutions />

            {/* Road Map */}
            <Roadmap />

            {/* Valuation Growth */}
            <ValuationGrowth />

            {/* Customer Testimonials */}
            <CustomerTestimonials />

            {/* Stand out */}
            <StandOut />

            {/* Funding Highlight */}
            {/* <FundingHighlight /> */}

            {/* Current Opportunities */}
            <CurrentOpportunities />

            {/* Success Stories */}
            <SuccessStories />
            
            {/* Meet Team */}
            {/* <MeetTheTeam /> */}

            {/* News */}
            <NewsSection />

            {/* Connect */}
            <ConnectSection />

            {/* Footer */}
            <FooterCommon />
        </>

    )
}

export default LussoHome