import React from 'react';
import bg from '../../../../assets/images/gaskon/bannerBg.svg'

const GaskonBanner = () => {
    return (
        <div className="relative w-full bg-no-repeat bg-cover bg-center md:min-h-[50rem] bg-none md:bg-current md:flex md:flex-col md:justify-center"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            {/* <img src={bg} className='absolute right-0 h-full w-auto' /> */}
            {/* Content */}
            <div className="relative md:w-[80%] h-full md:m-auto px-6 pt-32 pb-24 md:pt-40 md:pb-32">
                <div className="text-white text-center">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
                        <span className="bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4]  bg-clip-text text-transparent">
                            Your Gateway
                        </span> to
                        <span className="block">Unmatched Opportunities</span>
                    </h1>
                    <p className="text-lg md:text-xl mb-8 font-normal">
                        Simplifying Success with AI, Data, and Innovation
                    </p>
                    <button className="bg-[#0177D1] hover:bg-blue-700 text-white font-medium px-6 py-3 rounded-full transition-colors duration-200">
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GaskonBanner