
import React from 'react'
import './css/fy.scss'
import bg from '../.../../../../../assets/images/century/comprehensiveBg.svg'
import icons from './Image/Star.png'

export default function ComprehensiveSection() {
    return (
        <>
            <div className='object-cover bg-no-repeat py-[50px] p-5' style={{ background: `url(${bg})` }}>
                <div className='inset-0 bg-opacity-50  md:max-w-[80%] mx-auto flex flex-col md:flex-row gap-5 justify-center items-center'>
                    <div className='flex-0 md:p-10'>
                        {/* Main Title */}
                        <h1 className="text-white text-4xl md:text-6xl font-semibold mb-8 text-center md:text-start flex-1">
                            Comprehensive analysis & Risk Management
                        </h1>
                    </div>
                    <div className='sub_fcon'>
                        {/* Option boxes */}
                        <div className='flex-1 flex flex-col justify-end items-end md:p-10'>
                            <div className="space-y-4 md:space-y-6 w-full md:w-[4/5]">
                                <div className="bg-black bg-opacity-50 hover:bg-opacity-70 transition-colors duration-300 text-white text-start py-4 px-6 rounded-md"
                                    style={{ borderLeft: '2px solid #E5A811' }}>
                                    We conduct a comprehensive analysis of market risks, economic factors, and property-specific challenges
                                </div>
                                <div className="bg-black bg-opacity-50 hover:bg-opacity-70 transition-colors duration-300 text-white text-start py-4 px-6 rounded-md"
                                    style={{ borderLeft: '2px solid #E5A811' }}>
                                    Our risk management strategy includes continuous monitoring and proactive measures to mitigate potential issues, ensuring the stability and resilience of our investments.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sban py-10'>
                <div className='sim'>
                    <img src={icons} />
                </div>
                <div className='scons'>
                    <h2 className='text-3xl font-medium mb-2'>Insurance Coverage</h2>
                    <p className='text-md font-medium'>- Full coverage for all properties, including liability and disaster insurance -</p>
                </div>
                <div className='sim'>
                    <img src={icons} />
                </div>
            </div>
        </>
    )
}