import React, { useState, useEffect, useRef } from "react";
import "./Portfolio.scss";

import GetAccesImage from "../../assets/images/icons/get-access-image.svg";
import Tick from "../../assets/images/icons/tick.svg";
import ArrowNE from "../../assets/images/icons/arrow-ne.svg";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OtpModal from "../../components/misc/modal/OtpModal";
import { useDisclosure } from "@mantine/hooks";
import { Checkbox } from "@mantine/core";
import { validateEmail } from "../../utils/helperFunctions";
import { useApiMutation } from "../../hooks/useApi";
import arrow from "../../assets/images/icons/arrowBlack.png";
import animateArrow from "../../assets/images/icons/animateIcon.png";
import { toast } from "react-toastify";
import FloatingButton from "../../components/common/FloatingButton";

interface Errors {
  businessName?: string;
  fullname?: string;
  phone?: string;
  email?: string;
  checkbox?: string;
}
const Portfolio = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [currentWord, setCurrentWord] = useState(0);
  const navigate = useNavigate();
  const [accessRequestResponse, setAccessRequestResponse] = useState("");
  const words = ["Business", "Success", "Enterprise"];
  const accessPortfolioRef = useRef<HTMLDivElement | null>(null);
  const [otpEmail, setOtpEmail] = useState(new Array(6).fill(""));
  const [otpPhone, setOtpPhone] = useState(new Array(6).fill(""));
  const [currentModal, setCurrentModal] = useState(1);
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState("")

  // apis
  const { mutate: portfolioAcess } = useApiMutation("requestAccess");
  const { mutate } = useApiMutation("verifyPhone");
  const { mutate: verifyEmail } = useApiMutation("verifyEmail");

  const verifyOtp = () => {
    const phoneOtp = otpPhone.join("");
    const payload: any = {
      otp: phoneOtp,
      phoneNumber: "+" + phone,
    };

    try {
      mutate(payload, {
        onSuccess: (data: any) => {
          // console.log("Phone OTP verified", data);
          setOtpError(false);
          setCurrentModal(2);
          toast.success("Phone OTP verified", { theme: 'dark' })

        },
        onError: (error: any) => {
          // console.log("Phone OTP error", error);
          toast.warning("Phone OTP error", { theme: 'dark' })
          setOtpError(true);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Verify email OTP
  const verifyOtpEmail = () => {
    const emailOtp = otpEmail.join("");
    const payload: any = {
      token: emailOtp,
      email,
    };

    try {
      verifyEmail(payload, {
        onSuccess: async (data: any) => {
          setEmailVerified(data?.emailVerified);
          setOtpError(false);
          setStatus(data?.status)
          await close();
          // navigate("/portfolios");
          toast.success("Email OTP verified", {theme: 'dark'})

        },
        onError: (error: any) => {
          console.log("Email OTP error", error);
          toast.warning("Email OTP error", {theme: 'dark'})
          setOtpError(true);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prevWord) => (prevWord + 1) % words?.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleMouseMove = (e: React.MouseEvent) => {
    const teamUpSection = document.querySelector(
      ".ready-to-team-up"
    ) as HTMLElement;
    if (teamUpSection) {
      const rect = teamUpSection.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      teamUpSection.style.setProperty("--mouse-x", `${x}px`);
      teamUpSection.style.setProperty("--mouse-y", `${y}px`);
    }
  };

  const handleVerify = async () => {
    const newErrors: Errors = {};

    if (!phone) {
      newErrors.phone = "Phone number is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!isChecked) {
      newErrors.checkbox = "You must agree to the Privacy Policy and terms";
    }
    if (!fullName) {
      newErrors.fullname = "Full Name is required";
    }

    if (!businessName) {
      newErrors.businessName = "Business Name is required";
    }

    setErrors(newErrors);

    if (Object?.keys(newErrors)?.length === 0) {
      const payload: any = {
        businessName,
        name: fullName,
        email,
        phoneNumber: "+" + phone,
      };
      await portfolioAcess(payload, {
        onSuccess: async (data: any) => {
          // setEmailVerified(data?.emailVerified);
          setStatus(data?.status)
          if (!data?.emailVerified) {
            open();
          } else if (data?.status === "Approved") {
            // navigate("/portfolios");
            navigate(`/portfolios?email=${email}`);
          }
        },
        onError: (data: any) => {
          console.log("dtaaa", data);
        },
      });
    }
  };

  const handleLogin = async () => {
    const newErrors: Errors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }
    setErrors(newErrors);
    if (Object?.keys(newErrors)?.length === 0) {
      const payload: any = {
        email: email
      };

      portfolioAcess(payload, {
        onSuccess: async (data: any) => {
          setEmailVerified(data?.emailVerified);
          if (!data?.emailVerified) {
            open();
          } else {
            if (data?.message === "Request approved") {
              // navigate("/portfolios");
              navigate(`/portfolios?email=${email}`);

            } else {
              setIsLoggedIn(false);
              setEmailVerified(data?.emailVerified);
            }
          }
        },
        onError: (data: any) => {
          // console.log("dtaaa33", data);
          toast.warning('Unauthorized Access', { theme: 'dark' })
        },
      });

    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: "phone" | "email" | "fullname" | "businessName"
  ) => {
    const { value } = e.target;
    if (field === "phone") {
      setPhone(value);
      if (errors.phone && value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      } else if (value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Phone number is required",
        }));
      }
    } else if (field === "fullname") {
      setFullName(value);
      if (errors.fullname && value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, fullname: "" }));
      } else if (value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fullname: "Full name is required",
        }));
      }
    } else if (field === "businessName") {
      setBusinessName(value);
      if (errors.businessName && value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, businessName: "" }));
      } else if (value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          businessName: "Business name is required",
        }));
      }
    } else if (field === "email") {
      setEmail(value);
      if (errors.email && value !== "" && validateEmail(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      } else if (value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email is required",
        }));
      } else if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email format",
        }));
      }
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    if (errors.checkbox && e.target.checked) {
      setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
    }
  };

  const handlePhoneChange = (phone: string) => {
    setPhone(phone);
    if (errors.phone && phone !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
    } else if (phone === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Phone number is required",
      }));
    }
  };
  return (
    <div className="portfolio-container">
      {/* <TopHeader /> */}
      <OtpModal
        close={close}
        opened={opened}
        email={email}
        phone={phone}
        verifyOtp={verifyOtp}
        verifyOtpEmail={verifyOtpEmail}
        setOtpPhone={setOtpPhone}
        setOtpEmail={setOtpEmail}
        otpEmail={otpEmail}
        otpPhone={otpPhone}
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
        otpError={otpError}
      />
      <section className="access-portfolio" ref={accessPortfolioRef}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1>ACCESS</h1>
            <div style={{ display: "flex" }}>
              <span className="expand-image">
                <img src={GetAccesImage} alt="Dynamic" />
              </span>
              <div style={{ width: "12px" }}></div>
              <h1 className="moving-text">OUR</h1>
            </div>
            <h1>PORTFOLIO</h1>
          </div>
          <p
            style={{
              wordWrap: "break-word",
              textWrap: "pretty",
              width: "504px",
            }}
          >
            Gaskon grants investors unparalleled access to premier opportunities in cutting-edge enterprises, revolutionizing the future of global markets through strategic investments.
          </p>
        </div>
        <div style={{ width: "180px" }}></div>
        {
          status === "Awaiting Approval" &&
            !isLoggedIn ? (
            // <div className="email-verification">
            <div className="pending-box">
              <div className="flex flex-col items-start justify-center gap-2 px-16 py-9">
                <h2 className="text-2xl font-bold">
                  Account Verification in Progress{" "}
                </h2>
                <p className="text-base font-normal text[#4B4B4B] text-balance mb-8">
                  We're verifying your details to ensure a secure experience. Please
                  allow some time for this process. You’ll receive a notification at
                  your registered email once the verification is complete. Thank you
                  for your patience!
                </p>
                <Link
                  to="/home"
                  className=" font-semibold text-base"
                  style={{ color: "#0177D1", textDecoration: "underline" }}
                >
                  Back to Home
                </Link>
              </div>
              {/* </div> */}
            </div>
          ) : isLoggedIn ? (
            <>
              <div className="flex flex-col items-start justify-center gap-2  pt-4 mt-5 md:mt-auto">
                <span className="text-5xl font-semibold uppercase">
                  Welcome Back !{" "}
                </span>
                <span
                  className="text-sm font-semibold mb-4"
                  style={{ color: "#989898" }}
                >
                  Sign in to your account
                </span>
                <div className="form-container md:p-0">
                  <div className="input-group" style={{ border: errors.email && "1px solid #AE0101", borderRadius: errors.email && "5px" }}>
                    <label className="label" style={{ color: errors.email && "#AE0101" }}>Email Id*</label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                  </div>
                </div>
                {errors.email && (
                  <h2
                    className="error text-xs font-normal mb-3"
                    style={{ color: "#AE0101" }}
                  >
                    {errors.email}
                  </h2>
                )}
                {!errors?.email && (

                  <Link to="/Access" onClick={() => {
                    setIsLoggedIn(false);
                    setErrors({})
                  }}>
                    <div className="sign-in-link mb-3">
                      Not Verified? Verify Now
                    </div>
                  </Link>
                )}
                <div className="mid">
                  <button
                    onClick={handleLogin}
                    className="btn flex items-center justify-between"
                  >
                    <span className="btn-text">Sign In</span>
                    <div className="arrow-container">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                        }}
                      >
                        <img src={arrow} alt="Sign in Arrow" className="arrow" />
                        <img
                          src={animateArrow}
                          alt="New Arrow"
                          className="new-arrow"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="email-verification">
              <div className="login-form">
                <div
                  className="input-section"
                  style={{
                    borderBottom: errors?.businessName && "1px solid red",
                  }}
                >
                  <label>Business Name*</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={businessName}
                      onChange={(e) => handleInputChange(e, "businessName")}
                    />
                    {errors?.businessName && (
                      <span className="error text-rose-500 text-xs">
                        {errors?.businessName}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="input-section"
                  style={{ borderBottom: errors?.fullname && "1px solid red" }}
                >
                  <label>Full Name*</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => handleInputChange(e, "fullname")}
                    />
                    {errors.fullname && (
                      <span className="error text-rose-500 text-xs mr-4 w-28 md:w-auto md:mr-0">
                        {errors.fullname}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="input-section"
                  style={{ borderBottom: errors.phone && "1px solid red" }}
                >
                  <label>Phone*</label>
                  <div className="input-wrapper">
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={handlePhoneChange}
                      enableSearch={true}
                      placeholder="Enter phone number"
                      inputStyle={{
                        width: "100% !important",
                        padding: "10px",
                        fontSize: "16px",
                        // border: "none",
                        paddingLeft: "48px",
                        borderBottom: "1px solid #e1e5e4",
                      }}
                      containerClass="custom-phone-input"
                      buttonStyle={{
                        border: "1px solid #e1e5e4",
                      }}
                    />
                    {errors.phone && (
                      <span className="error text-rose-500 text-xs errorPhone">
                        {errors.phone}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="divider"></div> */}
                <div
                  className="input-section"
                  style={{ borderBottom: errors.email && "1px solid red" }}
                >
                  <label>Email*</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                    {errors.email && (
                      <span className="error text-rose-500 text-xs w-28 md:w-auto mr-6 md:mr-0">
                        {errors.email}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex text-center justify-center gap-2 mt-4">
                <div>
                  <Checkbox
                    // defaultChecked
                    label="I agree to  Privacy Policy and terms. We promise to keep your information safe & sound."
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    styles={(theme) => ({
                      input: {
                        border: !isChecked ? "1px solid #989898" : "none",
                        // backgroundColor: isChecked ? theme.colors.blue[6] : 'transparent',
                      },
                    })}
                  />
                  <div className="flex text-left ml-7 mt-2">
                    {errors.checkbox && (
                      <span
                        className="error text-rose-500 text-xs"
                        style={{ color: "red" }}
                      >
                        {errors.checkbox}
                      </span>
                    )}
                  </div>
                </div>
                {/* <span className="text-xs font-medium self-center"> </span> */}
              </div>
              <div
                style={{
                  color: "#676767",
                  fontSize: 16,
                  fontFamily: "Outfit",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  visibility:
                    accessRequestResponse && accessRequestResponse !== ""
                      ? "visible"
                      : "hidden",
                }}
              >
                {accessRequestResponse}
              </div>
              <Link to="/Access" onClick={() => {
                setIsLoggedIn(true);
                setErrors({})
              }
              }>
                <div className="sign-in-link mt-6">
                  Already Registered? Sign in
                </div>
              </Link>
              <div style={{ height: "24px" }}></div>
              <button
                className="verify-now"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={handleVerify}
              >
                <button
                  style={{ paddingLeft: "19px" }}
                // onClick={() => handleVerify()}
                >
                  Verify Now
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40px",
                    height: "40px",
                    background: "white",
                    borderRadius: 9999,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={Tick} />
                </div>
              </button>
            </div>
          )}
      </section>
      <div
        style={{
          width: "80%",
          height: "46px",
          background: "rgba(57, 202, 221, 0.16)",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          backdropFilter: "blur(250px)",
          marginLeft: "200px",
          marginRight: "200px",
          opacity: 0.8,
        }}
        className="team-upperPart1"
      />
      <div
        style={{
          width: "90%",
          height: "46px",
          background: "rgba(57, 202, 221, 0.16)",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          backdropFilter: "blur(250px)",
          marginLeft: "100px",
          marginRight: "100px",
        }}
        className="team-upperPart2"
      />
      <section className="ready-to-team-up" onMouseMove={handleMouseMove}>
        <div className="gradient-shadow"></div>
        <h1>
          Connect with us
        </h1>
        <button
          className="lets-talk"
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ paddingLeft: "19px" }}>Let's talk</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40px",
              height: "40px",
              background: "black",
              borderRadius: 9999,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ArrowNE} />
          </div>
        </button>
        <p>Book a 15 min call</p>
      </section>
      {/* <div style={{height: "100px"}}></div> */}
      {/* <Footer />  */}
      <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />

    </div>
  );
};

export default Portfolio;
