import React, { useEffect, useRef } from "react";
import "./HeroSection.scss";
import Logo from "../../assets/images/icons/Gaskonlogo.svg";
// import Logo from "../../assets/images/icons/groupIcons.png";
import PyramidImage from "../../assets/images/icons/PyramidImg.png";
import StarImage from "../../assets/images/icons/StarImage.png";
import logo1 from "../../assets/images/icons/company_logo1.png";
import logo2 from "../../assets/images/icons/company_logo2.png";
import logo3 from "../../assets/images/icons/company_logo3.png";
import logo4 from "../../assets/images/icons/company_logo4.png";
import logo5 from "../../assets/images/icons/company_logo5.png";
import Vector from "../../assets/images/icons/Vector 464.svg";
import arrow from "../../assets/images/icons/arrowBlack.png";
import animateArrow from "../../assets/images/icons/animateIcon.png";
import { useNavigate } from "react-router-dom";

const HeroSection: React.FC = () => {
  const navigate = useNavigate();
  const logoContainerRef = useRef(null);
  const logoImgRef = useRef(null);

  useEffect(() => {
    const logoContainer: any = logoContainerRef.current;
    const logoImg: any = logoImgRef.current;

    const appendLogo = () => {
      const clone = logoImg.cloneNode(true);
      logoContainer.appendChild(clone);
    };

    const intervalId = setInterval(() => {
      appendLogo();
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className="hero-section">
      {/* <div className="gradient-circles" >
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </div> */}
      <section className="content">
        <div
          style={{
            padding: "6px 14px",
            border: "2px rgba(7, 186, 210, 0.75) solid",
            justifyContent: "center",
            alignItems: "center",
            display: "inline-flex",
          }}
          className="rounded-3xl"
        >
          <span
            style={{
              color: "#686868",
              fontFamily: "Outfit",
              wordWrap: "break-word",
            }}
            className="text-xs font-normal uppercase"
          >
            Trusted by 200+ Businesses Worldwide
          </span>
        </div>
        <h1 className="text-7xl font-semibold justify-center text-center">
          EXPERT CONSULTING &<br />
          INNOVATIVE SOLUTIONS
        </h1>
        <p className="font-outfit text-base font-normal text-center justify-center">
          Expert consultancy and cutting-edge digital solutions to drive growth
          and success
        </p>
        <div className="mid">
          <button
            onClick={() => {
              navigate("/get-started");
            }}
            className="btn flex items-center justify-between"
          >
            <span className="btn-text">Get Started</span>
            <div className="arrow-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <img src={arrow} alt="Get Started Arrow" className="arrow" />
                <img src={animateArrow} alt="New Arrow" className="new-arrow" />
              </div>
            </div>
          </button>
        </div>
      </section>
      <div className="side-images">
        <img src={PyramidImage} alt="Pyramid" className="pyramid-img" />
        <img src={StarImage} alt="Star" className="star-img" />
        <img src={Vector} alt="Vector" className="vector-img " />
      </div>
      <div className="company-logos">
        <div className="logos-scroll" ref={logoContainerRef}>
          <img
            src={Logo}
            className="logoImg"
            alt="Company Logo"
            ref={logoImgRef}
            onError={(e) => {
              if (e.target instanceof HTMLImageElement) {
                e.target.src = Logo;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
