import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PortfolioList.scss';
import evolve from '../../assets/images/icons/e-volve1.jpg';
import Norwood from '../../assets/images/icons/norwoodframe.png';
import Century21 from '../../assets/images/icons/century21logo.png';
import GridIcon from '../../assets/images/icons/GridIcon.svg';
import ListViewIcon from '../../assets/images/icons/ListViewIcon.svg';
import GridIconActive from '../../assets/images/icons/GridIconActive.svg';
import ListViewIconActive from '../../assets/images/icons/ListViewIconActive.svg';
import StarIcon from '../../assets/images/emojistar1.png';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import defaultPlaceholder from '../../assets/images/default-placeholder.png'
import Pagination from '../../components/Pagination';
import Footer from '../../components/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import { clientImagePath, clientPaths } from '../../hooks/common.utils';
import NewTopHeader from '../../components/NewHeader';

// Step 1: Define the Company interface
interface Company {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  number: string;
  fundingProgress: number;
  projectedMultiplier: number;
  roi: number;
  investmentPotential: string;
  category: string;
  clientId: string;
  clientRedirect?: any
  returnOfInvestment: string
}

// const companies: Company[] = [
//   { id: 1, name: 'E-Volve', description: 'Revolutionizing the tech industry with cutting-edge software solutions and innovative AI technologies.', imageUrl: evolve, number: '01', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Technology" },
//   { id: 2, name: 'Norwood Energy', description: 'Norwood Energy Corp is a Texas based oil and gas company vertically integrated in all your oil and gas needs', imageUrl: Norwood, number: '02', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Health Care" },
//   { id: 3, name: 'Century 21', description: 'Renowned brewery producing high-quality beers enjoyed worldwide.', imageUrl: Century21, number: '03', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Consumer Goods" },
//   { id: 1, name: 'E-Volve', description: 'Revolutionizing the tech industry with cutting-edge software solutions and innovative AI technologies.', imageUrl: evolve, number: '01', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Technology" },
//   { id: 2, name: 'Norwood Energy', description: 'Norwood Energy Corp is a Texas based oil and gas company vertically integrated in all your oil and gas needs', imageUrl: Norwood, number: '02', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Health Care" },
//   { id: 3, name: 'Century 21', description: 'Renowned brewery producing high-quality beers enjoyed worldwide.', imageUrl: Century21, number: '03', fundingProgress: 30, roi: 28, projectedMultiplier: 3, investmentPotential: "High", category: "Consumer Goods" },
//   // { id: 4, name: 'Mayo Clinic', description: 'Renowned medical center, providing expert patient care, research, and education.', imageUrl: MayoClinic, number: '04' },
//   // { id: 5, name: 'Nestlé', description: 'World\'s largest food company, offering high quality nutrition products globally', imageUrl: Nestle, number: '05' },
//   // { id: 6, name: 'Hertz', description: 'Leading global car rental service, providing reliable, convenient vehicle solutions.', imageUrl: Cococola, number: '06' },
//   // { id: 7, name: 'Danone', description: 'French company specializing in dairy and healthy food products.', imageUrl: MayoClinic, number: '07' },
//   // { id: 8, name: 'Starbucks', description: 'Famous for coffee and expanding into diverse food and beverage items.', imageUrl: Nestle, number: '08'},
//   // { id: 9, name: 'PepsiCo', description: 'Multinational corporation known for its diverse food and drink brands.', imageUrl: 'https://via.placeholder.com/399x399', number: '09' },
//   // { id: 10, name: 'Blue Origin', description: 'Innovating private spaceflight for cargo, astronauts, and lunar landings.', imageUrl: 'https://via.placeholder.com/399x399', number: '10' },
//   // { id: 11, name: 'Popeyes', description: 'Famous for its crispy, Cajun-inspired fried chicken and Louisiana-style flavors', imageUrl: 'https://via.placeholder.com/399x399', number: '11' },
//   // { id: 12, name: 'Example 13', description: 'Description for Example 13', imageUrl: 'https://via.placeholder.com/399x399', number: '12' },
//   // { id: 13, name: 'Example 14', description: 'Description for Example 14', imageUrl: SpaceX ,number: '13' },
//   // { id: 14, name: 'Example 15', description: 'Description for Example 15', imageUrl: Heikin, number: '14' },
// ];

// const categories = ['All Companies', 'Technology', 'Health Care', 'Renewable Energy', 'Consumer Goods', 'Industrial Manufacturing', 'Architectural Services']

// const CompanyCard: React.FC<{ company: Company, index: number }> = ({ company, index }) => {
//   const navigate = useNavigate();

//   const handleNavigation = () => {
//     if (company.name === 'Norwood Energy') {
//       navigate('/NorwoodEnergy');
//     } else if (company.name === 'Century 21') {
//       navigate('/Century21');
//     }
//   };
//   return (
//     <div className="company-card" onClick={handleNavigation}>
//       {index % 2 === 0 ? (
//         <>
//           <div className="company-card-header">
//             <span className="company-card-number">{company.number}</span>
//             <div style={{ width: "20px" }}></div>
//             <div style={{ display: "flex", flexDirection: "column" }}>
//               <span className="company-card-title">{company.name}</span>
//               <div className="company-card-description">{company.description}</div>
//             </div>
//           </div>
//           <div style={{ height: "30px" }}></div>
//           <img src={company.imageUrl} alt={company.name} className="company-card-image" />
//         </>
//       ) : (
//         <>
//           <img src={company.imageUrl} alt={company.name} className="company-card-image" />
//           <div style={{ height: "30px" }}></div>
//           <div className="company-card-header">
//             <span className="company-card-number">{company.number}</span>
//             <div style={{ width: "20px" }}></div>
//             <div style={{ display: "flex", flexDirection: "column" }}>
//               <span className="company-card-title">{company.name}</span>
//               <div className="company-card-description">{company.description}</div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };
const CompanyCard: React.FC<{ company: Company, index: number }> = ({ company, index }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (company.name === 'Norwood Energy') {
      navigate('/NorwoodEnergy');
    } else if (company.name === 'Century 21') {
      navigate('/Century21');
    } else if (company.name === 'E-Volve') {
      navigate('/EvolveEnergy');
    }
  };
  return (
    <div className="company-card" onClick={handleNavigation}>
      {index % 2 === 0 ? (
        <>
          <div className="company-card-header">
            <span className="company-card-number">{company.number}</span>
            <div style={{ width: "20px" }}></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="company-card-title">{company.name}</span>
              <div className="company-card-description">{company.description}</div>
            </div>
          </div>
          <div style={{ height: "30px" }}></div>
          <img src={company.imageUrl} alt={company.name} className="company-card-image" onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = defaultPlaceholder;
          }} />
        </>
      ) : (
        <>
          <img src={company.imageUrl} alt={company.name} className="company-card-image" onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = defaultPlaceholder;
          }} />
          <div style={{ height: "30px" }}></div>
          <div className="company-card-header">
            <span className="company-card-number">{company.number}</span>
            <div style={{ width: "20px" }}></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="company-card-title">{company.name}</span>
              <div className="company-card-description">{company.description}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const InvestmentCard: React.FC<Company> = ({
  category,
  name,
  description,
  fundingProgress,
  projectedMultiplier,
  returnOfInvestment,
  investmentPotential,
  roi,
  imageUrl,
  clientId,
  clientRedirect
}) => {
  const [isHovered, setIsHovered] = useState(window.innerWidth < 768);
  let fund = Number(fundingProgress?.toString()?.replace("%", ""));
  let return_invest = Number(returnOfInvestment?.toString()?.replace("%", ""));
  console.log(fund)
  // console.log()
  return (
    <div
      className="investment-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => clientRedirect(clientId)}
    >
      <div className="investment-card-header">
        <div className="card-header-left">
          <div style={{ alignItems: 'center', gap: '4px', display: 'inline-flex' }}>
            <div style={{ width: '4px', height: '4px', background: isHovered ? '#0177D1' : '#989898', transform: 'rotate(45deg)' }} />
            <div className="category-title" style={{ color: isHovered ? '#0177D1' : '#989898', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '700', textTransform: 'uppercase', lineHeight: '12px', wordWrap: 'break-word' }}>{category}</div>
          </div>
          <div className="title">{name}</div>
        </div>
        <div className="high-badge uppercase"><div style={{ color: '#4EA737', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>{roi}</div></div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
        <div className="image-container">
          <img className='image-container-img' src={imageUrl} alt={name} onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = defaultPlaceholder;
          }} />
        </div>
        {window.innerWidth > 768 && !isHovered ? (
          <div className="description">{description}</div>
        ) : (
          <div className="border-style">
            <div className="hover-details">
              <div className="progress-circle">
                <CircularProgressWithLabel value={Number(fund)} />
                <div className="label">Funding Progress</div>
              </div>
              <div className="progress-circle">
                <div className="circle">
                  <span>{projectedMultiplier}</span>
                </div>
                <div className="label">Projected Multiplier</div>
              </div>
              <div className="progress-circle">
                <CircularProgressWithLabel value={Number(return_invest)} />
                <div className="label">Return Of Investment</div>
              </div>
            </div>
          </div>
        )}

        {window.innerWidth <= 768 ?
          (<div className="description">{description}</div>) : ('')
        }
      </div>
    </div>
  );
};

const PortfolioList: React.FC = () => {

  const investmentCardRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(12);
  const [selectedCateogry, setSelectedCategory] = useState('All Companies');
  const [loading, setLoading] = useState(false);
  const [gridViewSelected, setGridViewSelected] = useState(true);
  const [companies, setCompanies] = useState<any>([])
  // Get current companies
  const filteredCompanies = selectedCateogry === 'All Companies'
    ? companies
    : companies.filter((company: any) => company.category === selectedCateogry);
  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = filteredCompanies?.slice(indexOfFirstCompany, indexOfLastCompany);

  const [categories, setCategories] = useState<string[]>([])

  const location = useLocation();

  const clientRedirect = (clientId: string) => {
    if (clientPaths[clientId]) {
      window.open(clientPaths[clientId], '_blank');
    } else {
      toast.warning('Route not found', { theme: 'dark' })
    }
  };

  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // get portfolios
  const getPortfolios = async () => {
    try {
      let api = `${process.env.REACT_APP_BASE_URL}portfolios`;
      const res = await axios.get(api, { headers: { email: email } });

      if (res?.data) {
        let data = res?.data?.portfolios;
        console.log(data);

        const mappedData = data?.map((item: any) => ({
          ...item,
          id: item.clientId,
          name: item.businessName,
          description: item.businessGoals,
          imageUrl: clientImagePath[item.clientId],
          number: item.employeeCount,
          fundingProgress: item?.fundingProgress,
          roi: item?.riskLevel,
          projectedMultiplier: item?.projectedMultiplier,
          investmentPotential: item?.investmentPotential,
          category: item.businessType
        }));

        setCompanies(mappedData);
        setCategories(["All Companies"].concat(Array.from(new Set(data?.map((e: any) => e?.businessType)))));
      } else {
        toast.info(res?.data?.message || "No data available", { theme: 'dark' });
      }
    } catch (error: any) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response);
        toast.error(`Error: ${error.response.data.message || 'Failed to fetch portfolios'}`, { theme: 'dark' });
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
        toast.error("No response from server. Please try again later.", { theme: 'dark' });
      } else {
        // Something else happened in making the request
        console.error("Error:", error.message);
        toast.error("An error occurred. Please try again.", { theme: 'dark' });
      }
    }
  };


  useEffect(() => {
    getPortfolios()
  }, [email])

  function updateCardBorders() {
    const borderColor = '#E9E9E9';
    const container = investmentCardRef.current;
    if (!container) return;
    const cards = container.getElementsByClassName('investment-card');
    if (!cards.length) return;

    const containerWidth = container.offsetWidth;
    const cardWidth = (cards[0] as HTMLElement).offsetWidth;
    const cardsPerRow = Math.floor(containerWidth / cardWidth);
    const totalRows = Math.ceil(cards.length / cardsPerRow);

    for (let i = 0; i < cards.length; i++) {
      (cards[i] as HTMLElement).style.border = 'none';
    }

    for (let i = 0; i < cards.length; i++) {
      const row = Math.floor(i / cardsPerRow);
      if (row < totalRows - 1) {
        (cards[i] as HTMLElement).style.borderBottom = '1px solid ' + borderColor;
      }

      if ((i + 1) % cardsPerRow !== 0) {
        (cards[i] as HTMLElement).style.borderRight = '1px solid ' + borderColor;
      }
    }

  }

  useEffect(() => {
    window.addEventListener('resize', updateCardBorders);
    updateCardBorders();
  });
  const getGridView = () => {
    return (
      <div className="investment-card-flex" ref={investmentCardRef}>
        {currentCompanies.map((company: any, index: number) => (
          <div
            onClick={() => { gotoPortfolio(company) }}
          >
            <InvestmentCard
              key={index} {...company}
              clientId={company.clientId}
              clientRedirect={clientRedirect} />
          </div>
        ))}
      </div>
    );
  }

  const gotoPortfolio = (company: any) => {
    if (company.name === 'E-Volve') {
      navigate('/EvolveEnergy')
    } else if (company.name === 'Norwood Energy') {
      navigate('/NorwoodEnergy')
    } else if (company.name === 'Century 21') {
      navigate('/Century21')
    }
  }
  const getListView = () => {
    return (<table className="table">
      <thead>
        <tr>
          <th className='first-column' align="center">Company</th>
          <th align="center" >Funding Progress</th>
          <th align="center">Projected Multiplier</th>
          <th align="center">Return Of Investment</th>
          <th align="center">Investment Potential</th>
        </tr>
      </thead>
      <tbody>
        {
          loading ?
            (<div className="skeleton">
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
            </div>)
            : (
              currentCompanies.map((company: any, index: number) => (
                <tr onClick={() => { gotoPortfolio(company) }} key={index} style={{ height: '255px', padding: '32px' }}>
                  <td data-label="Name" align="center" className="bold-and-large first-column">
                    <div onClick={() => clientRedirect(company.clientId)} style={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'flex-start', paddingRight: '16px' }}>
                      <img src={company.imageUrl} style={{ width: '200px', height: "auto" }}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = defaultPlaceholder;
                        }} />
                      <div className='items-center' style={{ flexDirection: 'column', display: 'flex' }}>
                        <div style={{ alignSelf: 'stretch', height: '124px', flexDirection: 'column', gap: '8px', display: 'flex' }}>
                          <div style={{ alignItems: 'center', gap: '4px', display: 'inline-flex' }}>
                            <div style={{ width: '4px', height: '4px', background: '#0177D1', borderRadius: '9999px' }} />
                            <div style={{ color: '#0177D1', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '700', textTransform: 'uppercase', lineHeight: '12px', wordWrap: 'break-word' }}>{company.category}</div>
                          </div>
                          <div style={{ alignSelf: 'stretch', color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '16px', wordWrap: 'break-word', textAlign: 'left' }}>{company.name}</div>
                          <div style={{ alignSelf: 'stretch', color: '#686868', fontSize: '14px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '20px', wordWrap: 'break-word', textAlign: 'left' }}>{company.description}</div>
                        </div>
                        {/* <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                            <div style={{ color: '#0177D1', fontSize: 16, fontFamily: 'Outfit', fontWeight: '600', lineHeight: 16, wordWrap: 'break-word' }}>Know More</div>
                            <div style={{ width: 20, height: 20, position: 'relative' }}>
                              <div style={{ width: 13.33, height: 12.96, left: 3.33, top: 3.52, position: 'absolute', background: '#0177D1' }}></div>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </td>
                  <td data-label="Name" align="center">
                    <div className='items-center' style={{ color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '16px', wordWrap: 'break-word' }}>{company.fundingProgress}</div>
                  </td>
                  <td data-label="Name" align="center" className="bold-and-large">
                    <div  className='items-center' style={{ color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '16px', wordWrap: 'break-word' }}>{company.projectedMultiplier}</div>
                  </td>
                  <td data-label="Name" align="center" className="bold-and-large">
                    <div style={{ width: '100%', color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '16px', wordWrap: 'break-word' }}>{company.returnOfInvestment}</div>
                  </td>

                  <td data-label="Name" align="center" className="bold-and-large">
                    <div style={{ padding: '6px 11px', background: 'rgba(78, 167, 55, 0.24)', borderRadius: '8px', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                      <div style={{ color: '#4EA737', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>{company.roi}</div>
                    </div>
                  </td>
                </tr>
              ))
            )
        }
      </tbody>
    </table>)
  }

  return (
    <div className="app">
      {/* Header */}
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
          background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
        }}>
          <NewTopHeader />
        </div>
      </div>
      {/* <TopHeader /> */}
      <header className="app-header">
        <div className='title-wrapper'>
          <div className='title-container'>Star-Studded <br />Investment Opportunities
          </div>
          <div style={{ width: '100%', color: '#B9B9B9', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word' }}
          >Explore our curated portfolio of top-performing companies across various industries. Dive into detailed insights and performance metrics to make informed investment decisions. Unlock potential and drive growth with our comprehensive portfolio listings.
          </div>
        </div>
        <div className='star'>
          <img src={StarIcon} alt='star' className='star-image' />
        </div>
        <div className='gradient-circles hidden md:block'>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
        </div>
      </header>
      <div className="body">
        <div className="view-switch-icons" style={{ flexDirection: 'row-reverse', gap: '20px', display: 'flex', cursor: "pointer" }}>
          <img src={gridViewSelected ? GridIconActive : GridIcon} style={{ width: '15px', height: "16px", cursor: 'pointer' }} onClick={() => setGridViewSelected(true)} />
          <img src={gridViewSelected ? ListViewIcon : ListViewIconActive} style={{ width: '15px', height: "16px", cursor: 'pointer' }} onClick={() => setGridViewSelected(false)} />
        </div>
        <div className="features-container">
          <div className="features">
            {categories.map((category) => (
              <div className="feature" onClick={() => setSelectedCategory(category)}>
                <div style={{ padding: '12px 16px', background: selectedCateogry == category ? '#0177D1' : '', border: selectedCateogry == category ? '' : '2px #E9E9E9 solid', borderRadius: '30px', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                  <div style={{ color: selectedCateogry == category ? 'white' : '#686868', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '16px', wordWrap: 'break-word' }}>{category}</div>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div style={{ height: '100%' }} >
          {gridViewSelected ? getGridView() : getListView()}
        </div>
      </div>
      {/* <div className="company-grid">
        {currentCompanies.map((company, index) => (
          <CompanyCard key={company.id} company={company} index={index} />
        ))}
      </div> */}
      <Pagination companiesPerPage={companiesPerPage} totalCompanies={currentCompanies.length} currentPage={currentPage} paginate={paginate} />
      {/* <Footer /> */}
    </div>
  );
}

export default PortfolioList;