import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';
import { isValidEmail } from '../../../../components/Contact_Us/GetInTouchButton';
import Cookies from 'js-cookie';
import axios from 'axios';
import Bg1 from '../../../../assets/images/lusso/Bg.svg';

export default function ConnectSection() {
    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef<any>(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const token = Cookies.get('authToken');
    const [loading, setLoading] = useState(false)

    // Handle click outside popup to close
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const { name, email, phone, message } = formData;
            setLoading(true)
            // Validate the form data
            if (!name || !email || !phone || !message) {
                toast.error('Please fill in all the required fields.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                return;
            }

            if (!isValidEmail(email)) {
                toast.error('Please enter a valid email address.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                return;
            }

            const payload = {
                name,
                email,
                phone,
                message,
                meetingLink: '',
            };
            let headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.post(`
            ${process.env.REACT_APP_BASE_URL}enquiry/${process.env.REACT_APP_CLIENT_LUSSO_ID}`,
                payload,
                { headers: headers });

            if (response.status === 200) {
                // Reset the form data
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setIsOpen(false);
                setLoading(false)


                // Show a success message using Toastify
                toast.success('Form submitted successfully!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                setLoading(false)

                // Handle the error case
                toast.error('Error submitting the form.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }


        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'An error occurred while submitting the form.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setLoading(false)
        }
    };

    return (
        <div className="bg-[#010101] p-4">
            <div className="relative w-full md:w-[80%] mx-auto text-center min-h-screen flex items-center justify-center">
                {/* Background dots */}
                <div className="absolute ml-5 inset-0 grid grid-cols-[repeat(20,minmax(0,1fr))] gap-4 opacity-20 pointer-events-none">
                    {[...Array(500)].map((_, i) => (
                        <div key={i} className="w-1 h-1 bg-white rounded-full"></div>
                    ))}
                </div>

                {/* Content */}
                <div className="relative z-10">
                    <h1 className="text-6xl font-bold text-white mb-6">
                        LET'S CONNECT!
                    </h1>
                    <p className="text-gray-300 text-lg mb-8 max-w-2xl mx-auto">
                        At Lusso.ai, we value open communication and are eager to discuss how we can partner for mutual growth.
                    </p>
                    <button
                        onClick={() => setIsOpen(true)}
                        className="bg-[#7D3CF3] tracking-tighter text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-purple-700 transition duration-300 mb-6"
                    >
                        SCHEDULE A MEETING
                    </button>
                    <p className="text-gray-400">
                        or contact us at <a href="mailto:info@lussolabs.io" className="text-[#02C3CD] hover:underline">info@lussolabs.io</a>
                    </p>
                </div>

                {/* Popup Form */}
                {isOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                        <div ref={popupRef} className="bg-white rounded-3xl shadow-xl max-w-[95%] md:max-w-7xl w-full md:max-h-[90%] overflow-hidden p-3">
                            <div className="flex flex-col lg:flex-row md:gap-3">
                                <div className="lg:w-[40%] p-3 px-4 md:p-6 bg-[#E6F1FA] rounded-xl overflow-y-auto">
                                    <div className="flex justify-between items-center mb-2">
                                        <h2 className="text-xl font-bold">GET IN TOUCH WITH US</h2>
                                        <button
                                            onClick={() => setIsOpen(false)}
                                            className="md:hidden text-white bg-[#686868] rounded-full p-1"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                    <p className="text-xs text-[#676767] mb-3 text-start">
                                        Fill out the form below or Schedule a meeting with us
                                    </p>

                                    <form onSubmit={handleSubmit} className="text-start">
                                        <div className="mb-4 bg-white rounded-2xl w-full md:max-w-md mx-auto pt-5"
                                            style={{ border: '1px solid #E8E8E8' }}>
                                            <div className="mb-4 pb-5 px-4 md:px-8 border-b border-b-slate-400">
                                                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name*</label>
                                                <input
                                                    required
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Enter Name"
                                                    className="appearance-none border-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>

                                            <div className="mb-4 pb-5 px-4 md:px-8 border-b border-b-slate-400">
                                                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email ID*</label>
                                                <input
                                                    required
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Enter Email"
                                                    className="w-full p-2 border-none rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                                                />
                                            </div>

                                            <div className="mb-4 pb-5 px-4 md:px-8 border-b border-b-slate-400">
                                                <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone Number*</label>
                                                <PhoneInput
                                                    country={"us"}
                                                    value={formData.phone} // The phone number state
                                                    onChange={(value: any) => {
                                                        setFormData({ ...formData, phone: value });
                                                    }}  // Handler to set the phone number
                                                    enableSearch={true} // Allows country search
                                                    placeholder="Enter phone number"
                                                    buttonStyle={{
                                                        border: "none"
                                                    }}
                                                    inputStyle={{ border: 'none' }}
                                                    inputClass="appearance-none border-0 rounded w-full py-2 pe-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" // Your custom input styles
                                                />
                                            </div>

                                            <div className="mb-4 px-4 md:px-8">
                                                <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message*</label>
                                                <textarea
                                                    required
                                                    id="message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    rows={2}
                                                    placeholder="Leave us your message here"
                                                    className="w-full p-2 border-none rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center gap-2 mb-4">
                                            <input
                                                required
                                                type="checkbox"
                                                id="privacy-policy"
                                                className="h-5 w-5 text-purple-600 cursor-pointer"
                                            />
                                            <label htmlFor="privacy-policy" className="text-[#686868] text-sm cursor-pointer">
                                                I agree to Privacy Policy and terms. We promise to keep your information safe and sound.
                                            </label>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-full bg-white border-2 border-blue-300 text-[#0177D1] font-bold p-3 rounded-xl mb-4 hover:bg-blue-50 transition duration-300"
                                        >
                                            {loading ? 'Sending ...' : 'Send'}
                                        </button>

                                        <div className="flex items-center my-6">
                                            <div className="flex-grow border-t border-gray-400"></div>
                                            <span className="flex-shrink mx-4 text-[#676767] font-bold">or</span>
                                            <div className="flex-grow border-t border-gray-400"></div>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={() => window.open('https://calendly.com/lussolabs/evolve-energy-investment-enquiry', '_blank')}
                                            className="w-full bg-[#1E1E1E] text-white font-bold p-3 rounded-xl hover:bg-gray-800 transition duration-300 flex items-center justify-center gap-2"
                                        >
                                            <Calendar className="w-5 h-5" />
                                            Schedule Meeting
                                        </button>
                                    </form>
                                </div>

                                <div className="hidden lg:block lg:w-[55%] rounded-xl">
                                    <img
                                        src={Bg1}
                                        alt="Solar Panels"
                                        className="w-full h-[99%] object-cover rounded-xl bg-center"
                                        style={{ backgroundPosition: 'center' }}
                                    />
                                </div>

                                <div className="hidden md:block h-auto">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="text-white bg-[#686868] rounded-full p-1 hover:bg-gray-600 transition duration-300"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}