import React from "react";
import Approach from "./Approach";
import Fund from "./Fund";
import Home from "./Home";

import arrow from "../../assets/images/icons/arrowBlack.png";
import animateArrow from "../../assets/images/icons/animateIcon.png";
import "./about_us.scss";
import SustainabilityIcon from "../../assets/images/icons/sustain.png";
import IntegrityIcon from "../../assets/images/icons/integrityAbout.png";
import InnovativeBulbIcon from "../../assets/images/icons/innovations.png";
import ExcellenceIcon from "../../assets/images/icons/ExcellenceIcon.svg";
import ValuesBgGradient from "../../assets/images/icons/ValuesBgGradient.svg";
import DiamondWallPaper from "../../assets/images/icons/DiamondWallpaper.png";
import Star from "../../assets/images/icons/start.png";
import Subtract from "../../assets/images/icons/Subtract.png";
import Contact_us from "../../pages/Landing/Contact_us";
import { useNavigate } from "react-router-dom";
import WavesImage from "../../assets/images/aboutPattern.png";
import Circle from "../../assets/images/icons/light.png";
import Excellence from "../../assets/images/icons/excellenceIcon.png";
import ScrollLogos from "../scrollLogos/ScrollLogos";
import FloatingButton from "../common/FloatingButton";

export default function About_us() {
  const navigate = useNavigate();
  return (
    <div className="about-container">
      <div className="hero-section backgroundImg flex items-center justify-center">
        <section className="text-center flex flex-col items-center justify-center">
          <div className="flex flex-col items-center">
            <span
              className="font-semibold md:text-6xl text-2xl py-1 w-auto md:w-3/4 text-balance text-center uppercase"
              style={{ color: "#FFFFFF" }}
            >
              WE Unlock Business Horizons with Gaskon’s Strategic Brilliance
            </span>
          </div>
          <p
            className="font-[Outfit] text-center text-base font-normal"
            style={{ color: "#FFFFFF" }}
          >
            Discover limitless opportunities through our innovative solutions
            and expert guidance, tailored to drive your success.
          </p>
        </section>
      </div>

      <div className="mission-section">
        <div className="mission-intro">
          <div className="mission-tag-container px-4 py-1 mb-5">
            <span className="mission-tag text-xs font-normal uppercase">
              Our Mission
            </span>
          </div>
          <div className="font-semibold md:text-7xl text-5xl text-center uppercase w-full">
            <h1 className="mission-title">empower businesses & investors</h1>
          </div>
          <p className="mission-description text-base font-normal text-center text-wrap md:w-3/4">
            By bridging the gap between opportunity and achievement. With a
            focus on transparency, client-centric service, and long-term growth,
            we ensure that every decision is informed, every action is
            purposeful, and every outcome is exceptional.
          </p>
        </div>
      </div>
      <div className="values-section md:p-24 py-16 md:py-auto px-8 flex-col items-start md:flex-row justify-around  max-w-none">
        <div className="values-intro max-w-none md:max-w-xl">
          <h1
            className="mb-5 font-semibold md:text-6xl text-5xl  text-start uppercase"
            style={{ color: "#1E1E1E" }}
          >
            Our values
          </h1>
          <p
            style={{
              color: "#686868",
              fontFamily: "Outfit",
              wordWrap: "break-word",
            }}
            className="text-base font-normal"
          >
            At Gaskon, our values are the foundation of everything we do. We
            believe in integrity, innovation, client-focused solutions, and
            sustainable practices. These principles guide our decisions, shape
            our strategies, and inspire us to deliver exceptional results for
            our clients.
          </p>
          <div>
            <img
              src={WavesImage}
              alt="image"
              className="waveImage hidden md:block"
            />
          </div>
        </div>
        <div className="values-list  max-w-none md:max-w-xl mt-14 md:mt-5">
          <div className="values-item">
            <div className="circle-container">
              <img src={IntegrityIcon} alt="Icon" className="icon" style={{width:"96px"}} />
            </div>
            <div className="values- w-700">
              <p className="values-description">
                <span style={{ fontWeight: "700" }}>Integrity: </span>We uphold
                the highest standards of honesty and ethics in every aspect of
                our work.
              </p>
            </div>
          </div>
          <div className="values-item">
            <div className="circle-container">
              <img src={Circle} alt="Icon" className="icon" />
            </div>
            <div className="values-content">
              <p className="values-description">
                <span style={{ fontWeight: "700" }}>Innovation: </span>We
                embrace change and constantly seek new ways to provide value.
              </p>
            </div>
          </div>
          <div className="values-item">
            <div className="circle-container">
              <img src={Excellence} alt="Icon" className="icon" />
            </div>
            <div className="values-content">
              <p className="values-description">
                <span style={{ fontWeight: "700" }}>Excellence: </span>We strive
                for the highest quality in everything we do.
              </p>
            </div>
          </div>
          <div className="values-item">
            <div className="circle-container">
              <img src={SustainabilityIcon} alt="Icon" className="icon" />
            </div>
            <div className="values-content">
              <p className="values-description">
                <span style={{ fontWeight: "700" }}>Sustainability: </span>We
                are committed to fostering growth that benefits both our clients
                and the wider community.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-fundRaise">
        <h1 className="font-semibold md:text-5xl text-2xl md:auto px-2 text-center text[#003258] self-middle uppercase py-9">
          {" "}
          Over $500M raised to Fuel Business Growth
        </h1>
      </div>
      <div className="py-11">
        <ScrollLogos />
      </div>
      <Contact_us /> 

      <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />

    </div>
  );
}
