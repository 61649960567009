import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, X, Plus, EllipsisVertical } from 'lucide-react';
import { files, fileTypes } from './AllFilesSection';
import newChatIcon from '../../../../assets/images/document/newChat.svg'
import newChatBlackIcon from '../../../../assets/images/document/new_chat.svg'
import FullScreenModal from './FullScreenModal';

const AskAIModal = ({ isOpen, onClose }: any) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('All');
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState<boolean>(false);

    const itemsPerPage = 5;
    const totalPages = Math.ceil(files.length / itemsPerPage);
    const tabs = ['All', 'Documents', 'Spreadsheet', 'PPT', 'PDF', 'Images'];

    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    const paginatedFiles: any = files.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


    if (!isOpen) return null;

    const toggleFileSelection = (file: any) => {
        setSelectedFiles((prev: any) =>
            prev.includes(file) ? prev.filter((f: any) => f !== file) : [...prev, file]
        );
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (selectAll) {
            setSelectedFiles([])
        } else {
            setSelectedFiles(files)
        }
    };

    const handleFileSelect = (file: any) => {
        setSelectedFiles([...selectedFiles, file]);
    };

    const handleContinue = () => {
        setIsFullScreenModalOpen(true);
    };


    // AI chat input Handler
    const handleAISubmit = (query: any) => {
        console.log('Submitted query:', query);
    };

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center p-4 z-10">
                <div className="bg-white rounded-lg w-full max-w-6xl">
                    <div className="p-6">
                        <div className='flex flex-wrap md:flex-nowrap justify-between'>
                            <div className="flex flex-col gap-2 mb-6">
                                <h2 className="text-2xl font-bold flex items-center gap-2">
                                    <img src={newChatBlackIcon} alt='newChatBlackIcon' className='w-7 h-7' />
                                    <span className="mr-2">Ask AI</span>
                                </h2>
                                <p className="text-sm text-gray-500">Select files to start a new session & analysis files</p>
                            </div>
                            <X className="cursor-pointer bg-[#E1E5E43D] p-1 rounded-full" onClick={onClose} />
                        </div>

                        <div className="flex justify-between items-center my-4">
                            <div className="mb-4 flex space-x-2 bg-[#F8F9F8] rounded-md p-1 w-fit -ms-1">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab}
                                        className={`px-4 py-2 rounded-md transition-colors duration-200 ${activeTab === tab
                                            ? 'bg-white text-[#1E1E1E]'
                                            : 'bg-transparent text-[#989898]'
                                            }`}
                                        onClick={() => setActiveTab(tab)}
                                    >
                                        {tab}
                                    </button>
                                ))}
                            </div>

                            <div className="flex justify-between items-center mb-4 gap-2">
                                <button className="flex items-center px-4 py-2 bg-gray-100 rounded-md text-sm font-medium">
                                    <Plus size={16} className="mr-2" /> New Upload
                                </button>
                                <div className="flex items-center space-x-2">
                                    <button
                                        className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                    >
                                        <ChevronLeft size={20} />
                                    </button>
                                    <span className=" font-medium text-lg">{currentPage}</span>
                                    <span className=" text-gray-500 font-medium text-lg">{'/' + totalPages}</span>
                                    <button
                                        className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        <ChevronRight size={20} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="md:h-[550px] h-fit overflow-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="text-left text-md bg-[#E1E5E43D] text-[#989898] p-2 border-none">
                                        <th className="py-2 font-normal">
                                            <input
                                                type="checkbox"
                                                className="mr-2"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                            File name
                                        </th>
                                        <th className="py-2 font-normal">Uploaded by</th>
                                        <th className="py-2 font-normal">Last Modified</th>
                                        <th className="py-2 font-normal"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedFiles.map((file: any, index: number) => {
                                        const FileIcon = fileTypes[file.type].icon;
                                        const isSelected = selectedFiles.includes(file);

                                        return (
                                            <tr key={index} className="border-t border-gray-200">
                                                <td className="py-4 flex items-center space-x-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={isSelected}
                                                        onChange={() => toggleFileSelection(file)}
                                                    />
                                                    <div className={`p-2 rounded-md`}>
                                                        <img src={FileIcon} alt={'file-' + index} className='w-12 h-12' />
                                                    </div>
                                                    <div>
                                                        <p className="font-medium">{file.name}</p>
                                                        <p className="text-sm text-gray-500">{file.size} • {file.type.toUpperCase()}</p>
                                                    </div>
                                                </td>
                                                <td className="py-4">
                                                    <p>{file.uploadedBy}</p>
                                                    <p className="text-sm text-gray-500">{file.email}</p>
                                                </td>
                                                <td className="py-4 text-gray-500">{file.lastModified}</td>
                                                <td className="py-4 text-right flex items-center justify-end">
                                                    <button className="px-2 py-1 text-gray-500">
                                                        <img src={newChatIcon} alt='newChatIcon' className='w-7 h-7' />
                                                    </button>
                                                    <button className="px-2 py-1 text-gray-500">
                                                        <EllipsisVertical />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="bg-gray-100 px-6 py-4 flex justify-between items-center">
                        <p className="text-sm font-medium">{selectedFiles.length} Selected</p>
                        <div>
                            <button className="px-4 py-2 text-gray-600 font-medium mr-2" onClick={onClose}>Cancel</button>
                            <button className="px-4 py-2 bg-black text-white font-medium rounded-md" onClick={handleContinue}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Full-Screen Modal */}
            <FullScreenModal
                isOpen={isFullScreenModalOpen}
                onClose={() => setIsFullScreenModalOpen(false)}
                selectedFiles={selectedFiles}
                onFileSelect={handleFileSelect}
                handleAISubmit={handleAISubmit}
            />
        </>

    );
};

export default AskAIModal;