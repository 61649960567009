import React from 'react';
import bg from '../../../../assets/images/gaskon/networkBg.svg'

const NetworkingOpportunity = () => {
    return (
        <div className='bg-white md:py-[100px] p-5'>
            <div className="relative w-full bg-gradient-to-r from-[#0372C6] via-[#0372C6] to-[#0AB4D2] overflow-hidden 
            md:w-[80%] md:mx-auto py-[50px] rounded-3xl"
            >
                <div className='bg-no-repeat bg-cover bg-center rounded-3xl p-5 max-w-5xl mx-auto'
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    {/* Content Container */}
                    <div className="relative space-y-4 md:min-h-[350px] flex flex-col justify-center text-center">
                        <h1 className="text-3xl md:text-5xl font-bold text-white leading-tight">
                            See Opportunities Without<br />
                            the Hassle of Networking
                        </h1>
                        <p className="text-sm md:text-lg text-white/90 max-w-2xl mx-auto">
                            Skip the traditional networking roadblocks and access a global pool of
                            investors instantly with Gaskon's automated investor matching
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetworkingOpportunity