import React from 'react';
import './Pagination.scss';

const Pagination = ({ companiesPerPage, totalCompanies, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCompanies / companiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      <li className="page-item">
        <button className="page-link" onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)} aria-label="Previous">
          &lang;
        </button>
      </li>
      <li className="page-item"><span className="page-info">{currentPage} / {pageNumbers.length}</span></li>
      <li className="page-item">
        <button className="page-link" onClick={() => paginate(currentPage < pageNumbers.length ? currentPage + 1 : currentPage)} aria-label="Next">
          &rang;
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
