import React from 'react'
import AdminLoginBg from '../../assets/images/admin/loginBg.svg';
import './admin_login.scss';

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/icons/logo.svg';
import Cookies from 'js-cookie';
import axios from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Eye, EyeOff } from 'lucide-react';
import { toast } from 'react-toastify';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [invalidField, setInvalidField] = useState('');
  const [invalidError, setInvalidError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validateFields = () => {
    if (!/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(userName)) {
      setInvalidField('credserror');
      return false;
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)) {
      setInvalidField('credserror');
      return false;
    }
    return true;
  }

  useEffect(() => {
    localStorage.removeItem("isAuthenticated");
    let authToken = Cookies.get('authToken');
    if (authToken && authToken !== '') {
      navigate("/admin/investor-request");
    }
  }, []);

  const handleLogin = () => {
    // if (!validateFields()) return;
    // if(userName === 'admin.lussolabs@gmail.com' && password === 'AZaz09$$') {
    //   navigate("/dev/dashboard");
    // }
    setIsLoading(true);
    let apiRequest = {
      email: userName,
      password: password,
    };
    axios.post('https://api.gaskon.io/api/v1/login', apiRequest)
      .then(response => {
        // console.log('response', response);
        let authToken = response.data?.token;
        Cookies.set('authToken', authToken, { expires: 7 }); // Expires in 7 days
        // if (response.data.isNewUser) {
        //   navigate('/auth/verify');
        // } else {
        let token = Cookies.get('authToken');
        // console.log('$$$$$$$$$$$$$$$$$tokenin', token);
        if (token) {
          const payload = jwtDecode<JwtPayload>(token);
          navigate('/admin/investor-request');
        }else{
          toast.warning('Login failed', {theme: 'dark'})
        }
      })
      .catch(error => {
        // console.log('error', error);
        toast.warning('Login failed', {theme: 'dark'})

        setIsLoading(false);
        setInvalidField('credserror');
      });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission
    handleLogin();
  };

  return (
    // <div className="admin-login-container">

    //     <div className="admin-image-ctnr-admin">
    //     <div style={{left: 0, top: 0, position: 'relative'}} />
    //         <div style={{background: '#07245C', position: 'absolute', left: 0, top: 0, height: '100%', width: '100%'}}/>
    //         <img src={AdminLoginBg} alt="Background" className="admin-background-im-adm" />
    //         <div style={{left: 40, bottom: 100, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex'}}>
    //                     <div style={{alignSelf: 'stretch', height: '20px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '18px', display: 'flex'}}>
    //                         <div style={{textAlign: 'center', color: 'white', fontSize: '32px', fontFamily: 'Outfit', fontWeight: '600', textTransform: 'uppercase', lineHeight: '32px', wordWrap: 'break-word'}}>Admin Portal</div>
    //                         <hr color='white' style={{width: '100%'}}/>
    //                     </div>
    //         </div>
    //     </div>
    //   <form className="admin-login-content" onSubmit={handleSubmit}>
    //     <div className='elevate-text' >Hello there👋🏼</div>
    //     <div className="admin-sign-in-text">Sign in to your account</div>
    //     <div className="admin-login-form">
    //       <div className="admin-input-container">
    //         <label>Email Id</label>
    //         <input 
    //           type="text" 
    //           value={userName} 
    //           onChange={(e) => setUserName(e.target.value)} 
    //         />
    //       </div>
    //       <div className="admin-divider"></div>
    //       <div className="admin-input-container">
    //         <label>Password</label>
    //         <input 
    //           type="password" 
    //           value={password} 
    //           onChange={(e) => setPassword(e.target.value)} 
    //         />
    //       </div>

    //     </div>
    //     <div className="admin-forgot-password-container">
    //         <div className="admin-forgot-password">Forgot password?</div>
    //     </div>
    //     <button className="admin-sign-in-button">Sign In</button>
    //     {/* <div className="admin-or-text-container">
    //       <div className="admin-or-text">or</div>
    //     </div>
    //     <Link to="/signup"><div className="admin-new-user-text">New User? <a href="/signup" className="admin-new-user-text">Sign Up</a></div></Link> */}
    //     {/* <div className="admin-footer">
    //       <a href="/privacy-policy">Privacy Policy</a> |
    //       <a href="/faq">FAQ</a> |
    //       <a href="/terms-of-use">Terms of Use</a>
    //     </div> */}
    //   </form>
    // </div>

    <div className="flex flex-col h-screen bg-white lg:flex-row">
      {/* Top banner for mobile / Left side for desktop */}
      <div 
        className="bg-[#07245C] bg-cover bg-no-repeat bg-center px-8 py-12 md:p-12 h-64 lg:h-full lg:w-1/2 flex flex-col justify-between relative"
        style={{ backgroundImage: `url(${AdminLoginBg})` }}
      >
        <div className="text-[#4A90E2] text-2xl font-bold">
          <img src={Logo} alt='Logo' />
        </div>
        <div className="lg:block">
          <div className="flex items-center">
            <h2 className="text-white text-3xl font-semibold uppercase mr-4">Admin Portal</h2>
            <div className="flex-grow h-[1px] bg-[#313F64]"></div>
          </div>
        </div>
      </div>

      {/* Login form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-6 py-8 lg:px-16">
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold mb-2">HELLO THERE 👋🏼</h2>
          <p className="text-gray-600 mb-8">Sign in to your account</p>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="userName" className="block text-sm font-medium text-gray-700 mb-1">Email Id</label>
              <input
                type="text"
                id="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="User Name"
              />
            </div>

            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="••••••••••"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-8 text-gray-400"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>

            <div className="text-right">
              <a href="#" className="text-sm text-gray-600 hover:underline">FORGOT PASSWORD?</a>
            </div>

            <button type="submit" className="w-full bg-black text-white py-3 px-4 rounded-md hover:bg-gray-800 transition duration-300">
              Sign In
            </button>
          </form>

          <p className="mt-8 text-center text-sm text-gray-600">
            Don't have an account? <Link to="/signup" className="text-blue-600 hover:underline">Request Access</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;