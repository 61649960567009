import React, { useState } from 'react';
import investorLogo from '../../../../assets/images/century/investorLogo.svg'

const InvestorFeedbackSlide = () => {
  const feedbackData = [
    {
      logo: investorLogo,
      text: 'Century 21 Realty consistently delivers exceptional returns on my investments. Their strategic approach to property development and commitment to sustainability have resulted in impressive growth and profitability. I am highly satisfied with my partnership and look forward to future opportunities.'
    },
    {
      logo: investorLogo,
      text: 'Greenfield Capital has been instrumental in helping me diversify my investment portfolio. Their market insights and deep understanding of real estate trends have allowed me to maximize returns while minimizing risks.'
    },
    {
      logo: investorLogo,
      text: `Partnering with Oakwood Real Estate has transformed my investment strategy. Their team's professionalism and dedication to sustainable growth have exceeded my expectations. I look forward to a long and successful collaboration.`
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % feedbackData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? feedbackData.length - 1 : prev - 1));
  };

  return (
    <div className='relative pb-[50px]'>
      <div className="md:max-w-[80%] mx-auto relative">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 p-5 md:pt-[50px] md:pb-0 md:px-0">
          <h1 className="text-3xl font-bold text-[#414141]">Investor Feedback</h1>
          <div className="flex justify-center items-center mt-4 space-x-4">
            <button
              onClick={prevSlide}
              className={`w-10 h-10 flex items-center justify-center rounded-full border-2 ${currentSlide === 0 ? 'border-gray-600' : 'border-gray-400'}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>

            <div className="flex items-center space-x-1">
              {feedbackData.map((_, index) => (
                <div
                  key={index}
                  className={`h-1 w-10 ${index === currentSlide ? 'bg-gray-800' : 'bg-gray-300'}`}
                ></div>
              ))}
            </div>

            <button
              onClick={nextSlide}
              className={`w-10 h-10 flex items-center justify-center rounded-full border-2 ${currentSlide === feedbackData.length - 1 ? 'border-yellow-500' : 'border-gray-400'}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
        <div className='bg-white rounded-2xl p-5'>
          <div className="md:max-w-[75%] mx-auto">
            <div className="flex flex-col gap-2 md:flex-row items-center md:justify-evenly">
              <div className="flex flex-col gap-2 md:flex-row items-center w-full md:flex-0">
                <div className="w-[300px] md:mr-6 flex justify-center">
                  <img
                    src={feedbackData[currentSlide].logo}
                    alt="Company Logo"
                    className="w-full h-full object-contain"
                  />
                </div>
                <p className="text-[#2A2B38CC] leading-relaxed text-lg flex-1">
                  {feedbackData[currentSlide].text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InvestorFeedbackSlide;