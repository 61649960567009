import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, ArrowDown, ArrowUp, Mail, Phone, Eye, Check, X, MoreHorizontal, MoreVertical, Trash, ChevronRightIcon } from 'lucide-react';
import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment'
import StatusDropdown from './StatusDropdown';
import arrowRight from '../../assets/images/admin/arrowRight.png'
import FilterByDropdown from '../../components/common/FilterByDropdown';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const BusinessListing = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All');
  const itemsPerPage = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const [openDropdownOptionIndex, setOpenDropdownOptionIndex] = useState<number | null>(null);


  const [requests, setRequests] = useState<any>([]);
  const [sortConfig, setSortConfig] = useState({ key: 'business_name', direction: 'ascending' });
  const navigate = useNavigate()

  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const sortedRequests = useMemo(() => {
    let sortableRequests = [...requests];
    if (sortConfig.key) {
      sortableRequests.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRequests;
  }, [requests, sortConfig]);

  const SortIcon = ({ columnName }: any) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? (
        <ArrowUp className="w-4 h-4 inline-block ml-1" />
      ) : (
        <ArrowDown className="w-4 h-4 inline-block ml-1" />
      );
    }
    return null;
  };


  useEffect(() => {
    getApprovalRequests();
  }, [currentPage, activeTab]);

  const getApprovalRequests = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("authToken");
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}businessListings?page=${currentPage-1}&size=${itemsPerPage}`, {
        headers: { "Authorization": `Bearer ${token}` },
      });
      const requests = response.data.listings || [];

      setRequests(requests);
      setTotalRequestCount(response.data.recordsFiltered);
      // Setting counts can be eliminated
      setLoading(false);
    } catch (err) {
      console.error(err);
      Swal.fire('Error!', 'Failed to fetch requests.', 'error');
      setLoading(false);
    }
  };

  return (
    <div className="md:p-6">
      <div className='flex flex-col md:flex-row justify-center md:justify-between items-center mb-4'>
        <h1 className="text-3xl font-bold mb-6">Business Listings</h1>

        <div className='flex-1 flex justify-end'>
          <FilterByDropdown />
          {/* Pagging */}
          <div className="flex items-center space-x-2">
            <button
              className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <span className="font-medium text-lg">{currentPage}</span>
            <span className="text-gray-500 font-medium text-lg"> / {totalPages}</span>
            <button
              className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>

      </div>

      {/* Table */}
      <div className="rounded-lg overflow-x-auto scrollbar-none">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-transparent">
            <tr>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort('businessName')}
              >
                Business Name <SortIcon columnName="businessName" />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort('portfolioCount')}
              >
                No. of Portfolios <SortIcon columnName="portfolioCount" />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Active
              </th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedRequests.map((row: any, index: number) => (
              <tr key={index} className={`hover:bg-[#E6F1FA] font-bold rounded-lg cursor-pointer`}
                onClick={() => navigate('/admin/business-listings/' + row.clientId)}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <div
                        className={`h-10 w-10 rounded-full flex items-center justify-center text-white bg-blue-400`}
                      >
                        {row.businessName.charAt(0).toUpperCase() || '?'}
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="text-sm capitalize text-gray-900">{row.businessName}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{row.businessType}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{row.portfolioCount}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{moment(row.lastActiveOn).format('DD MMM, YYYY')}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-end flex gap-2 justify-end items-center relative">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => navigate('/admin/business-listings/' + row.clientId)}
                  >
                    <img src={arrowRight} alt='arrow right' className='w-auto h-8' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};


export default BusinessListing;