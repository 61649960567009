import FaceBookIcon from "../../assets/images/icons/FaceBookIcon.svg";
import XIcon from "../../assets/images/icons/XIcon.svg";
import InstagramIcon from "../../assets/images/icons/InstagramIcon.svg";
import LinkedInIcon from "../../assets/images/icons/LinkedInIcon.svg";
import FooterAnimation from "../FooterAnimation";
import Logo from "../../assets/images/icons/logo.svg";
import "../Footer.scss";

import footerbg from '../../assets/images/nurwood/footerBg.svg'
const FooterCommon = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${footerbg})`,
                backgroundSize: 'cover'
            }}>
            <div className="p-5 py-10 md:pt-[150px] flex flex-col md:flex-row md:justify-between h-full md:py-[100px] md:max-w-[90%] mx-auto">
                <div className="flex flex-col justify-between order-2 md:order-1">
                    <div className="flex flex-col">
                        <div className="w-28">
                            <img src={Logo} className="logo" alt="Logo" />
                        </div>
                        <div className="elevate-your">
                            <h1 className="font-test-founders font-semibold text-6xl">Elevate</h1>
                            <div className="font-test-founders font-semibold text-3xl md:text-6xl mb-4 flex flex-row gap-4">
                                <h1>Your</h1>
                                <FooterAnimation />
                            </div>
                        </div>
                    </div>
                    <div className="text-[#9B9B9C] text-base font-light font-outfit break-words mt-20">
                        All Rights Reserved
                    </div>
                </div>
                <div className="md:flex gap-5 order-1 md:order-2">
                    <div className="flex flex-col">
                        <div className="flex flex-row">
                            <div className="menu">
                                <h4 className="font-semibold text-base">Menu:</h4>
                                <ul className="text-base font-light font-outfit flex flex-col gap-4 mt-4">
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">About Us</li>
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">Case Study</li>
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">Resource</li>
                                </ul>
                            </div>
                            <div className="w-14"></div>
                            <div className="license">
                                <h4 className="font-semibold text-base">License:</h4>
                                <ul className="text-base font-light font-outfit flex flex-col gap-4 mt-4">
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">Privacy Policy</li>
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">Copyright</li>
                                    <li className="text-[#9B9B9C] cursor-pointer hover:text-black">Email Address</li>
                                </ul>
                            </div>
                        </div>
                        <div className="h-12"></div>
                        <div className="flex flex-row gap-9">
                            <a href="#" className="cursor-pointer bg-white rounded-full p-2 flex justify-center w-8 h-8">
                                <img src={FaceBookIcon} alt="Facebook" />
                            </a>
                            <a href="#" className="cursor-pointer bg-white rounded-full p-2 flex justify-center w-8 h-8">
                                <img src={XIcon} alt="X" />
                            </a>
                            <a href="#" className="cursor-pointer bg-white rounded-full p-2 flex justify-center w-8 h-8">
                                <img src={LinkedInIcon} alt="LinkedIn" />
                            </a>
                            <a href="#" className="cursor-pointer bg-white rounded-full p-2 flex justify-center w-8 h-8">
                                <img src={InstagramIcon} alt="Instagram" />
                            </a>
                        </div>
                    </div>
                    <div className="w-14"></div>
                    <div className="contact-info mt-10 md:mt-0">
                        <ul className="text-base font-light font-outfit flex flex-col gap-4">
                            <h4 className="font-semibold">Mobile</h4>
                            <li className="text-[#9B9B9C] cursor-pointer hover:text-black">817-880-1304</li>
                            <h4 className="font-semibold mt-5">Email</h4>
                            <li className="text-[#9B9B9C] cursor-pointer hover:text-black">info@gaskon.io</li>
                            <h4 className="font-semibold mt-5">Address</h4>
                            <li className="text-[#9B9B9C] text-wrap">1203 S White Chapel Blvd #100, Southlake, Texas,76092</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterCommon