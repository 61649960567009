import CircleImage from '../../../../assets/images/circle-images.png';
import BlueTick from '../../../../assets/images/blueTick.png';
import RightArrow from '../../../../assets/images/arrow-right-black.svg';

const LegalComplianceSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center">
      <div className="bg-[#111315] text-white p-6 md:p-10 md:pt-16 rounded-3xl md:flex">
        <div className="mb-10 md:mb-0 md:w-[40%] relative">
          <h2 className="section-title-legal text-4xl font-bold">Legal & Compliance</h2>
          <div className="md:absolute top-[30%] -left-[40px]">
            <img src={CircleImage} alt="" />
          </div>
        </div>
        <div className="legal-compliance-details md:w-[60%] md:px-10">
          <div className="intellectual-property mb-10">
            <h3 className="details-title text-2xl font-bold flex items-center">
              <div className="slanted-square bg-blue-500 mr-4"></div>Intellectual property
            </h3>
            <ul className="details-list">
              <li className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-4 w-1/2">
                  <img src={BlueTick} alt="" />
                  <span>Patents: Pending for solar panel technology and energy storage systems.</span>
                </div>
                <div className="flex items-center gap-4 w-1/2">
                  <img src={BlueTick} alt="" />
                  <span>Copyrights: Protected for all original content and designs.</span>
                </div>
              </li>
              <li className="flex items-center gap-4 w-1/2">
                <img src={BlueTick} alt="" />
                <span>Trademarks: Registered for brand names and logos.</span>
              </li>
            </ul>
          </div>
          <div className="regulatory-compliance">
            <h3 className="details-title text-2xl font-bold flex items-center">
              <div className="slanted-square bg-blue-500 mr-4"></div>Regulatory Compliance
            </h3>
            <ul className="details-list">
              <li className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-4 w-1/2">
                  <img src={BlueTick} alt="" />
                  <span>Environmental Standards: Fully compliant with all relevant regulations.</span>
                </div>
                <div className="flex items-center gap-4 w-1/2">
                  <img src={BlueTick} alt="" />
                  <span>Safety Certifications: Products meet industry safety requirements.</span>
                </div>
              </li>
              <li className="flex items-center gap-4 w-1/2">
                <img src={BlueTick} alt="" />
                <span>Industry Standards: Adhere to ISO quality and sustainability guidelines.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default LegalComplianceSection