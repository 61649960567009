import React, { useState, useEffect, useRef } from 'react';

const StatusDropdown = ({ data, initialStatus, index, isOpenIndex, setIsOpenIndex, handleStatusChange }: any) => {
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus])

  const dropdownRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const statusOptions = [
    { label: 'Approve', textColor: 'text-green-800' },
    { label: 'Decline', textColor: 'text-red-800' },
  ];

  const getStatusIcon = (status: string) => {
    if (status === "Approved" || status === "Approve") {
      return (
        <span className="text-green-600">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </span>
      );
    }
    if (status === "Pending" || status === "Awaiting Approval") {
      return (
        <span className="text-blue-600">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zm-1 8a1 1 0 112 0v3a1 1 0 11-2 0V10zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
        </span>
      );
    }
    if (status === "Failed" || status === "Rejected" || status === "Decline") {
      return (
        <span className="text-red-600">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3-11a1 1 0 10-1.414-1.414L10 8.586 8.414 7a1 1 0 10-1.414 1.414L8.586 10l-1.586 1.586a1 1 0 001.414 1.414L10 11.414l1.586 1.586a1 1 0 001.414-1.414L11.414 10l1.586-1.586a1 1 0 000-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenIndex(isOpenIndex === index ? -1 : index);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [setIsOpenIndex]);

  // const toggleDropdown = () => {
  //   setIsOpenIndex(isOpenIndex === index ? -1 : index);
  // };
  const toggleDropdown = (e: any) => {
    if (dropdownRef.current.style.display === "block") {
      dropdownRef.current.style.display = "none";
    } else {
      dropdownRef.current.style.display = "block";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        dropdownRef.current.style.display = "none";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // render
  return (
    <div className="relative inline-block text-left w-full md:w-fit">
      <div className="flex items-center">
        <button
          onClick={toggleDropdown}
          ref={buttonRef}
          className={`cursor-pointer px-3 py-2 rounded-lg md:rounded-full text-sm font-semibold flex justify-between items-center space-x-2 w-full md:w-fit
            ${status === "Approved" ? "bg-green-100 text-green-800" :
              status === "Pending" || status === "Awaiting Approval" ? "bg-blue-100 text-blue-800" :
                "bg-red-100 text-red-800"
            }`}
        >
          <div className='flex gap-2 flex-1 items-center'>
            {getStatusIcon(status)}
            <span>{status}</span>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {/* Dropdown menu */}
      <div
        ref={dropdownRef}
        className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
        style={{ display: 'none' }} // Initially hidden
      >
        <div className="py-1 font-normal">
          {statusOptions.map((option) => (
            <button
              type='button'
              key={option.label}
              onClick={(e) => {
                e.stopPropagation();
                handleStatusChange(data.requestId, option.label, false);
                dropdownRef.current.style.display = "none"; // Hide after selecting
              }}
              className={`block px-4 py-2 text-sm w-full text-left ${option.textColor} hover:bg-gray-100`}
            >
              <span className="flex items-center space-x-2">
                {getStatusIcon(option.label)}
                <span>{option.label}</span>
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusDropdown;

