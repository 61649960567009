import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './DashboardLayout.scss';
import CreatePortfolioPopup from './CreatePortfolioPopup';
import Logo from '../../../assets/images/icons/logo.svg';
import Sidebar from '../../../components/common/Sidebar';
import Header from './Header';
import Overview from './Overview';
import FinancialOverview from './FinancialOverview';
import RecentActivities from './RecentActivities';
import Portfolios from './Portfolios';
import RecentDocument from './RecentDocument';
import TasksAndDeadlines from './TasksAndDeadlines';
import LineChart from './LineChart';
import Graph from '../../../assets/images/icons/graph.svg';
import TeamIcon from '../../../assets/images/icons/TeamIcon.svg';
import TasksIcon from '../../../assets/images/icons/TasksIcon.svg';
import DocumentIcon from '../../../assets/images/icons/DocumentIcon.svg';
import QuickStats from '../../../assets/images/icons/quick-stats.svg';
import ActivePortfolios from '../../../assets/images/icons/active-portfolio.svg';
import DollarIcon from '../../../assets/images/icons/dollar.svg';
import SettingsIcon from '../../../assets/images/icons/SettingsIcon.svg';
import SupportIcon from '../../../assets/images/icons/SupportIcon.svg';
import MailIcon from '../../../assets/images/mailIcon.svg'
import Cookies from 'js-cookie';
import { Outlet, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import DocumentDashboard from './DocumentDashboard';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const menuList = [
  {
    title: 'Overview',
    src: Graph,
    path: '/'
  },
  {
    title: 'Portfolio',
    src: ActivePortfolios,
    path: ''
  },
  {
    title: 'Inquiries',
    src: MailIcon,
    path: '/inquiries'
  },
  {
    src: QuickStats,
    title: 'Analytics',
    path: ''
  },
  {
    src: DocumentIcon,
    title: 'Document',
    path: '/document'
  },
  {
    src: TasksIcon,
    title: 'Tasks'
  },
  {
    src: DollarIcon,
    title: 'Financials'
  },
  {
    src: SettingsIcon,
    title: 'Settings'
  },
  {
    src: TeamIcon,
    title: 'Manage Team'
  },
  {
    src: SupportIcon,
    title: 'Support'
  }
]

const DashboardLayout: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const handleCreatePortfolioClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    let authToken = Cookies.get('authToken');
    if (!authToken || authToken === '') {
      navigate("/login");
    } else {
      if (authToken && authToken !== '') {
        const payload = jwtDecode(authToken);
        if (payload && typeof payload.exp === 'number' && payload.exp * 1000 < Date.now()) {
          navigate("/login");
        }
      }
    }
  }, []);

  // const getLayoutContent = () => {
  //   <div className="dashboard-container">
  //     <div className="sidebar">
  //       <img src={Logo} alt="Logo" className='logo' />
  //       <div className="sidebar-item"></div>
  //       <div className="sidebar-item"></div>
  //       <div className="sidebar-item"></div>
  //       <div className="sidebar-item"></div>
  //       <div className="sidebar-footer"></div>
  //     </div>
  //     <div className="main-content">
  //       <div className="header">
  //         <div className="header-item"></div>
  //         <div className="header-item"></div>
  //         <div className="create-portfolio" onClick={handleCreatePortfolioClick}>
  //           <div className="create-portfolio-text">Create Portfolio</div>
  //         </div>
  //       </div>
  //       <div className="content">
  //         <div className="content-box"></div>
  //         <div className="content-box"></div>
  //         <div className="sidebar-content">
  //           <div className="sidebar-content-box"></div>
  //           <div className="sidebar-content-box"></div>
  //           <div className="sidebar-content-box"></div>
  //         </div>
  //         <div className="content-box"></div>
  //       </div>
  //     </div>
  //   </div>
  // }

  // const homeContent = () => {
  //   return (
  //     <div>
  //       <Overview handleCreatePortfolioClick={handleCreatePortfolioClick} />
  //       <div style={{ height: "24px" }}></div>
  //       <div className="content-row">
  //         <div style={{
  //           display: "flex", flexDirection: "column", height: "100%",
  //           width: "100%"
  //         }}>
  //           <FinancialOverview />
  //           <div style={{ height: "20px" }}></div>
  //           <Portfolios />
  //           <div style={{ height: "20px" }}></div>
  //           <RecentDocument />
  //         </div>
  //         <div style={{
  //           display: "flex", flexDirection: "column", height: "100%",
  //           width: "100%"
  //         }}>
  //           <RecentActivities />
  //           <div style={{ height: "20px" }}></div>
  //           <TasksAndDeadlines />
  //         </div>


  //       </div>
  //       <div>
  //         {
  //           isPopupVisible && <CreatePortfolioPopup onClose={handleClosePopup} />
  //         }
  //       </div>
  //       <LineChart />
  //     </div>
  //   );
  // };

  // const renderContent = () => {
  //   switch (selectedMenuIndex) {
  //     case 0:
  //       return homeContent();
  //     case 3:
  //       return <DocumentDashboard />;
  //     // Add more cases as needed
  //     default:
  //       return homeContent();
  //   }
  // };

  return (
    <div className={`dashboard-layout ${isSidebarOpen ? 'side-open' : 'side-close'}`}>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        menuList={menuList}
        admin={false}
        setSelectedMenuIndex={setSelectedMenuIndex}
        toggleSidebar={toggleSidebar}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div className="client-dashboard-main-content bg-white">
        <Header />
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default DashboardLayout;
