import React from 'react';

const Loader = ({ isLoading }: { isLoading: boolean }) => {
    if (!isLoading) return null; // Return nothing if not loading

    return (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
            <div className="text-center">
                <h2 className="text-2xl font-bold">Loading...</h2>
            </div>
        </div>
    );
};

export default Loader;
