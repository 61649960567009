import React from 'react';

const investmentData = [
  {
    opportunity: "Series A Equity Round",
    amountRaised: "$1.75M",
    valuation: "$15M",
    terms: "$250K for 2% equity"
  },
  {
    opportunity: "Seed Funding",
    amountRaised: "$750K",
    valuation: "$10M",
    terms: "$150K for 1.5% equity"
  },
  {
    opportunity: "Angel Investment",
    amountRaised: "$300K",
    valuation: "$5M",
    terms: "$100K for 2% equity"
  },
  {
    opportunity: "Pre-Seed Funding",
    amountRaised: "$1.2M",
    valuation: "$20M",
    terms: "$800K Self-Funded, $600K Convertible notes"
  },
  {
    opportunity: "Seed Funding (Expanded)",
    amountRaised: "$1.5M",
    valuation: "$25M",
    terms: "$100K for 1% Equity, $250K for 2.5% Equity, $500K for 5% Equity"
  }
];

export default function InvestmentOpportunityTable() {
  return (
    <div className="bg-[#7D1D95] bg-opacity-30 px-4 pb-4 rounded-3xl shadow-2xl">
      <div className="bg-[#B3B2B2] bg-opacity-40 rounded-3xl p-6 shadow-lg">
        <table className="w-full text-white text-center my-[50px]">
          <thead>
            <tr className="text-center font-medium">
              <th className="pb-4 text-xl">Investment Opportunity</th>
              <th className="pb-4 text-xl">Amount Raised</th>
              <th className="pb-4 text-xl">Valuation</th>
              <th className="pb-4 text-xl">Terms</th>
            </tr>
          </thead>
          <tbody>
            {investmentData.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="py-3 text-xl font-bold">{item.opportunity}</td>
                  <td className="py-3 text-xl font-bold">{item.amountRaised}</td>
                  <td className="py-3 text-xl font-bold">{item.valuation}</td>
                  <td className="py-3 text-xl font-bold">{item.terms}</td>
                </tr>
                {index < investmentData.length - 1 && (
                  <tr>
                    <td colSpan={4} className="py-2">
                      <div className="h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30"></div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}