import moment from 'moment';
import React from 'react'
import { clientPaths } from '../../../hooks/common.utils';

export const MobileCard = ({ row, index, handleToggleStatus, clientRedirect }: any) => (
  <div className="bg-white rounded-lg shadow-md p-4 mb-4">
    <div className="flex justify-between items-center mb-2">
      <div className="bg-[#E6F1FA] text-[#4B4B4B] px-3 py-2 rounded-lg text-md font-medium">
        {row.businessName}
      </div>
      <button
        onClick={() => handleToggleStatus(index, row.clientId, row.active)}
        className={`relative inline-flex h-6 w-11 items-center rounded-full ${row.status ? 'bg-green-500' : 'bg-gray-200'
          }`}
      >
        <span className="sr-only">Toggle Status</span>
        <button
          onClick={() => handleToggleStatus(index, row.clientId, row.active)}
          className={`relative inline-flex h-6 w-11 items-center rounded-full ${row.active ? 'bg-green-600' : 'bg-gray-300'
            }`}
        >
          <span className='sr-only'>Toggle Status</span>
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${row.active ? 'translate-x-6' : 'translate-x-1'
              }`}
          />
        </button>
      </button>
    </div>
    <div className="mb-2">
      <p className="text-md text-[#989898]">Portfolio Link</p>
      <p className={`text-md font-medium text-[#2B3940] ${clientPaths[row.clientId] ? 'cursor-pointer' : 'text-red-400 cursor-not-allowed'}`} onClick={() => clientRedirect(row.clientId)}>{clientPaths[row.clientId] || 'Route Not Found'}</p>
    </div>
    <div>
      <p className="text-md text-[#989898]">Created on</p>
      <p className="text-md font-medium text-[#2B3940]">{row.createdOn ? moment(row.createdOn).format('DD MMM, YYYY') : 'n/a'}</p>
    </div>
  </div>
);