import React, { useState } from 'react';
import { MapPin, Phone, Mail, Calendar } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import contactBG from '../../assets/images/contactUsBg.svg'
import contactUsBgMbl from '../../assets/images/contactUsBgMbl.svg'
import { toast } from 'react-toastify';
import { isValidEmail } from './GetInTouchButton';
import Cookies from 'js-cookie';
import axios from 'axios';
import sendIcon from '../../assets/images/evolveEnergy/sendIcon.svg'


const ContactForm = () => {
    const initialFormData = { name: '', email: '', phone: '+1', message: '', privacy: false };

    const [loading, setLoading] = useState(false);
    const token = Cookies.get('authToken');

    const [formData, setFormData] = useState(initialFormData);

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        privacy: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            privacy: e.target.checked
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            name: '',
            email: '',
            phone: '',
            message: '',
            privacy: ''
        };

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            newErrors.email = 'Invalid email address';
            isValid = false;
        }

        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone is required';
            isValid = false;
        }

        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        }

        if (!formData.privacy) {
            newErrors.privacy = 'You must accept the privacy policy';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            const { name, email, phone, message } = formData;
            setLoading(true);

            if (!name || !email || !phone || !message) {
                toast.error('Please fill in all the required fields.');
                return;
            }

            if (!isValidEmail(email)) {
                toast.error('Please enter a valid email address.');
                return;
            }

            const payload = {
                name,
                email,
                phone,
                message,
                meetingLink: '',
            };

            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}enquiry/${process.env.REACT_APP_CLIENT_GASKON_ID}`,
                payload,
                { headers }
            );

            if (response.status === 200) {
                setFormData(initialFormData);
                toast.success('Form submitted successfully!');
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'An error occurred while submitting the form.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white w-full p-5 pt-[100px] md:p-0">
            <div className="min-h-screen w-full object-cover bg-no-repeat justify-between flex"
                style={{
                    backgroundImage: `url(${contactBG})`,
                    backgroundPosition: 'center',
                    width: '100%'
                }}>
                {/* Left Side - Contact Information */}
                <div className="md:w-[40%] space-y-6 relative">
                </div>

                {/* Right Side - Form */}
                <div className="md:w-[60%] bg-[#F9FCFE] min-h-screen  md:py-[100px]">
                    <form onSubmit={handleSubmit} className="relative z-20 bg-[#F9FCFE] text-start contus md:max-w-[70%] mx-auto md:p-10">
                        <h2 className='uppercase mb-5 text-[#1E1E1E] text-4xl font-bold tracking-tight'>Get in Touch with us</h2>
                        <p className='mb-8 text-[#686868] text-sm'>Fill out the form below, and our team will get back to you shortly to discuss how we can help you make the switch to solar.</p>

                        <div className="mb-4 bg-white rounded-2xl w-full mx-auto pt-5" style={{
                            boxShadow: '0px 24px 54px 0px #18181812'
                        }}>
                            <div className="mb-4 pb-5 px-4 md:px-8 border-b-[1px] border-b-slate-400">
                                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                                    Name*
                                </label>
                                <input
                                    required
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors.name && <span className="text-red-500 text-xs">{errors.name}</span>}
                            </div>

                            <div className="mb-4 pb-5 px-4 md:px-8 border-b-[1px] border-b-slate-400">
                                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                                    Email ID*
                                </label>
                                <input
                                    required
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter Email"
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
                            </div>

                            <div className="mb-4 pb-5 px-4 md:px-8 border-b-[1px] border-b-slate-400">
                                <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
                                    Phone Number*
                                </label>
                                <PhoneInput
                                    country="us"
                                    value={formData.phone}
                                    onChange={(value: any) => {
                                        setFormData({ ...formData, phone: value });
                                    }}
                                    enableSearch
                                    placeholder="Enter phone number"
                                    buttonStyle={{ border: 'none' }}
                                    inputStyle={{ border: 'none' }}
                                    inputClass="appearance-none border-0 rounded w-full py-2 pe-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors.phone && <span className="text-red-500 text-xs">{errors.phone}</span>}
                            </div>

                            <div className="mb-4 px-4 md:px-8">
                                <label htmlFor="message" className="block text-gray-700 font-bold mb-2">
                                    Message*
                                </label>
                                <textarea
                                    required
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows={2}
                                    placeholder="Leave us your message here"
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors.message && <span className="text-red-500 text-xs">{errors.message}</span>}
                            </div>
                        </div>

                        <div className="flex items-center gap-2 mb-4">
                            <input
                                required
                                type="checkbox"
                                id="privacy-policy"
                                name="privacy-policy"
                                checked={formData.privacy}
                                onChange={handleCheckbox}
                                className="form-checkbox h-5 w-5 text-blue-500 cursor-pointer"
                            />
                            <label htmlFor="privacy-policy" className="ml-2 text-[#686868] font-normal cursor-pointer">
                                I agree to Privacy Policy and terms. We promise to keep your information safe and sound.
                            </label>
                            {errors.privacy && <span className="text-red-500 text-xs">{errors.privacy}</span>}
                        </div>

                        <button
                            disabled={loading}
                            type="submit"
                            style={{ border: '1px solid #808B8B33' }}
                            className="w-full bg-white border-2 border-blue-300 text-md md:text-lg gap-2 flex items-center justify-center text-[#0177D1] font-bold p-3 rounded-xl focus:outline-none focus:shadow-outline"
                        >
                            <img src={sendIcon} alt='send' />
                            {loading ? 'Sending...' : 'Send'}
                        </button>

                        <div className="flex items-center my-6">
                            <div className="flex-grow border-t border-gray-400"></div>
                            <span className="flex-shrink mx-4 text-[#676767] font-bold">or</span>
                            <div className="flex-grow border-t border-gray-400"></div>
                        </div>
                        <button
                            type="button"
                            onClick={() => window.open('https://calendly.com/lussolabs/evolve-energy-investment-enquiry', '_blank')}
                            className="bg-[#1E1E1E] flex gap-2 items-center text-md md:text-lg justify-center text-white font-bold p-3 rounded-xl focus:outline-none focus:shadow-outline mt-4 w-full"
                        >
                            <Calendar />
                            Schedule Meeting
                        </button>
                    </form>
                    <div className="flex flex-col gap-2 justify-center md:justify-normal items-center md:items-start h-fit py-[100px] md:py-0 md:min-h-0 space-y-3 
                        md:absolute md:-mt-[12rem] md:-ml-[35rem] text-sm md:text-xl">
                        <img src={contactUsBgMbl} className='z-[10px] md:hidden absolute object-cover bg-no-repeat -mt-[10rem]' />
                        <div className="flex items-center">
                            <Phone className="w-4 h-4 text-[#1E1E1E] mr-2" />
                            <a href="tel:945-279-2074" className="text-[#1E1E1E]  hover:text-gray-800">
                            945-279-2074
                            </a>
                        </div>
                        <div className="flex items-center">
                            <Phone className="w-4 h-4 text-[#1E1E1E] mr-2" />
                            <a href="tel:817-880-1504" className="text-[#1E1E1E]  hover:text-gray-800">
                                817-880-1504
                            </a>
                        </div>
                        <div className="flex items-center">
                            <Mail className="w-4 h-4 text-[#1E1E1E] mr-2" />
                            <a href="mailto:info@gaskon.io" className="text-[#1E1E1E]  hover:text-gray-800">
                                info@gaskon.io
                            </a>
                        </div>
                        <div className="flex items-center">
                            <MapPin className="w-4 h-4 text-[#0177D1] font-semibold mr-2" />
                            <a href="#" className="text-[#0177D1] font-semibold  hover:text-gray-800">
                                Locate us in Google Maps
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;