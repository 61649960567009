import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/icons/Gaskonlogo.svg';
import Burger from '../assets/images/icons/burger.png';
import './NewHeader.scss';
import { useState , useEffect} from 'react';
import { Button, Menu, MenuItem } from '@mantine/core';

const NewTopHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton]: any = useState("Home");

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/home":
        setActiveButton("Home");
        break;
      case "/Access":
        setActiveButton("Access");
        break;
      // case "/team":
      //   setActiveButton("Our Team");
      //   break;
      case "/about":
        setActiveButton("About Us");
        break;
      case "/Contact":
        setActiveButton("Contact");
        break;
      default:
        setActiveButton(""); 
        break;
    }
  }, [location.pathname]); 

  return (
    <div className='header-Section'>
      <header className="top-header-new">
        <div>
          <img src={Logo} className="logoNew cursor-pointer" alt="Gaskon Logo" onClick={() =>   navigate("/home")} />
        </div>
        <nav>
          <ul>
            <li
              className={activeButton === "Home" ? "li-active" : ""}
              onClick={() => {
                setActiveButton("Home");
                navigate("/home");
              }}
            >
              Home
            </li>
            <li
              className={activeButton === "Access" ? "li-active" : ""}
              onClick={() => {
                setActiveButton("Access");
                navigate("/Access");
              }}
            >
              Portfolio
            </li>
            {/* <li
              className={activeButton === "Our Team" ? "li-active" : ""}
              onClick={() => {
                setActiveButton("Our Team");
                navigate("/team");
              }}
            >
              Our Team
            </li> */}
            <li
              className={activeButton === "About Us" ? "li-active" : ""}
              onClick={() => {
                setActiveButton("About Us");
                navigate("/about");
              }}
            >
              About Us
            </li>
            <li
              className={activeButton === "Contact" ? "li-active" : " text-sm font-normal font-outfit"}
              onClick={() => {
                setActiveButton("Contact");
                navigate("/Contact");
              }}
            >
              Contact
            </li>
          </ul>
        </nav>
          <span
            className="text-sm font-normal font-outfit get-startedBtn"
            onClick={() => navigate("/get-started")}
          >
            Get Started
          </span>
          <Menu >
            <Menu.Target>
              <Button className='menu-burger' style={{ backgroundColor: 'transparent', padding: 0, border: 'none' }}>
                 <img src={Burger} alt='memu' />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
              <MenuItem onClick={() => navigate("/Access")}>Portfolio</MenuItem>
              {/* <MenuItem onClick={() => navigate("/team")}>Our Team</MenuItem> */}
              <MenuItem onClick={() => navigate("/about")}>About Us</MenuItem>
              <MenuItem onClick={() => navigate("/Contact")}>Contact</MenuItem>
              <MenuItem onClick={() => navigate("/get-started")}>Get Started</MenuItem>
            </Menu.Dropdown>
          </Menu>
        {/* </div> */}
      </header>
    </div>
  );
}

export default NewTopHeader;
