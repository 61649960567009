import React from 'react';
import { Zap, LayoutGrid, Stethoscope } from 'lucide-react';
import img1 from '../../../../assets/images/gaskon/whatWeDo1.svg'
import img2 from '../../../../assets/images/gaskon/whatWeDo2.svg'
import img3 from '../../../../assets/images/gaskon/whatWeDo3.svg'

const FeatureCard = ({ icon: Icon, title, description }: any) => {
    return (
        <div className="relative flex flex-col gap-4">
            <div className="w-12 h-12 rounded-full border-2 border-blue-500 flex items-center justify-center">
                {Icon}
            </div>
            <div>
                <h3 className="text-lg font-medium text-gray-800 mb-2">{title}</h3>
                <p className="text-gray-600 leading-relaxed">{description}</p>
            </div>
        </div>
    );
};

const WhatWeDoSection = () => {
    const features = [
        {
            icon: <img src={img1} alt='img 1' />,
            title: "AI-Driven Insights for",
            description: "smarter decision-making."
        },
        {
            icon: <img src={img2} alt='img 2' />,
            title: "Customizable Dashboards to",
            description: "track progress and investment performance"
        },
        {
            icon: <img src={img3} alt='img 3' />,
            title: "Investor Matching based on",
            description: "advanced algorithms."
        }
    ];

    return (
        <div className='relative bg-white md:pb-[20px]'>
            <div className=" bg-[#E6F1FA] overflow-hidden py-24 md:h-[45rem] mb-[20px]">

                {/* Content Container */}
                <div className="md:w-[80%] mx-auto px-4 relative" style={{ zIndex: 1 }}>
                    {/* Header Section */}
                    <div className="mb-16 md:mb-24 flex justify-between items-center gap-6">
                        <div className="text-4xl md:text-5xl font-bold mb-6 flex-1 gap-2">
                            <p>What We Do In </p>
                            <p className="text-blue-500 items-center flex w-full gap-2">
                                <span>Gaskon</span>
                                <span className="flex-1 text-blue-500 w-full md:w-64 h-px bg-gradient-to-r from-[#0076D0] to-[#0EFED4]"></span>
                            </p>
                        </div>
                        <div className="flex items-center gap-4 flex-col md:flex-row">
                            <p className="text-gray-600 max-w-xl">
                                Gaskon's platform is an all-in-one solution designed to streamline
                                investment opportunities, data insights, and project management
                            </p>
                        </div>
                    </div>

                    {/* Features Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {features.map((feature, index) => (
                            <FeatureCard
                                key={index}
                                icon={feature.icon}
                                title={feature.title}
                                description={feature.description}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Full Width Background Text */}
            <div
                className="absolute inset-0 w-full h-full md:flex items-end justify-center overflow-hidden bottom-0 hidden"
                style={{ zIndex: 0 }}
            >
                <div className="relative w-full text-center whitespace-nowrap">
                    <span
                        className="text-[20vw] font-bold text-white leading-none select-none"
                        style={{
                            display: 'inline-block',
                            transform: 'translateY(20%)',
                            letterSpacing: '1vw'
                        }}
                    >
                        GASKON
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDoSection;