import React from 'react';
import getStarted from '../../../assets/images/gaskon/getStarted.svg'
import getStartedMbl from '../../../assets/images/getStartedMbl.svg'
import Logo from "../../../assets/images/icons/logo.svg";
import investorIcon from "../../../assets/images/gaskon/get-started-investor.svg";
import businessIcon from "../../../assets/images/gaskon/get-started-building.svg";
import { Link, useNavigate } from 'react-router-dom';
import NewTopHeader from '../../../components/NewHeader';
import FloatingButton from '../../../components/common/FloatingButton';

const GetStarted = () => {
  const navigate = useNavigate();

  return (
    <div className="h-screen bg-white">

      {/* Main Content */}
      <div className="container h-full m-auto p-4 md:py-0 md:px-6">
        {/* Content Container */}
        <div className="relative h-full flex flex-col md:flex-row items-center justify-center md:gap-12">
          {/* Left Content */}
          <div className="order-2 md:order-1 w-full md:w-[55%]">
            <div className='md:max-w-2xl m-auto'>
              {/* Logo */}
              <div className="hidden md:block md:absolute mb-5 md:mb-0 md:top-20 text-start">
                <img
                  src={Logo}
                  alt="Logo"
                  className="cursor-pointer w-32"
                  onClick={() => navigate("/home")}
                />
              </div>

              <h1 className="hidden md:block text-3xl md:text-4xl font-bold text-[#262C31] md:mb-5">
                Welcome to Gaskon
              </h1>
              <p className="text-[#828A91] mb-4 md:mb-8 max-w-xl">
                Whether you’re an investor looking to explore new opportunities or a
                business seeking guidance and growth.
              </p>
              <h1 className="md:hidden text-3xl md:text-4xl font-bold text-[#262C31] mb-5">
                Which describes you?
              </h1>
              {/* Cards */}
              <div className="space-y-4">
                {/* Investor Card */}
                <div className="bg-[#EEF8FF] p-4 rounded-lg hover:bg-blue-100 transition-all cursor-pointer" onClick={() => navigate('/Access')}>
                  <div className="flex gap-4 items-center">
                    <div className="bg-[#0177D1] rounded-lg">
                      <img src={investorIcon} alt='investor' className='object-cover' />
                    </div>
                    <div>
                      <h2 className="text-[#3F464A] font-semibold mb-1">
                        I'm a Investor
                      </h2>
                      <p className="text-[#3F464A] text-sm">
                        Explore curated investment opportunities, track your portfolio, and grow
                        your wealth with our expert guidance
                      </p>
                    </div>
                  </div>
                </div>

                {/* Business Card */}
                <div className="bg-[#08DBD4] p-4 rounded-lg transition-all cursor-pointer" onClick={() => navigate('/signup')}>
                  <div className="flex gap-4 items-center">
                    <div className="bg-[#EEF8FF] rounded-lg">
                      <img src={businessIcon} alt='investor' className='object-cover' />
                    </div>
                    <div>
                      <h2 className="text-[#3F464A] font-semibold mb-1">
                        I'm a Business Seeking Growth
                      </h2>
                      <p className="text-[#3F464A] text-sm">
                        Access expert consultancy and financial support to scale your business
                        and achieve sustainable growth.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Already have account */}
                <p className='md:hidden text-[#262C31] font-medium text-md'>Already having a account? <Link to={'/login'} className='font-semibold'>Sign in</Link></p>
              </div>
            </div>
          </div>

          {/* Right Content - Image */}
          <div className="order-1 md:order-2 w-full md:w-[45%] h-full md:flex items-center">
            <div className="w-full h-[90%] rounded-l-3xl relative overflow-hidden">
              <div
                className="w-full h-full bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${getStarted})`,
                  filter: 'grayscale(1)'
                }}
              />
              <div className="block md:hidden absolute z-10 left-0 bottom-5 text-start">
                <img
                  src={Logo}
                  alt="Logo"
                  className="cursor-pointer w-32"
                  onClick={() => navigate("/home")}
                />
              </div>
              {/* <div
                className="md:hidden block w-full h-full bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${getStartedMbl})`,
                  filter: 'grayscale(1)',
                  backgroundPosition: 'left'
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />
    </div>
  );
};

export default GetStarted;