import React from 'react';
import lussoLogo from '../../../../assets/images/lusso/lusso.svg'
import googleIcon from '../../../../assets/images/lusso/google.svg'
import amazonIcon from '../../../../assets/images/lusso/amazon.svg'
import shopIcon from '../../../../assets/images/lusso/shop.svg'
import fiverrIcon from '../../../../assets/images/lusso/fiverr.svg'

const companies = [
  {
    logo: <img src={googleIcon} alt='google' className='h-10 w-10' />,
    logoColor: 'text-[#4285F4]',
    strength: 'AI expertise, large-scale reach',
    lussoStrength: 'Fully personalized, AI-powered experiences tailored for individual business needs'
  },
  {
    logo: <img src={amazonIcon} alt='google' className='h-10 w-10' />,
    logoColor: 'text-[#FF9900]',
    strength: 'E-commerce dominance, vast resources',
    lussoStrength: 'Niche focus with specialized, scalable solutions for both B2B and B2C'
  },
  {
    logo: <img src={shopIcon} alt='google' className='h-10 w-10' />,
    logoColor: 'text-[#96BF48]',
    strength: 'Strong e-commerce platform, ease of use',
    lussoStrength: 'Integrated marketplace approach with AI-driven automation and advanced customization options'
  },
  {
    logo: <img src={fiverrIcon} alt='google' className='h-10 w-10' />,
    logoColor: 'text-[#96BF48]',
    strength: 'Freelancing platform, diverse services, ideal for short-term, project-based work.',
    lussoStrength: 'AI-powered platform offering unique personalized solutions, designed for businesses seeking long-term growth and efficiency.'
  }
];

const StrengthsComparisonTable = () => {
  return (
    <div className="w-full text-white md:max-w-4xl mx-auto">
      <div className="relative">
        <div className="grid grid-cols-2 gap-4 mb-6 text-center">
          <div className="text-3xl font-bold">Strengths</div>
          <div className="flex justify-center items-center">
            <img src={lussoLogo} alt='lussoLogo' className='h-8' />
          </div>
        </div>

        {companies.map((company, index) => (
          <div key={index} className="grid grid-cols-2 gap-4 p-4 relative">
            <div className="text-sm flex gap-1 items-center">
              <div className={`text-3xl font-bold ${company.logoColor} mr-4`}>{company.logo}</div>
              {company.strength}
            </div>
            <div className="text-sm md:max-w-xl">{company.lussoStrength}
              {index < 2 && (
                <div className="absolute bottom-0 left-0 right-0 h-[1.5px] bg-gradient-to-r from-transparent via-[#444546] to-transparent" />
              )}
            </div>
          </div>
        ))}

        {/* Vertical borders */}
        <div className="absolute top-5 h-[300px] bottom-0 left-2/4 -ml-[35px] w-[1.5px] bg-gradient-to-b from-transparent via-[#444546] to-transparent" />
      </div>
    </div>
  );
};

export default StrengthsComparisonTable;