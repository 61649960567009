import React from 'react'
import infoCardBg from '../../../../assets/images/lusso/infoCardBG.svg'

function SummeryInfoCard({ img, title, desc, isSummery= true }: { img: string, title: string, desc: string | string[], isSummery?: boolean }) {
    return (
        <div
            className="relative p-6 bg-white rounded-3xl min-h-[350px] md:w-[400px]"
            style={{
                // backgroundImage: `url(${img})`, 
                backgroundPosition: 'right top',
                backgroundRepeat: 'no-repeat',
                // border: '2px solid #C4C4C4',
                borderRadius: '25px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                border: !isSummery ? '16px solid #67627C' : '16px solid #5A5767'
            }}
        >
            <div className="flex flex-col gap-4 max-w-[97%] z-10">
                <h3 className="text-3xl font-bold text-black z-10">
                    {title}
                </h3>
                {Array.isArray(desc) ?
                    <ul className="list-disc list-inside">
                        {desc.map((item, index) => (
                            <li key={index} className="text-lg font-medium text-[#686868] mb-2">
                                {item}
                            </li>
                        ))}
                    </ul>
                    :
                    <p className="text-xl font-medium text-[#686868] min-h-[150px] flex items-center">
                        {desc}
                    </p>}
            </div>
            <img src={img} className="absolute right-0 top-0 w-24 h-24" alt="icon" />
        </div>
    );
}


export default SummeryInfoCard