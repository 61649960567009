import React from 'react';
import './Header.scss';
import BellIcon from '../../../assets/images/icons/bell.svg';

const Header: React.FC = () => {
  return (
    <header className="header ct hidden md:flex">
      <div className="search-bar">
        {/* <CiSearch /> */}
        <input type="text" placeholder="🔍Search anything here..." />
      </div>
      <div className="user-info">
        <div className="notification">
          <img src={BellIcon} className="icon" />
          <div className="count">2</div>
        </div>
        <div className="user-details">
          <div className="avatar">Y</div>
          <div className="details">
            <div className="name">Yeshu</div>
            <div className="role">Owner</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
