import React, { useEffect, useState } from 'react';
import { Edit2 } from 'lucide-react';
import nebulaLogo from '../../assets/images/admin/nebula-logo.png'
import Breadcrumb from '../../components/common/Breadcrumb';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import Loader from '../../components/common/Loader';

const BusinessListingDetails = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [businessDetails, setBusinessDetails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    const { clientId } = useParams()

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setBusinessDetails((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsEditing(false);
    };

    const breadcrumbItems = [
        { label: 'Business Listing', path: '/admin/business-listings' },
        { label: 'Business Details', path: '' },
    ];
    const token = Cookies.get("authToken");

    useEffect(() => {
        getDetailsData()
    }, [clientId])

    const getDetailsData = async () => {
        setLoading(true)
        let api = process.env.REACT_APP_BASE_URL + 'businessListings/' + clientId
        const res = await axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })

        if (res?.data) {
            let businessData = res?.data
            if (businessData) {
                setBusinessDetails({
                    name: businessData.businessName || '',
                    type: businessData.businessType || '',
                    location: businessData.businessHQ || '',
                    description: businessData.businessGoals || '',
                    yearsInOperation: businessData.businessExperience || '',
                    employees: businessData.employeeCount || '-',
                    revenue: businessData.annualRevenue || '',
                });
            }

            setLoading(false)

        } else {
            setLoading(false)
        }
    }

    return (
        <div className='md:p-6 relative'>
            <div className='mb-5'>
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex justify-between items-center mb-2 pb-4">
                <div className="flex items-center">
                    {nebulaLogo && <div className="rounded-full h-12 w-12 me-2">
                        <img src={nebulaLogo} alt='logo' className='rounded-full' />
                    </div>}
                    <h1 className="text-xl md:text-3xl font-bold">{businessDetails?.name}</h1>
                </div>
                <button
                    onClick={() => setIsEditing(!isEditing)}
                    className="py-2 px-3 flex items-center text-lg font-semibold text-[#43474E] hover:text-gray-800 bg-white border-[1px] rounded-lg border-[#E8E8E8]"
                >
                    <Edit2 className="w-5 h-5 mr-1" />
                    Edit
                </button>
            </div>

            <div className='bg-white p-6'>
                <h2 className="text-xl font-bold mb-4">Business Details</h2>
                {/* Loader */}
                <Loader isLoading={loading} />

                {/* Form/view */}
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Business name</label>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="name"
                                    value={businessDetails?.name}
                                    onChange={handleInputChange}
                                    className="w-full border rounded-md p-3 h-[50px]"
                                />
                            ) : (
                                <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.name}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Business Type</label>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="type"
                                    value={businessDetails?.type}
                                    onChange={handleInputChange}
                                    className="w-full border rounded-md p-3 h-[50px]"
                                />
                            ) : (
                                <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.type}</p>
                            )}
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Headquarters Location</label>
                        {isEditing ? (
                            <input
                                type="text"
                                name="location"
                                value={businessDetails?.location}
                                onChange={handleInputChange}
                                className="w-full border rounded-md p-3 h-[50px]"
                            />
                        ) : (
                            <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px] md:w-[49%]">{businessDetails?.location}</p>
                        )}
                    </div>

                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Business Description & Goal</label>
                        {isEditing ? (
                            <textarea
                                name="description"
                                value={businessDetails?.description}
                                onChange={handleInputChange}
                                rows={4}
                                className="w-full border rounded-md p-3 h-[50px]"
                            ></textarea>
                        ) : (
                            <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.description}</p>
                        )}
                    </div>

                    <h2 className="text-xl font-bold mb-4">Business Size & History</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Business in Operation (in years)</label>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="yearsInOperation"
                                    value={businessDetails?.yearsInOperation}
                                    onChange={handleInputChange}
                                    className="w-full border rounded-md p-3 h-[50px]"
                                />
                            ) : (
                                <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.yearsInOperation}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">No of employees</label>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="employees"
                                    value={businessDetails?.employees}
                                    onChange={handleInputChange}
                                    className="w-full border rounded-md p-3 h-[50px]"
                                />
                            ) : (
                                <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.employees}</p>
                            )}
                        </div>
                    </div>

                    <h2 className="text-xl font-bold mb-4">Business Revenue</h2>
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Last year (2023)</label>
                        {isEditing ? (
                            <input
                                type="text"
                                name="revenue"
                                value={businessDetails?.revenue}
                                onChange={handleInputChange}
                                className="w-full border rounded-md p-3 h-[50px]"
                            />
                        ) : (
                            <p className="p-3 bg-gray-100 border-[1px] rounded-lg border-[#E8E8E8]rounded-md min-h-[50px]">{businessDetails?.revenue}</p>
                        )}
                    </div>

                    {isEditing && (
                        <div className="mt-6">
                            <button
                                type="submit"
                                className="px-4 py-2 bg-[#1e1e1e] text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </form>
            </div>

        </div>
    );
};

export default BusinessListingDetails