// @ts-nocheck
import React from 'react'
import lussoLogo from '../../../../assets/images/lusso/lusso.svg'
import alertIcon from '../../../../assets/images/lusso/alert.svg'
import tick from '../../../../assets/images/lusso/inditick.svg'
import overlay from '../../../../assets/images/lusso/strategicOverlay.svg'
import { Dot } from 'lucide-react';

function StrategicSolutions() {
    const companies = [
        {
            logoColor: 'text-[#4285F4]',
            strength: 'Market competition from larger players',
            lussoStrength: 'Lusso.ai forms strategic partnerships, leveraging niche expertise to stay agile and competitive.'
        },
        {
            logoColor: 'text-[#FF9900]',
            strength: 'Potential scalability issues',
            lussoStrength: 'Lusso.ai forms strategic partnerships, leveraging niche expertise to stay agile and competitive.'
        },
        {
            logoColor: 'text-[#96BF48]',
            strength: 'Technological challenges in maintaining AI-driven infrastructure',
            lussoStrength: 'Lusso.ai invests in a robust platform infrastructure, ensuring reliability and adaptability to future tech advancements.'
        }
    ];


    return (
        <div className='bg-gradient-to-b from-[#010101] to-[#010101] min-h-screen text-white relative'>
            <div className='text-center md:w-[80%] md:mx-auto pb-[100px]'>
                <div className="relative mb-5">
                    <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                        Navigating Challenges with
                    </p>
                    <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
                        Strategic Solutions
                    </h1>
                    <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />

                </div>
                <p className='text-md text-white md:max-w-5xl md:mx-auto'>In a competitive digital landscape, Lusso.ai faces certain risks, but we proactively address each with a clear strategy</p>
            </div>

            {/* <StrengthsComparisonTable /> */}
            <div className='md:w-[70%] absolute -top-[15%] -right-[20%] scale-150'>
                <img src={overlay} alt='map' />
            </div>
            <div className="relative w-full text-white md:max-w-5xl mx-auto shadow-3xl bg-[#100D16] px-10 py-20 rounded-2xl">
                <div className="relative">
                    <div className="grid text-[#D7D1E7] grid-cols-2 gap-4 mb-6 text-center">
                        <div className="text-xl font-bold uppercase flex flex-col gap-2 items-center">
                            <img src={alertIcon} alt='alert' className='h-8' />
                            potential risks</div>
                        <div className="text-xl font-bold uppercase flex flex-col gap-2 items-center">
                            <img src={tick} alt='alert' className='h-8' />
                            Mitigation Strategies</div>
                    </div>

                    {companies.map((company, index) => (
                        <div key={index} className="grid grid-cols-2 gap-4 py-4 relative">
                            <div className="text-md flex gap-1 items-center md:ps-10">
                                <Dot />
                                {company.strength}
                            </div>
                            <div className="text-md md:max-w-xl">
                                <div className='flex gap-2 items-center md:ps-10'>
                                    <Dot />
                                    {company.lussoStrength}
                                </div>

                                {index < 2 && (
                                    <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-gray-600 to-transparent" />
                                )}
                            </div>
                        </div>
                    ))}

                    {/* Vertical borders */}
                    {/* <div className="absolute top-5 h-[300px] bottom-0 left-2/4 -ml-[35px] w-px bg-gradient-to-b from-transparent via-gray-600 to-transparent" /> */}
                </div>
            </div>
        </div>
    )
}

export default StrategicSolutions