import './App.css';
import ClientLogin from './pages/client/login/ClientLogin';
import ReactQueryProvider from './providers/ReactQueryProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignUp from './pages/client/signup/SignUp';
import EmailVerification from './pages/client/email_verification/EmailVerification';
import DashboardLayout from './pages/client/dashboardlayout/DashboardLayout';
import Access from './pages/investors/Portfolio';
import PortfolioList from './pages/investors/PortfolioList';
import Home from './pages/Landing/Landing';
import LandingLayout from './pages/Landing/LandingLayout';
import About_us from './components/About_us/About_us';
import Contact_us from './components/Contact_Us/Contact_us';
import Our_Team from './components/OurTeam/Our_Team';
import Portfolio from './pages/investors/Portfolio';
import EnergyPortfolio from './pages/investors/Portfolios/EnergyPortfolio';
import PortfolioDetails from './pages/investors/PortfolioDetails';
import RealEstatePortfolio from './pages/investors/Portfolios/Realestate/Portfolio';
import Evolve from './pages/investors/Portfolios/Evolve/evolvehome';
import FixedButton from './components/FixedButton';
import AdminHome from './pages/admin/home_page';
import AdminLogin from './pages/admin/login_page';
import ForgotPassword from './pages/client/forgotpassword/forgotpassword';
import ResetPassword from './pages/client/resetpassword/resetpassword';
import PricingComponent from './pages/client/Pricing/Pricing';
import LussoHome from './pages/investors/Portfolios/Lusso/LussoHome';
import Portfolios from './pages/client/dashboardlayout/Portfolios';
import DocumentDashboard from './pages/client/dashboardlayout/DocumentDashboard';
import HomeContent from './pages/client/dashboardlayout/HomeContent';
import ClientInquiryList from './pages/client/dashboardlayout/ClientInquiryList';
import AdminLayout from './pages/admin/AdminLayout';
import AdminPortfolioManagement from './pages/admin/admin_portfolio_management';
import InvestorRequests from './pages/admin/InvestorRequests';
import BusinessListing from './pages/admin/BusinessListing';
import BusinessListingDetails from './pages/admin/BusinessListingDetails';
import PortfolioManagement from './pages/admin/PortfolioManagement';
import ResetPassByToken from './pages/client/resetpassword/ResetPassByToken';
import NotFound from './components/common/NotFound';
import GaskonHome from './pages/investors/Portfolios/Gaskon/GaskonHome';
import GetStarted from './pages/client/getStarted/GetStarted';
import SignupFlow from './pages/client/getStarted/SignupFlow';

const App = () => {
  return (
    <>
      <ReactQueryProvider>
        <BrowserRouter>
          <Routes>
            <Route path="adminHome" element={<AdminHome />} />

            {/* Auth Routes */}
            <Route path="get-started" element={<GetStarted />} />
            <Route path="login" element={<ClientLogin />} />
            {/* <Route path="signup" element={<SignupFlow />} /> */}
            <Route path="signup" element={<SignUp />} />
            <Route path="verify" element={<EmailVerification />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset" element={<ResetPassword />} />
            <Route path="auth/resetPassword" element={<ResetPassByToken />} />

            {/* Admin Routes */}
            <Route path="admin" element={<AdminLayout />}>
              <Route index element={<AdminLogin />} />
              {/* <Route path="home" element={<InvestorRequests />} /> */}
              <Route path="investor-request" element={<InvestorRequests />} />
              <Route path="business-listings" element={<BusinessListing />} />
              <Route path="business-listings/:clientId" element={<BusinessListingDetails />} />
              <Route path="portfolio" element={<PortfolioManagement />} />
              {/* <Route path="document" element={<DocumentDashboard />} /> */}
              {/* <Route path="inquiries" element={<ClientInquiryList />} /> */}
            </Route>

            <Route path="pricing" element={<PricingComponent />} />
            <Route path="portfolios" element={<PortfolioList />} />
            <Route path="Lusso" element={<LussoHome />} />
            <Route path="NorwoodEnergy" element={<EnergyPortfolio />} />
            <Route path="Century21" element={<RealEstatePortfolio />} />
            <Route path="EvolveEnergy" element={<Evolve />} />
            <Route path="Gaskon" element={<GaskonHome />} />

            {/* Dashboard Routes */}
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route index element={<HomeContent />} />
              <Route path="document" element={<DocumentDashboard />} />
              <Route path="inquiries" element={<ClientInquiryList />} />
              <Route path="portfolios" element={<PortfolioManagement />} />
            </Route>

            <Route path="/" element={<LandingLayout />}>

              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="Access" element={<Portfolio />} />
              <Route path="contact" element={<Contact_us />} />
              <Route path="about" element={<About_us />} />
              <Route path="team" element={<Our_Team />} />
              
              {/* NotFound route */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ReactQueryProvider>
      {/* <FixedButton /> */}
    </>
  );
}

export default App;
