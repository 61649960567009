
import './DocumentDashboard.scss';
import CreateSection from './Documents/CreateSection';
import FolderStructure from './Documents/FolderStructure';
import AllFilesSection from './Documents/AllFilesSection';
import { useState } from 'react';
import AskAIModal from './Documents/AskAIModal';


const DocumentDashboard = () => {
  const [isOpenAsk, setIsOpenAsk] = useState(false)

  const handleToggleAI = () => {
    setIsOpenAsk(!isOpenAsk)
  }

  // render
  return (
    <div className='bg-white h-screen p-5 md:p-10'>
      <div className='flex flex-col gap-10'>
        {/* Create Section */}
        <CreateSection toggleAI={handleToggleAI} isOpenAsk={isOpenAsk}  />

        {/* File Structure */}
        <FolderStructure />

        {/* All Files */}
        <AllFilesSection />
      </div>

      {/* Ask AI Modal */}
      <AskAIModal isOpen={isOpenAsk} onClose={() => setIsOpenAsk(false)} />

    </div>
  )
}

export default DocumentDashboard