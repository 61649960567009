import React from 'react';
import './RecentDocument.scss';
import XlsIcon from '../../../assets/images/icons/XlsIcon.svg';
import DownloadIcon from '../../../assets/images/icons/DownloadIcon.svg';
import ShareIcon from '../../../assets/images/icons/ShareIcon.svg';
import Eye from '../../../assets/images/icons/eye.svg';

const RecentDocument: React.FC = () => {
  return (
    <div className="recent-document">
      <div className="header">
        <h2>Recent Document</h2>
        <a href="#">View all</a>
      </div>
      <div className="document">
        <img src={XlsIcon} className="icon" />
        <div className="info">
          <strong>Financial Forecast.xlsx</strong>
          <span>Uploaded on June 25, 2024</span>
        </div>
        <div className="actions">
          <div className='icon-wrap'><img src={DownloadIcon} className="icon"/></div>
          <div className='icon-wrap'><img src={Eye} className="icon" /></div>
          <div className='icon-wrap'><img src={ShareIcon} className="icon" /></div>
        </div>
      </div>
    </div>
  );
};

export default RecentDocument;
