import React, { useState } from 'react';
import Overview from './Overview';
import FinancialOverview from './FinancialOverview';
import Portfolios from './Portfolios';
import RecentDocument from './RecentDocument';
import RecentActivities from './RecentActivities';
import TasksAndDeadlines from './TasksAndDeadlines';
import LineChart from './LineChart';
import CreatePortfolioPopup from './CreatePortfolioPopup';


const HomeContent = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const handleCreatePortfolioClick = () => {
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div>
            <Overview handleCreatePortfolioClick={handleCreatePortfolioClick} />
            <div style={{ height: '24px' }}></div>
            <div className="content-row">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <FinancialOverview />
                    <div style={{ height: '20px' }}></div>
                    <Portfolios />
                    <div style={{ height: '20px' }}></div>
                    <RecentDocument />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <RecentActivities />
                    <div style={{ height: '20px' }}></div>
                    <TasksAndDeadlines />
                </div>
            </div>
            <div>
                {isPopupVisible && <CreatePortfolioPopup onClose={handleClosePopup} />}
            </div>
            <LineChart />
        </div>
    );
};

export default HomeContent;