import React from 'react';
import bg from '../../../../assets/images/gaskon/securityBg.png'
import lockIcon from '../../../../assets/images/gaskon/lock.svg'

const SecuritySection = () => {
    return (
        <div className='bg-white md:pb-[100px] p-5'>
            <div className="relative w-full md:w-[80%] md:mx-auto md:py-[50px] rounded-3xl"
            >
                <div className='bg-no-repeat bg-cover bg-center rounded-3xl p-5'
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    {/* Content Container */}
                    <div className="relative text-white space-y-4 md:min-h-[350px] flex flex-col gap-2 
                        items-center justify-center text-center">
                        <img src={lockIcon} alt='lock' className='h-16 w-16' />
                        <p className='text-3xl font-bold'>Data Security</p>
                        <p className='text-sm text-slate-100 max-w-md'>
                            Top-tier data security ensures your information is protected at all times,
                            offering peace of mind as you grow your portfolio
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecuritySection