import React from 'react';
import showcase1 from '../../../../assets/images/gaskon/showcase1.svg'
import showcase2 from '../../../../assets/images/gaskon/showcase2.svg'

const DashboardShowcase = () => {
    const sections = [
        {
            title: "Customizable Dashboard",
            description: "Tailor your dashboard with real-time data for instant insights and enhanced decision-making.",
            image: showcase1,
            imageAlt: "Customizable dashboard interface",
        },
        {
            title: "AI-Driven Analytics",
            description: "Our AI tools provide deep insights into market trends and investor preferences, empowering smarter strategies.",
            image: showcase2,
            imageAlt: "AI analytics dashboard",
        }
    ];

    const LaptopDisplay = ({ imageSrc, imageAlt }: { imageSrc: string, imageAlt: string }) => (
        <div className="relative transform scale-110 bg-transparent w-full max-w-md mx-auto lg:mx-0">
            {/* Laptop Frame */}
            <div className="relative w-full aspect-[16/10]">
                {/* Screen Content */}
                <div className="absolute inset-0 rounded-md overflow-hidden z-10">
                    <img
                        src={imageSrc}
                        alt={imageAlt}
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="w-full md:w-[80%] md:mx-auto">
            <div className="text-2xl md:text-3xl font-bold mb-6 gap-2 md:max-w-md text-start">
                <p className='mb-3'>
                    <span className='me-2 text-[#1D1D1D]'>Dashboard, AI, Data, and</span>
                    <span className="bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4]  bg-clip-text text-transparent">
                        Beyond....
                    </span>
                </p>

                <p className="text-gray-600 max-w-xl text-sm font-normal">
                    Gaskon’s platform is an all-in-one solution designed to streamline
                    investment opportunities, data insights, and project management
                </p>
            </div>
            {sections.map((section, index) => (
                <div key={index} className={`relative w-full overflow-hidden py-16 lg:py-24 
                    `}>
                    {/* Text Content */}
                    <div className={`${index % 2 === 0 ? 'md:justify-start pl-[20%]' : 'md:justify-end'} bg-[#EFF6FC] w-full py-12 px-8 mx-auto flex items-center lg:px-16`}>
                        <div className='md:w-7/12'>
                            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                                {section.title}
                            </h2>
                            <p className="text-lg text-gray-600 max-w-xl">
                                {section.description}
                            </p>
                        </div>

                        {/* Laptop Display */}
                        <div className={`absolute ${index % 2 === 0 ? 'right-0' : '-left-12'} z-10`}>
                            <img
                                src={section.image}
                                alt={section.imageAlt}
                                className="w-full h-[300px] -mt-[100px]"
                            />
                            {/* <LaptopDisplay imageSrc={section.image} imageAlt={section.imageAlt} /> */}
                        </div>
                    </div>

                </div>
            ))}
        </div>
    );
};



export default DashboardShowcase;