import { Modal } from "@mantine/core";
import React, { useEffect, useRef } from "react";

const OtpModal = ({
  opened,
  close,
  email,
  phone,
  verifyOtp,
  verifyOtpEmail,
  setOtpPhone,
  setOtpEmail,
  otpEmail,
  otpPhone,
  currentModal,
  setCurrentModal,
  otpError,
}: any) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Trigger verification after entering OTP
  useEffect(() => {
    if (otpPhone.every((digit: any) => digit !== "") && currentModal === 1) {
      verifyOtp();
    } else if (
      otpEmail.every((digit: any) => digit !== "") &&
      currentModal === 2
    ) {
      verifyOtpEmail();
    }
  }, [otpPhone, otpEmail, currentModal]);

  useEffect(() => {
    if (currentModal === 2) {
      inputRefs.current[0]?.focus();
    }
  }, [currentModal]);

  // OTP input handling
  const handleOtpChange = (
    value: string,
    index: number,
    type: "email" | "phone"
  ) => {
    if (type === "phone") {
      const newOtp = [...otpPhone];
      if (/^\d?$/.test(value)) {
        newOtp[index] = value;
        setOtpPhone(newOtp);
        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        }
      }
    } else {
      const newOtp = [...otpEmail];
      if (/^[A-Za-z0-9]?$/.test(value)) {
        newOtp[index] = value.toUpperCase();
        setOtpEmail(newOtp);
        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        }
      }
    }
  };

  // Handle backspace to go to previous input
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
    type: "email" | "phone"
  ) => {
    if (e.key === "Backspace" && e.currentTarget.value === "") {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  return (
    <Modal
    opened={opened}
    onClose={close}
    overlayProps={{ backgroundOpacity: 0.59, blur: 4 }}
    size="lg" 
    lockScroll={true}
    closeOnClickOutside={false}
    withCloseButton={false}
    styles={{
      header: { backgroundColor: "#FFFFFF" },
      body: { backgroundColor: "#FFFFFF", paddingTop: "2rem" },
    }}
    data-centered
  >
    <div className="flex justify-between px-4 md:px-8">
      <h3 className="text-[#1E1E1E] font-inter text-xl md:text-3xl font-semibold uppercase">
        {currentModal === 1 ? "Verify Your phone" : "Verify Your email"}
      </h3>
      <button
        onClick={close}
        className="flex items-center justify-center w-5 h-5 bg-[#686868] rounded-full focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-3 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  
    {/* Body */}
    <div className="flex items-center justify-start bg-[#FFFFFF] p-4">
      <div className="bg-[#FFFFFF] p-4 md:p-6">
        <p className="font-inter font-medium text-[#989898] text-xs md:text-sm mb-4 -mt-4 md:-mt-6">
          {currentModal === 1
            ? `Enter the code sent via text to your phone + ${phone}`
            : `Enter the code sent to your email - ${email}`}
        </p>
  
        <div className="flex justify-start space-x-2 mb-4">
          <span
            className={`w-2 h-2 rounded-full ${
              currentModal === 1 ? "bg-[#0177D1]" : "bg-[#989898]"
            }`}
          ></span>
          <span
            className={`w-2 h-2 rounded-full ${
              currentModal === 2 ? "bg-[#0177D1]" : "bg-[#989898]"
            }`}
          ></span>
        </div>
  
        {/* OTP Inputs */}
        <div className="flex justify-center items-center mt-6 space-x-2 mb-4">
          {(currentModal === 1 ? otpPhone : otpEmail)
            .slice(0, 3)
            .map((data: any, index: any) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                className={`w-10 md:w-16 h-12 md:h-16 bg-white border ${
                  otpError ? "border-red-500" : "border-[#E8E8E8]"
                } text-center text-black text-xl md:text-2xl rounded-lg focus:outline-none focus:ring-2`}
                type="text"
                maxLength={1}
                value={data}
                onChange={(e) =>
                  handleOtpChange(
                    e.target.value,
                    index,
                    currentModal === 1 ? "phone" : "email"
                  )
                }
                onKeyDown={(e) =>
                  handleKeyDown(
                    e,
                    index,
                    currentModal === 1 ? "phone" : "email"
                  )
                }
              />
            ))}
  
          <span className="text-xl md:text-2xl font-semibold text-[#B9B9B9] mx-2">
            -
          </span>
  
          {(currentModal === 1 ? otpPhone : otpEmail)
            .slice(3, 6)
            .map((data: any, index: any) => (
              <input
                key={index + 3}
                ref={(el) => (inputRefs.current[index + 3] = el)}
                className={`w-10 md:w-16 h-12 md:h-16 bg-white border ${
                  otpError ? "border-red-500" : "border-[#E8E8E8]"
                } text-center text-black text-xl md:text-2xl rounded-lg focus:outline-none focus:ring-2`}
                type="text"
                maxLength={1}
                value={data}
                onChange={(e) =>
                  handleOtpChange(
                    e.target.value,
                    index + 3,
                    currentModal === 1 ? "phone" : "email"
                  )
                }
                onKeyDown={(e) =>
                  handleKeyDown(
                    e,
                    index + 3,
                    currentModal === 1 ? "phone" : "email"
                  )
                }
              />
            ))}
        </div>
  
        <p className="text-left text-[#989898] text-xs md:text-sm mt-4">
          {otpError ? "Entered code is incorrect" : "Didn't receive?"} -
          <span className="text-[#00A8E8] mx-1 cursor-pointer">
            {otpError ? "Re-Try Again" : "Resend Code"}
          </span>
        </p>
      </div>
    </div>
  </Modal>
  
  );
};

export default OtpModal;
