import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
  ChartData,
  ScriptableContext,
} from 'chart.js';
import './LineChart.scss';

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const data: ChartData<'line'> = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Active Users',
      data: [700000, 690000, 600000, 650000, 550000, 620000, 630000, 680000, 660000, 690000, 710000, 720000],
      borderColor: '#C4E36A',
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          // This case happens on initial chart load
          return 'rgba(0, 0, 0, 0)';
        }
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(196, 227, 106, 0)');
        gradient.addColorStop(0.38, 'rgba(196, 227, 106, 0.12)');
        gradient.addColorStop(0.45, 'rgba(196, 227, 106, 0.12)');
        gradient.addColorStop(1, 'rgba(196, 227, 106, 0.12)');
        return gradient;
      },
      fill: true,
      tension: 0.2,
    },
  ],
};

const options: ChartOptions<'line'> = {
  responsive: true,
  animation: {
    duration: 2000,
  },
  scales: {
    x: {
      title: {
        display: false,
        text: 'Months',
      },
    },
    y: {
      title: {
        display: false,
        text: 'Active Users',
      },
      position: 'right',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const LineChart: React.FC = () => {
  const chartRef = useRef<ChartJS | null>(null);

  useEffect(() => {
    // Cleanup the chart instance on component unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  return (
    <div className="line-chart">
      <Line
        data={data}
        options={options}
        ref={(chart) => {
          if (chart) {
            chartRef.current = chart;
          }
        }}
      />
    </div>
  );
};

export default LineChart;
