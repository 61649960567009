// @ts-nocheck
import React, { useState } from 'react';
import { ChevronRight, Check, ArrowRightCircle } from 'lucide-react';
import keyperf from '../../../../assets/images/lusso/indicator.gif'
import tick from '../../../../assets/images/lusso/inditick.svg'
import bg from '../../../../assets/images/lusso/keyIndicatorBg.svg'

const slides = [
    {
        number: '01',
        title: 'Customer Acquisition Cost (CAC):',
        current: {
            title: 'CURRENT CAC',
            content: '$150 per subscriber, which is optimized through targeted marketing campaigns and automated onboarding processes.'
        },
        goal: {
            title: 'GOAL',
            content: 'Reduce CAC to $100 per subscriber through increased use of AI-driven marketing tools and data-driven customer targeting strategies.'
        }
    },
    {
        number: '02',
        title: 'Total Lifetime Value(LTV):',
        current: {
            title: 'CURRENT LTV',
            content: 'With an average LTV of $7,000 per subscriber, Lusso.ai is focused on long-term engagement through continuous feature updates and customer retention programs.'
        },
        goal: {
            title: 'GOAL',
            content: 'Increase LTV to $10,000 by enhancing the subscription model, introducing premium features, and fostering long-term customer loyalty.'
        }
    },
    {
        number: '03',
        title: 'Churn Rate (CR):',
        current: {
            title: 'CURRENT CR',
            content: `Lusso.ai's current churn rate is 7% annually.`
        },
        goal: {
            title: 'GOAL',
            content: 'Reduce churn to 5% by improving user experience, offering personalized AI-driven solutions, and implementing retention strategies such as loyalty programs and enhanced customer support.'
        }
    },
    {
        number: '04',
        title: 'Short Term Goal (6-12 months):',
        current: {
            title: 'Revenue Target',
            content: 'Achieve $2.5 million in annual recurring revenue (ARR) through aggressive subscription sales and enterprise partnerships.'
        },
        goal: {
            title: 'Platform Development',
            content: 'Complete the integration of AI-based SEO tools and marketing automation features to enhance user experience and retention.'
        }
    },
    {
        number: '05',
        title: 'Long term Goals(2-3 Years):',
        current: {
            title: 'Revenue Target',
            content: 'Achieve $10 million in ARR by expanding into new verticals such as AI-driven content creation, e-commerce, and enterprise solutions.'
        },
        goal: {
            title: 'Product Expansion and Target valuation',
            content: 'Add advanced AI tools for predictive analytics, enabling users to optimize their digital marketing efforts with personalized insights with a target valuation of $50 Million by leveraging subscription growth and launching premium services.'
        }
    },
    // Add more slides as needed to reach a total of 5
];

const KeyPerformanceIndicators = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const currentSlideData = slides[currentSlide];

    return (
        <div className='bg-black'>
            <div className='bg-cover bg-center bg-no-repeat md:min-h-[60rem] w-full' style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}>
                <div className=" text-white p-8 font-sans md:w-[80%] mx-auto md:pt-[150px] ">
                    <p className='text-5xl font-bold flex items-center -gap-2 uppercase'>
                        Key performance
                        <img src={keyperf} alt='keyperf' className='-mx-8 h-32' />
                        Indicators
                    </p>
                    <div className="md:pt-[150px] flex justify-between items-center mb-8">
                        <h2 className="text-3xl font-bold flex gap-2 items-center">
                            <svg className="w-16 h-16" viewBox="0 0 50 50">
                                <text
                                    x="50%"
                                    y="50%"
                                    dominantBaseline="central"
                                    textAnchor="middle"
                                    fontSize="35"
                                    fontWeight="bold"
                                    fill="transparent"
                                    stroke="#01F0FB"
                                    strokeWidth="1.5"
                                >
                                    {currentSlideData.number}
                                </text>
                            </svg>
                            {currentSlideData.title}
                        </h2>
                        <div className="flex items-center">
                            <span className="text-white mr-2">{`${currentSlide + 1}/${slides.length}`}</span>
                            <button
                                onClick={nextSlide}
                                className="rounded-full transition-colors"
                            >
                                <ArrowRightCircle color='#8043F1' size={32} />
                            </button>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-8 relative">
                        <div className="relative z-10">
                            <h3 className="text-[#01F0FB] mb-4 uppercase">{currentSlideData.current.title}</h3>
                            <p className="text-lg">{currentSlideData.current.content}</p>
                            <div className="absolute top-[1em] left-1/4 right-0 h-px bg-gradient-to-r from-white to-transparent"></div>
                        </div>

                        <div className="relative z-10">
                            <h3 className="flex items-center text-[#01F0FB] mb-4 uppercase">
                                <img src={tick} alt='tick' className='me-2' />
                                {currentSlideData.goal.title}
                            </h3>
                            <p className="text-lg">{currentSlideData.goal.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default KeyPerformanceIndicators;