import EnergyOilImage from '../../../assets/images/icons/EnergyOilImage.png';
import NorwoodLogo from '../../../assets/images/icons/NorwoodLogo.png';
import BgNoise from '../../../assets/images/icons/Background Noise.png';
import NorwoodBg from '../../../assets/images/icons/NorwoodBg.png';
import ArrowRightBlack from '../../../assets/images/icons/arrow-right-black.svg';
import GraphArrowUp from '../../../assets/images/icons/GraphArrowUp.svg';
import GradientFront from '../../../assets/images/GradientFront.png';
import productBackgroundNoise from '../../../assets/images/productsBackgroundNoise.png';
import key from '../../../assets/images/icons/key.svg';
import EnsureProfit from '../../../assets/images/ensureProfit.png';

import Developments from '../../../assets/images/developments.png';
import Contracts from '../../../assets/images/contracts.png';
import Instagram from '../../../assets/images/instagramlogo.png';
import LinkedIn from '../../../assets/images/linkedinlogo.png';
import X from '../../../assets/images/X.png';
import InstagramBlack from '../../../assets/images/instagramlogo-black.png';
import LinkedInBlack from '../../../assets/images/linkedinlogo-black.png';
import XBlack from '../../../assets/images/X-black.png';
import FBBlack from '../../../assets/images/facebooklogo-black.png';
import PhoneIcon from '../../../assets/images/phoneIcon.svg';
import RightArrow from '../../../assets/images/arrow-right-black.svg';
import CircleImage from '../../../assets/images/circle-images.png';
import BlueTick from '../../../assets/images/blueTick.png';
import homeBG from '../../../assets/images/nurwood/home-bg.png';
import ExpectedReturnIcon from '../../../assets/images/nurwood/expected-return-con.svg';
import solarComaniesImg from '../../../assets/images/nurwood/solar-companies.png'


import { ArrowRight, MoveRight } from 'lucide-react';
import './EnergyPortfolio.scss';
import React, { useState } from 'react';
import ScrollableSection from '../../../components/Portfolios/ScrollableSection';
import KeyInsights from './Nurwood/KeyInsights';
import RevolveEnergySection from './Nurwood/RevolveEnergySection';
import NurwoodTeam from './Nurwood/NurwoodTeam';
import AchievementSection from './Nurwood/AchievementSection';
import RoadmapSection from './Nurwood/RoadmapSection';
import ClientReviewSection from './Nurwood/ClientReviewSection';
import LegalComplianceSection from './Nurwood/LegalComplianceSection';
import Footer from '../../../components/Footer';
import FooterCommon from '../../../components/common/FooterCommon';
import NurwoodProducts from './Nurwood/NurwoodProducts';
import NewTopHeader from '../../../components/NewHeader';
import ContactFormPopup from './Nurwood/ContactFormPopup';

const EnergyPortfolio = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [products, setProducts]: any = useState([
        {
            name: 'PREMUIM GASOLINE',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        },
        {
            name: 'DIESEL FUEL',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        },
        {
            name: 'PREMUIM GASOLINE',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        },
        {
            name: 'DIESEL FUEL',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        },
        {
            name: 'PREMUIM GASOLINE',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        },
        {
            name: 'DIESEL FUEL',
            description: 'High-performance fuel for optimal engine efficiency and reduced emissions.',
            image: solarComaniesImg,
        }
    ])
    return (
        <div className="container">
            <div className='relative h-[80%]'>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
                        background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
                    }}>
                        <NewTopHeader />
                    </div>
                </div>
                <div className="background-image">
                    <img src={NorwoodBg} alt="Background" className="h-screen object-cover w-screen" />
                </div>
                <div className='flex flex-col gap-8 absolute top-[20px] left-[2rem] md:top-[182px] md:left-[100px]'>
                    <div className="header-logo">
                        <img style={{ width: 76, height: 78 }} src={NorwoodLogo} alt="Norwood Energy" />
                    </div>
                    <div className="main-title">
                        <h1 className="mt-3 text-5xl md:text-7xl lg:text-8xl font-extrabold mb-4 uppercase">
                            ENERGY REFINING <br /> SOLUTIONS</h1>
                    </div>
                    <div className="net-worth font-bold">
                        <h2 className='text-sm flex items-center'>| <span className='ms-3 text-[20px] font-medium'>Net Worth</span></h2>
                        <h2 className='text-5xl md:text-7xl lg:text-8xl'>| $1,950,000</h2>
                    </div>

                    <button className='cursor-text w-fit bg-white px-4 py-2 rounded-xl text-xl flex gap-2 items-center text-[#1E1E1E] font-medium'>
                        {/* <div> */}
                        <img src={ExpectedReturnIcon} alt={'ExpectedReturnIcon'} className='h-[20px]' />
                        {/* </div> */}
                        EXPECTED RETURN: <b>16%</b>
                    </button>

                    <button onClick={() => setIsOpen(true)}
                        className='text-white text-2xl rounded-full w-fit bg-[#1E1E1E] px-5 py-3 flex gap-2 items-center'>
                        Contact Us
                        <MoveRight className='bg-white rounded-full p-2' color='#1E1E1E' size={30} />
                    </button>

                </div>
                <div className='absolute px-3 md:right-[100px] bottom-[1rem] md:bottom-[100px]'>
                    <div className="mission-vision w-full md:w-[420px] rounded-3xl flex flex-col gap-5 py-4">
                        <span style={{ lineHeight: '3rem' }}>MISSION <br></br>& VISION</span>
                        <p className='text-md'>To deliver high-quality, sustainable energy products, ensuring safety, efficiency, and environmental responsibility.</p>
                        <p className='text-md'>To lead the industry in innovative refining practices, contributing to a cleaner and more sustainable energy future.</p>
                    </div>
                </div>
            </div>


            <ScrollableSection img={EnergyOilImage} />

            <div className='sec3Bg h-screen flex items-center' style={{ position: 'relative', width: '100%' }}>

                <div className='w-full justify-center items-start flex flex-col max-w-[90%] mx-auto'>
                    <div className='text-white text-start text-5xl font-bold uppercase mb-10'>
                        <h1 className='text-start'>OUR PRODUCTS</h1>
                    </div>

                    <NurwoodProducts products={products} />

                    <div className='product-note'>
                        <p>
                            Currently, Norwood Energy is enhancing our refining capacity and optimizing operations for greater efficiency. Future plans include investing in renewable energy projects and diversifying into sustainable energy solutions globally.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F4F8F8', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className='md:gap-36 gap-5' style={{ width: '85%', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <div className='flex-col md:flex-row md:py-[72px] md:items-center md:justify-center flex'>
                            <div className='keyinsights' style={{ flex: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>
                                        <img src={key} alt='key' className='h-[60px] w-[60px] md:h-[120px] md:w-[120px]' />
                                    </div>
                                    <div>
                                        <span>KEY</span>
                                    </div>
                                </div>
                                <div>
                                    <span>INSIGHTS</span>
                                </div>
                            </div>
                            <div className='flex-1 flex flex-col gap-5 font-medium text-lg mb-10 md:mb-0'>
                                <span style={{ color: '#676767' }}>
                                    Our marketing strategy involves digital campaigns using social media, email marketing, and SEO to enhance online visibility. We also form strategic partnerships with eco-friendly organizations and participate in industry trade shows to showcase our products and connect with industry leaders.
                                </span>

                                <span style={{ color: '#676767' }}>
                                    Additionally, we focus on content marketing centered on sustainability, creating articles, blog posts, and videos that highlight the environmental benefits and technological advancements of our products. This approach helps us engage eco-conscious consumers, build strong brand awareness, and drive meaningful engagement.
                                </span>
                            </div>
                        </div>
                        <KeyInsights />
                    </div>
                    <div className="relative w-full h-[400px] sm:h-[300px] md:h-[500px] rounded-[24px] overflow-hidden">
                        {/* Background Image */}
                        <img src={EnsureProfit} alt="Background" className="w-full h-full object-cover" />

                        {/* Overlay Text */}
                        <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-4">
                            {/* Heading */}
                            <h1 className="uppercase font-bold text-[#0294FF] text-[2.5rem] leading-tight sm:leading-tight md:text-[4rem] md:leading-tight">
                                ensuring a profitable <br /> customer relationship
                            </h1>

                            {/* Subtext */}
                            <span className="mt-4 bg-[#8D8D8D33] text-white text-sm md:text-base p-2 md:p-3 rounded-full inline-flex items-center space-x-2">
                                <span className="text-lg text-white">✨</span>
                                <span>Our CAC is $200, with an LTV of $2,000</span>
                            </span>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <RevolveEnergySection />
                    </div>
                    <div>
                        <NurwoodTeam />
                    </div>
                    <div>
                        <AchievementSection />
                    </div>
                    <div>
                        <RoadmapSection />
                    </div>
                    <ClientReviewSection />
                    <div className="get-in-touch-container">
                        <h2 className="text-4xl md:text-7xl text-[#1e1e1e] uppercase font-bold tracking-tight">Get In Touch</h2>
                        <div className="contact-info my-10">
                            <div className="contact-detail">
                                <p className="text-[#676767] text-md md:text-md font-normal">Email:</p>
                                <p className="text-[#1E1E1E] text-lg md:text-2xl font-semibold">info@greentechsolutions.com</p>
                            </div>
                            <div className="contact-detail">
                                <p className="text-[#676767] text-md md:text-md font-normal">Mobile:</p>
                                <p className="text-[#1E1E1E] text-lg md:text-2xl font-semibold">+1-800-555-1234</p>
                            </div>
                            <div className="flex gap-1 justify-center items-center my-10">
                                <a href="https://twitter.com" className="social-icon twitter" aria-label="Twitter">
                                    <img src={X} alt='' />
                                </a>
                                <a href="https://linkedin.com" className="social-icon linkedin" aria-label="LinkedIn">
                                    <img src={LinkedIn} alt='' />
                                </a>
                                <a href="https://instagram.com" className="social-icon instagram" aria-label="Instagram">
                                    <img src={Instagram} alt='' />
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-center mb-10 md:max-w-6xl md:mx-auto">
                            <p className="contact-message">
                                Reach out today to learn more about our sustainable energy solutions and how we can help you achieve your green goals.
                            </p>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="bg-[#15171A] text-white px-2 py-1.5 ps-4 rounded-full flex items-center justify-between w-fit cursor-pointer">
                                <span className="font-medium me-2">Contact Us</span>
                                <img src={PhoneIcon} alt="Phone Icon" className="ml-3 p-2 bg-white rounded-full" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <LegalComplianceSection />

                        <div className="flex flex-col justify-center items-center my-8">
                            <div className="bg-[#15171A] text-white px-2 py-1.5 ps-4 rounded-full flex items-center justify-between w-fit cursor-pointer">
                                <span className="font-medium me-2">Get Full Deck</span>
                                <img src={RightArrow} alt="Phone Icon" className="ml-3 p-2 bg-white rounded-full" />
                            </div>
                            <p className="view-analytics-text mt-4">To view analytics</p>

                        </div>
                    </div>
                </div>
            </div>

            <ContactFormPopup
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                bgImage={NorwoodBg}
            />
            <FooterCommon />
        </div>
    );
}

export default EnergyPortfolio; 