import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const initialFormData = { name: '', email: '', phone: '', message: '' };
export const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

const ContactSection = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('authToken');


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePhoneChange = (phone: string) => {
        setFormData({ ...formData, phone });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const { name, email, phone, message } = formData;
            setLoading(true);

            if (!name || !email || !phone || !message) {
                toast.error('Please fill in all the required fields.');
                return;
            }

            if (!isValidEmail(email)) {
                toast.error('Please enter a valid email address.');
                return;
            }

            const payload = {
                name,
                email,
                phone,
                message,
                meetingLink: '',
            };

            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}enquiry/${process.env.REACT_APP_CLIENT_GASKON_ID}`,
                payload,
                { headers }
            );

            if (response.status === 200) {
                setFormData(initialFormData);
                toast.success('Form submitted successfully!');
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'An error occurred while submitting the form.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-[#F9FBFE] p-5 md:mt-[200px] md:py-12">
            <div className="md:w-[80%] mx-auto md:px-6 lg:px-12 flex flex-col lg:flex-row justify-between items-center lg:space-x-12">
                {/* Contact Info Section */}
                <div className="lg:w-1/2 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900">
                        <span className="me-2 bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4] bg-clip-text text-transparent">
                            Get In Touch
                        </span>
                        With Us
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        We’d love to hear from you! Whether you have questions, need more information about our services, or are ready to start your journey with us, simply fill out the form below and we’ll get back to you shortly.
                    </p>
                    <div className="mt-8 flex space-x-4 text-[#1D1D1D]">
                        <div className="flex items-center space-x-2 px-4 py-2 bg-white rounded-full shadow-md border border-[#00131E1A]">
                            <span className="text-xl text-gray-600">📞</span>
                            <span className="text-gray-900 font-medium">+123 456 7890</span>
                        </div>
                        <div className="flex items-center space-x-2 px-4 py-2 bg-white rounded-full shadow-md border border-[#00131E1A]">
                            <span className="text-xl text-gray-600">✉️</span>
                            <span className="text-gray-900 font-medium">info@gaskon.io</span>
                        </div>
                    </div>
                </div>

                {/* Form Section */}
                <div className="lg:w-1/2 md:max-w-xl bg-white p-6 lg:p-8 rounded-lg shadow-lg md:justify-end">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full p-3 border bg-[#EDF2F6] border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full p-3 border bg-[#EDF2F6] border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <PhoneInput
                            country={'us'}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            inputStyle={{ width: '100%', background: '#EDF2F6', padding: 24, marginLeft: 2 }}
                            inputClass="w-full p-3 border bg-[#EDF2F6] border-gray-200 rounded-lg outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            rows={4}
                            value={formData.message}
                            onChange={handleInputChange}
                            className="w-full p-3 border bg-[#EDF2F6] border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        ></textarea>
                        <p className="text-sm text-[#636567] flex gap-2">
                            <input
                                required
                                type="checkbox"
                                id="privacy-policy"
                                className="h-5 w-5 text-purple-600 cursor-pointer"
                            />
                            By clicking on "Send" you will agree to our
                            <a href="#" className="text-[#1d1d1d] hover:underline"> Privacy Policy.</a>
                        </p>
                        <button
                            type="submit"
                            className="w-full py-3 bg-[#0177D1] text-white font-semibold rounded-full hover:bg-blue-600 transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
