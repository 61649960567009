import React from 'react'
import bg from '../../../../assets/images/lusso/GradientBg2.svg'
// Gradientbg.svg'
import circlebg from '../../../../assets/images/lusso/circleTitleBg.svg'
import rightImg from '../../../../assets/images/lusso/circle.gif'
// setsApart.svg'

function AIPersonalization() {
    return (
        <div className='relative bg-gradient-to-b from-transparent via-black to-black -mt-5 flex flex-col items-center justify-between md:min-h-[60rem] md:h-screen bg-[#010101] p-5 object-cover bg-no-repeat'
            style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}
        >

            <div className="flex justify-start h-full items-center md:w-[80%] m-auto">
                {/* <div className='flex flex-col items-start py-40 px-5 rounded-full text-7xl font-bold text-white
                    uppercase absolute left-16 top-5 md:-mt-[10px] object-cover bg-no-repeat'
                    style={{
                        background: `url(${circlebg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}>
                    What sets<br />us apart
                </div> */}
                <div className='w-full md:max-w-3xl flex flex-col gap-10 text-white font-normal'>
                    <p className='text-8xl font-bold tracking-tight uppercase'>What sets<br />us apart</p>
                    <p className='text-3xl capitalize font-bold'>AI-Powered Personalization</p>
                    <p className='text-xl md:max-w-[100%] font-normal'>
                        Lusso.ai is distinct from competitors by offering a truly personalized, AI-powered experience that streamlines content creation,
                        marketing, and SEO. The platform’s automation features help businesses scale rapidly while maintaining efficiency.
                    </p>
                </div>
            </div>
            {/* <div className='bg-gradient-to-b from-transparent via-black to-black' /> */}

            {/* Right Img */}
            <img src={rightImg} alt='right img' className='absolute md:-right-[10rem] top-[27%] h-[30rem]' />

        </div>
    )
}

export default AIPersonalization