import React from 'react'
import globeIcon from '../../../../assets/images/lusso/globe2.svg'
import summeryBottomBG from '../../../../assets/images/lusso/summeryInfoNewBG.svg'
// summerbottombg.svg'
import img1 from '../../../../assets/images/lusso/s1.svg'
import img2 from '../../../../assets/images/lusso/s2.svg'
import img3 from '../../../../assets/images/lusso/s3.svg'
import statsImg from '../../../../assets/images/lusso/stats.png'
import SummeryInfoCard from './SummeryInfoCard'
import overlay from '../../../../assets/images/lusso/revenueOverlay.svg'

function SummerySection() {

    let summeryInfoData = [
        {
            img: img1,
            title: 'AI-powered automation tools',
            desc: 'Automate tasks to boost efficiency and streamline business operations.',
        },
        {
            img: img2,
            title: 'Advanced SEO optimization',
            desc: 'Maximize online visibility with cutting-edge, AI-driven SEO techniques.',
        },
        {
            img: img3,
            title: 'Personalized AI-driven solutions',
            desc: 'Tailor shopping experiences with intelligent, data-driven recommendations.',
        }
    ]


    const StatsDisplay = () => {
        return (
            <div className="relative flex flex-col gap-10 text-white">
                <p className='text-8xl font-bold tracking-tighter uppercase drop-shadow-lg' style={{ textShadow: '2px 2px 8px rgba(0, 0, 0, 0.8)' }}>
                    Revenue <br />Streams
                </p>
                <img src={statsImg} className='w-full' alt='stats' />
            </div>
        );
    };

    return (
        <div className='relative'>
            <div className='relative flex flex-col justify-end bg-[#010101]  p-5 md:pt-[200px] object-cover bg-no-repeat'>
                <div className='md:w-[80%] text-white md:mx-auto flex flex-col gap-2 items-center'>
                    <div className='flex flex-col md:flex-row items-center uppercase font-bold'>
                        <span className='text-xl md:text-5xl'>Revolutionising</span>
                        <img src={globeIcon} className='animate-spin-smooth h-[150px] md:-mx-12' alt='globe' />
                        <span className='text-xl md:text-5xl'>Digital Commerce</span>
                    </div>
                    <p className='mt-10 text-[#FFFFFF] text-lg md:max-w-5xl text-center'>Lusso.ai empowers businesses with cutting-edge AI technology for digital commerce. Our platform offers intelligent automation for streamlined operations, data-driven insights for strategic decision-making, and scalable solutions adaptable to businesses of all sizes. We deliver up to a 40% reduction in operational costs while increasing revenue by 10%, enhancing customer personalization, and providing robust, adaptive security. For investors, Lusso.ai represents a high-growth opportunity in the booming AI and e-commerce sectors. Join us in shaping the future of digital commerce.</p>
                </div>

                <div className='md:max-w-7xl md:min-h-[600px] mx-auto my-10 md:mt-0 md:mb-[100px] flex flex-col md:flex-row gap-3 md:gap-8 justify-center items-center md:justify-start'>
                    {
                        summeryInfoData?.map(e => (
                            <SummeryInfoCard img={e?.img} title={e?.title} desc={e?.desc} />
                        ))
                    }
                </div>
            </div>
            <img src={overlay} alt='revenue-overlay' className='absolute z-10 top-[30%] -left-[30rem]' />

            <div
                className="relative flex flex-col justify-end bg-[#010101] p-5 md:p-0 object-cover md:min-h-screen bg-no-repeat"
                style={{
                    backgroundImage: `url(${summeryBottomBG})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                    //  width: '100vw', 
                }}
            >

                <div className='md:w-[80%] mx-auto text-white flex flex-col md:flex-row 
                    justify-between lg:mt-[35rem] xl:mt-[45rem] md:pb-[50px]'>
                    <StatsDisplay />

                    {/* Revenue Model Section */}
                    <section className="pt-12 relative flex flex-col gap-10">
                        <div className="relative">
                            <h2 className="text-4xl font-bold">Subscription-Based Model</h2>
                            <p className="relative border-b-2 text-lg border-transparent after:absolute after:inset-0 after:h-[0.5px] after:bg-gradient-to-l after:from-black after:to-white after:bottom-0 after:left-0 after:right-0 mt-2 mb-8">
                                Recurring revenue from tiered subscription plans (Access, Elite, Infinity)
                            </p>
                        </div>

                        <div className="relative">
                            <h2 className="text-4xl font-bold">AI Tools and Custom Solutions</h2>
                            <p className="relative border-b-2 text-lg border-transparent after:absolute after:inset-0 after:h-[0.5px] after:bg-gradient-to-l after:from-black after:to-white after:bottom-0 after:left-0 after:right-0 mt-2 mb-8">

                                Projected to generate $3.36M - $5.04M annually through AI services.
                            </p>
                        </div>

                        <div className="relative">
                            <h2 className="text-4xl font-bold">Marketplace Commissions</h2>
                            <p className="relative border-b-2 text-lg border-transparent after:absolute after:inset-0 after:h-[0.5px] after:bg-gradient-to-l after:from-black after:to-white after:bottom-0 after:left-0 after:right-0 mt-2 mb-8">

                                High transaction volumes expected to contribute $1.68M - $3.36M annually.
                            </p>
                        </div>

                        <div className="relative">
                            <h2 className="text-4xl font-bold">Enterprise Partnerships</h2>
                            <p className="relative border-b-2 text-lg border-transparent after:absolute after:inset-0 after:h-[0.5px] after:bg-gradient-to-l after:from-black after:to-white after:bottom-0 after:left-0 after:right-0 mt-2 mb-8">
                                B2B partnerships contributing another $4M - $6M in annual revenue.
                            </p>
                        </div>
                    </section>


                </div>

            </div>
        </div>

    );
};

export default SummerySection