import React from 'react'
import bg from '../../../../assets/images/lusso/market-opportunity.svg'
import marketGreen from '../../../../assets/images/lusso/marketGreen.svg'
import marketPink from '../../../../assets/images/lusso/marketPink.svg'
import overlay from '../../../../assets/images/lusso/marketOpportunityOverlay.svg'
import map from '../../../../assets/images/lusso/map.svg'
import dotoverlay from '../../../../assets/images/lusso/dotOverlay.svg'

function MarketOpportunity() {
    return (
        <div className='relative flex flex-col justify-between md:min-h-screen bg-[#010101] p-5 md:py-[200px] object-cover bg-no-repeat'
            style={{
                // backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100vh'
            }}
        >

            {/* <div className='h-full absolute'
                style={{
                    background: `linear-gradient(0deg, #010101, #010101),
                        linear-gradient(9.77deg, rgba(0, 0, 0, 0) 19.97%, rgba(1, 1, 1, 0.2) 94.16%)`
                }} /> */}
            <div className='w-[80%] h-auto absolute -top-1/3 -right-[12%]'>
                <img src={overlay} alt='map' className='scale-100' />
            </div>
            <div className='w-[60%] h-auto absolute mt-[20px] right-6'>
                <img src={dotoverlay} alt='map' />
            </div>

            <div className='relative md:w-[80%] md:mx-auto font-medium text-white flex flex-col gap-12 items-center md:items-end'>

                <p className='text-2xl md:text-7xl font-bold md:text-end uppercase'>Market <br />opportunity</p>
                <p className='relative text-lg md:text-3xl md:text-end md:max-w-4xl'>The global AI market is expected to grow from
                    <span className='relative mx-2'>
                        $8.8 trillion
                        <img src={marketGreen} alt='green' className='absolute right-0 z-30' />
                    </span> in 2024 to  <span className='relative mx-2'>
                        $18.81 trillion
                        <img src={marketPink} alt='green' className='absolute right-0 z-30' />
                    </span> trillion by 2029, at a growth rate of 15.8% annually</p>

                <div className='flex flex-col gap-2 items-center md:items-end text-md md:text-2xl'>
                    <p className='md:text-end md:text-2xl'>• 15.8 Annual Growth rate</p>
                    <p className='md:text-end md:text-2xl'>• 21.2% of retail sales</p>
                </div>

            </div>

            <div className="absolute left-5 bottom-5 w-[65vw] h-auto">
                <img src={map} alt='map'  className='object-cover w-full h-auto'/>
            </div>
            {/* <div className="absolute border-none bottom-0 w-full bg-gradient-to-b from-black via-[#000] to-[#211F46] h-[100px]"></div> */}


        </div>
    )
}

export default MarketOpportunity