import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, ArrowDown, ArrowUp, Mail, Phone, Eye, Check, X, MoreHorizontal, MoreVertical, Trash } from 'lucide-react';
import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment'
import StatusDropdown from './StatusDropdown';
import MobileDataView from './InvestorRequest/MobileDataView';
import Swal from "sweetalert2";
import OptionsDropdown from './InvestorRequest/OptionDropdown';

const InvestorRequests = () => {
  const [requests, setRequests] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: 'email', direction: 'ascending' });
  const [approvedRequestCount, setApprovedRequestCount] = useState(0);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [rejectedRequestCount, setRejectedRequestCount] = useState(0);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const [isOpenOption, setIsOpenOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const [openDropdownOptionIndex, setOpenDropdownOptionIndex] = useState<number | null>(null);
  const [shouldRefresh, SetRefresh] = useState(false)

  useEffect(() => {
    getApprovalRequests(currentPage);
  }, [currentPage, activeTab]);

  const getApprovalRequests = async (currentPage: number) => {
    try {
      setLoading(true);
      const token = Cookies.get("authToken");
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}accessRequests?page=${currentPage-1}&size=${itemsPerPage}`, {
        headers: { 
          accept: '*/*',
          "Authorization": `Bearer ${token}` },
      });

      const requests = response.data.requests?.map((e: any) => ({
        ...e,
        status: e?.status === 'Verification Pending' ? 'Pending' : e?.status
      })) || [];

      setRequests(requests);
      // setApprovedRequestCount(response?.data?.approvedRequestCount)
      // setPendingRequestCount(response?.data?.pendingRequestCount)
      // setRejectedRequestCount(response?.data?.rejectedRequestCount)
      setTotalRequestCount(response.data.recordsFiltered);
      setTotalPages(Math.ceil(response.data.recordsFiltered / itemsPerPage)); // Total pages calculation
      setLoading(false);
    } catch (err) {
      console.error(err);
      Swal.fire('Error!', 'Failed to fetch requests.', 'error');
      setLoading(false);
    }
  };

  const statusCounts = useMemo(() => ({
    All: requests.length,
    Pending: requests.filter((req: any) => req.status === "Pending" || req.status === 'Verification Pending').length,
    Approved: requests.filter((req: any) => req.status === "Approved").length,
    Rejected: requests.filter((req: any) => req.status === "Rejected").length,
  }), [requests]);

  const filteredRequests = React.useMemo(() => {
    return activeTab === "All"
      ? requests
      : requests.filter((req: any) => req.status === activeTab);
  }, [activeTab, requests]);

  const sortedRequests = React.useMemo(() => {
    let sortableRequests = [...filteredRequests];

    if (sortConfig.key) {
      sortableRequests.sort((a, b) => {
        const aValue = a[sortConfig.key] ?? ''; // Handle null or undefined values
        const bValue = b[sortConfig.key] ?? '';

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableRequests;
  }, [filteredRequests, sortConfig, requests]);

  // console.log(sortedRequests)

  const requestSort = (key: any) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const SortIcon = ({ columnName }: any) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === "ascending" ? (
        <ArrowUp className="w-4 h-4 inline-block ml-1" />
      ) : (
        <ArrowDown className="w-4 h-4 inline-block ml-1" />
      );
    }
    return null;
  };


  const handleDropdownOptionToggle = (index: number) => {
    setOpenDropdownOptionIndex(openDropdownOptionIndex === index ? -1 : index);
  };

  const handleStatusChange = (requestId: string, optionLabel: string, isOption: boolean) => {
    const action = isOption ? optionLabel : optionLabel === "Approve" ? "Approve" : "Reject";

    Swal.fire({
      title: `Are you sure?`,
      text: `Do you want to ${action} this request?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${action} it!`,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = Cookies.get("authToken");
        let headers = {
          accept: '*/*',
          "Authorization": `Bearer ${token}`
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}accessRequestAction`, { requestId, action }, { headers })
          .then(response => {
            if (response.status === 200 && response?.data?.message) {
              Swal.fire({
                title: `Confirmed!`,
                text: `Your request has been ${action}.`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
              });
              getApprovalRequests(currentPage);  // Fetch updated requests after the action
            } else {
              Swal.fire(
                'Error!',
                'Something went wrong. Please try again later.',
                'error'
              );
            }
            setOpenDropdownOptionIndex(null)
          })
          .catch(err => {
            console.error(err);
            Swal.fire(
              'Error!',
              'Something went wrong. Please try again later.',
              'error'
            );
          })
          .finally(() => {
            setLoading(false);  // Ensure loading is stopped after the API call finishes
          });
      }
    });
  };

  // paggination state changes
  useEffect(() => {
    const newTotalPages = Math.ceil(totalRequestCount / itemsPerPage);
    setTotalPages(newTotalPages || 1);
  }, [requests]);

  // Pagination Buttons
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  // render
  return (
    <div className="p-2 md:p-6 relative">
      <h1 className="text-3xl font-bold mb-6">Investor Requests</h1>
      <div className='flex flex-col md:flex-row md:justify-between items-center gap-3 mb-4'>
        {/* Tabs */}
        <div className='w-full md:w-fit inline-flex flex-wrap md:flex-nowrap rounded-lg bg-[#E1E5E43D] p-1 items-center border-[#808B8B33] border-[1px]'>
          {Object.entries(statusCounts)?.map(([status, count], index) => (
            <>
              <button
                key={status}
                className={`px-2 md:px-4 py-2 text-xs md:text-sm ${activeTab === status
                  ? 'text-blue-600 font-semibold '
                  : 'text-gray-500 hover:text-gray-700 font-medium '
                  }`}
                onClick={() => setActiveTab(status)}
              >
                {status} <span className='font-semibold'>({count})</span>
              </button>
              {/* seperator */}
              {index < Object.entries(statusCounts).length - 1 && (
                <span className="text-gray-400 mx-1">/</span>
              )}
            </>
          ))}
        </div>

        {/* Pagging */}
        <div className="w-fit flex items-center space-x-2">
          <button
            className="p-1 rounded-full bg-gray-200 disabled:opacity-50"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <div className='flex items-center w-fit px-2'>
            <span className="font-medium text-lg">{currentPage}</span>
            <span className="text-gray-500 font-medium text-lg"> / {totalPages}</span>
          </div>
          <button
            className="p-1 rounded-full bg-[#1e1e1e] text-white disabled:opacity-50"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="hidden md:block bg-white shadow rounded-lg overflow-x-auto scrollbar-none">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => requestSort("name")}>
                Name <SortIcon columnName="name" />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => requestSort("email")}>
                Contact <SortIcon columnName="email" />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Business Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => requestSort("createdOn")}>
                Raised On <SortIcon columnName="createdOn" />
              </th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-end">Approval Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan={5} className="px-6 py-4 whitespace-nowrap">
                  Loading...
                </td>
              </tr>
            ) : (
              sortedRequests.map((request, index) => (
                <tr key={request.requestId} className="hover:bg-gray-50 hover:font-bold">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <div className={`h-10 w-10 rounded-full flex items-center justify-center text-white bg-green-400`}>
                          {request?.name ? request.name.charAt(0).toUpperCase() : '?'}
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm md:text-lg capitalize text-gray-900">{request?.name ? request?.name : ' Name is missing .. '}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap flex flex-col gap-1 justify-center">
                    <div className="text-sm md:text-lg text-[#2B3940]"><Mail className="inline-block w-4 h-4 mr-1 text-gray-400" />{request.email || 'Email not available'}</div>
                    <div className="text-sm md:text-lg text-[#2B3940]"><Phone className="inline-block w-4 h-4 mr-1 text-gray-400" />{request.phoneNumber || 'Number not available'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm md:text-lg text-gray-900 capitalize">{request.businessName || 'n/a'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm md:text-lg text-gray-900">{request.createdOn ? moment(request.createdOn).format('DD MMM, YYYY') : 'n/a'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-end flex gap-2 justify-end items-center relative">
                    <StatusDropdown
                      data={request}
                      initialStatus={request.status}
                      index={index}
                      isOpenIndex={openDropdownIndex}
                      setIsOpenIndex={setOpenDropdownIndex}
                      handleStatusChange={handleStatusChange}
                    />

                    <OptionsDropdown
                      isOpen={(openDropdownOptionIndex) === index}
                      onRemove={() => handleStatusChange(request.requestId, 'Revoke', true)}
                      onDelete={() => setOpenDropdownOptionIndex(null)}
                      onToggle={() => handleDropdownOptionToggle(index)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


      {/* Mobile View */}
      <div className='md:hidden'>
        <MobileDataView
          openDropdownIndex={openDropdownIndex}
          openDropdownOptionIndex={openDropdownOptionIndex}
          handleDropdownOptionToggle={handleDropdownOptionToggle}
          requests={requests}
          OptionsDropdown={OptionsDropdown}
          setOpenDropdownIndex={setOpenDropdownIndex}
          setOpenDropdownOptionIndex={setOpenDropdownOptionIndex}
          handleStatusChange={handleStatusChange} />
      </div>
    </div>
  );
};


export default InvestorRequests;