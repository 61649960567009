import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, ArrowLeft } from 'lucide-react';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center px-6 py-12">
      <div className="max-w-md w-full text-center">
        {/* Custom SVG illustration */}
        <svg
          className="w-full h-64 mx-auto mb-8"
          viewBox="0 0 400 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background circles */}
          <circle cx="200" cy="150" r="120" fill="#F3F4F6" />
          <circle cx="200" cy="150" r="80" fill="#E5E7EB" />
          
          {/* 404 Text */}
          <path
            d="M140 100h20v80h-20zm0-30h20v20h-20z"
            fill="#4B5563"
          />
          <circle
            cx="200"
            cy="150"
            r="30"
            fill="#4B5563"
          />
          <path
            d="M240 100h20v80h-20zm0-30h20v20h-20z"
            fill="#4B5563"
          />
          
          {/* Decorative elements */}
          <circle cx="120" cy="80" r="8" fill="#6366F1" />
          <circle cx="280" cy="220" r="8" fill="#6366F1" />
          <circle cx="65" cy="150" r="6" fill="#8B5CF6" />
          <circle cx="335" cy="150" r="6" fill="#8B5CF6" />
          <path
            d="M160 220l80-140"
            stroke="#6366F1"
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray="8 8"
          />
        </svg>

        {/* Content */}
        <h1 className="text-5xl font-bold text-gray-900 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Page Not Found</h2>
        <p className="text-gray-500 mb-8">
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Go Back
          </button>
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            <Home className="w-5 h-5 mr-2" />
            Back to Home
          </button>
        </div>

        {/* Additional Help Link */}
        {/* <p className="mt-8 text-sm text-gray-500">
          Need help?{' '}
          <a
            href="/contact"
            className="font-medium text-indigo-600 hover:text-indigo-500 transition-colors duration-200"
          >
            Contact Support
          </a>
        </p> */}
      </div>
    </div>
  );
};

export default NotFound;