import React from 'react';
import Founder1 from '../../../../assets/images/SarahFounder.svg';
import Founder2 from '../../../../assets/images/Founder2.svg';
import Founder3 from '../../../../assets/images/Founder3.svg';
import blackLine from '../../../../assets/images/evolveEnergy/blackLine.svg'

// Team Member Component
export const TeamMember = ({ image, name, title, description }: { image: string, name: string, title: string, description: string }) => {
    return (
        <div className="flex flex-col items-center">
            <img
                src={image}
                alt={name}
                className="w-full h-full object-cover mb-4"
            />
            <div className='flex flex-col gap-1.5 py-6 w-full'>
                <h3 className="text-md text-[#676767] font-medium flex gap-1.5">
                    <img src={blackLine} className='w-[2px]' />
                    {title}
                </h3>
                <p className="text-[#1E1E1E] font-semibold text-3xl uppercase">{name}</p>
                <p className="text-xs text-[#676767] font-lg">{description}</p>
            </div>
        </div>
    );
};

// Team Section Component
const NurwoodTeam = () => {
    const team = [
        {
            img: Founder1,
            name: 'Sarah Johnson',
            title: 'CEO',
            description: '20 years in renewable energy, former VP at SolarTech.',
        },
        {
            img: Founder2,
            name: 'David Lee',
            title: 'CTO',
            description: 'Expert in energy storage systems, PhD in Electrical Engineering',
        },
        {
            img: Founder3,
            name: 'Maria Gonzalez',
            title: 'COO',
            description: '15 years in operations management, MBA from Harvard.',
        },
    ];

    return (
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="text-md text-[#676767] font-medium flex gap-1.5">
                <img src={blackLine} className='w-[2px]' />
                Our Team
            </h3>
            <span className="component-header text-[2rem] md:text-[4rem]">Founders &<br></br>Key Members</span>
            <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-start">
                {team.map((member, index) => (
                    <TeamMember
                        key={index}
                        image={member.img}
                        name={member.name}
                        title={member.title}
                        description={member.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default NurwoodTeam