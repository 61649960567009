import React from "react";
import "./contactus.scss";
import ArrowNE from "../../assets/images/icons/arrowWhite.png";
import animateArrow from "../../assets/images/icons/animateIcon.png";

const Contact_us: React.FC = () => {
  const handleMouseMove = (e: React.MouseEvent) => {
    const teamUpSection = document.querySelector(
      ".ready-to-team-up"
    ) as HTMLElement;
    if (teamUpSection) {
      const rect = teamUpSection.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      teamUpSection.style.setProperty("--mouse-x", `${x}px`);
      teamUpSection.style.setProperty("--mouse-y", `${y}px`);
    }
  };

  return (
    <div className="contactus">
      <div
        style={{
          width: "80%",
          height: "46px",
          background: "rgba(57, 202, 221, 0.16)",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          backdropFilter: "blur(250px)",
          marginLeft: "200px",
          marginRight: "200px",
          opacity: 0.8,
        }}
        className="contact_Talk1"
      />
      <div
        style={{
          width: "90%",
          height: "46px",
          background: "rgba(57, 202, 221, 0.16)",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          backdropFilter: "blur(250px)",
          marginLeft: "100px",
          marginRight: "100px",
        }}
        className="contact_Talk2"
      />
      <section className="ready-to-team-up" onMouseMove={handleMouseMove}>
        <div className="gradient-shadow"></div>
        <h1>
        Connect with us
        </h1>
        <button
          className="lets-talk"
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            className="font-[Outfit] text-base font-bold"
            style={{ paddingLeft: "19px", color: "#1E1E1E" }}
          >
            <a href="https://calendly.com/lussolabs/gaskon-meet-up?month=2024-09" target="_blank">
            Let's talk
            </a>
          </span>
          <div className="arrow-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40px",
                height: "40px",
                background: "black",
                borderRadius: 9999,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={ArrowNE} alt="arrow" className="arrow"/>
              <img src={animateArrow} alt="arrow" className="new-arrow"/>
            </div>
          </div>
        </button>
        <p className="text-base capitalize font-normal">Book a 15 min call</p>
      </section>
    </div>
  );
};

export default Contact_us;
