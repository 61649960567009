import React from 'react';
import { Users } from 'lucide-react';

const data = [
  { subscribers: 500, revenue: '5 Million', valuation: '15 Million' },
  { subscribers: 1000, revenue: '10 Million', valuation: '30 Million' },
  { subscribers: 5000, revenue: '50 Million', valuation: '150 Million' },
  { subscribers: 10000, revenue: '100 Million', valuation: '300 Million' },
  { subscribers: 20000, revenue: '200 Million', valuation: '600 Million' },
];

const SubscriptionMetricsTable = () => {
  return (
    <div className="w-full text-white p-4 rounded-lg text-center text-lg font-bold">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="text-xl font-bold">Active Subscribers</div>
        <div className="text-xl font-bold">Annual Revenue</div>
        <div className="text-xl font-bold">Valuation ( based on 3X Multiplier )</div>
      </div>
      <div className="relative">
        {data.map((row, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2 relative">
            <div className="flex items-center justify-center">
              <Users className="mr-2" />
              <span className='mind-w-[300px]'>
                {row.subscribers.toLocaleString()}
              </span>
            </div>
            <div>$ {row.revenue}</div>
            <div>$ {row.valuation}</div>
            {index < data.length - 1 && (
              <div className="absolute left-0 right-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />
            )}
          </div>
        ))}
        <div className="absolute top-0 bottom-0 left-1/3 w-px bg-gradient-to-b from-transparent via-white to-transparent opacity-30 hidden md:block" />
        <div className="absolute top-0 bottom-0 left-2/3 w-px bg-gradient-to-b from-transparent via-white to-transparent opacity-30 hidden md:block" />
      </div>
    </div>
  );
};

export default SubscriptionMetricsTable;