import React, { useState } from 'react';
import Flash from '../../../assets/images/icons/flash.svg';
import Book from '../../../assets/images/icons/book.svg';
import './CreatePortfolioPopup.scss';

interface CreatePortfolioPopupProps {
  onClose: () => void;
}

const CreatePortfolioPopup: React.FC<CreatePortfolioPopupProps> = ({ onClose }) => {
    const [selectedOption, setSelectedOption] = useState<string | null>('ai');

    const handleOptionClick = (option: string) => {
      setSelectedOption(option);
    };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>How would you prefer to create your Portfolio?</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="popup-options">
          <div
            className={`option ai ${selectedOption === 'ai' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('ai')}
          >
             <img src={Flash} alt="Flash" className="option-icon ai-icon" />
            <div className="option-content">
              <h3>Create using AI</h3>
              <p>Leverage advanced AI to craft a professional portfolio with ease. Quick, efficient, and tailored to your needs.</p>
            </div>
            <div className="ribbon">
              <span>Upgrade Pro</span>
            </div>
          </div>
          <div
            className={`option scratch ${selectedOption === 'scratch' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('scratch')}
          >
           <img src={Book} alt="Book"  className="option-icon scratch-icon" />
            <div className="option-content">
              <h3>Create From scratch</h3>
              <p>Start from a blank canvas and build your portfolio step-by-step. Perfect for those who prefer a personalized touch.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePortfolioPopup;
