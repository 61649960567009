import React, { useState } from 'react';
import Logo from '../../../assets/images/icons/logo.svg';
import GreenTick from '../../../assets/images/icons/green_tick.svg';
import RedTick from '../../../assets/images/icons/wrongIcon.png';
import RightArrow from '../../../assets/images/icons/arrow-right-cropped.svg';
import EyeIcon from '../../../assets/images/icons/eye-icon.png';
import '../signup/SignUp.scss';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';

const ResetPassword = () => {
    const [password, setPassword]: any = useState('');
    const [confirmPassword, setConfirmPassword]: any = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [invalidField, setInvalidField] = useState('');

    const [rule1, setRule1] = useState(false);
    const [rule2, setRule2] = useState(false);
    const [rule3, setRule3] = useState(false);

    const validatePassword = (password: any) => {
        // Rule 1: At least 8 characters
        setRule1(password.length >= 8);

        // Rule 2: Contain a number or symbol
        setRule2(/[\d@$!%*?&]/.test(password));

        // Rule 3: Include a mix of uppercase and lowercase letters
        setRule3(/^(?=.*[a-z])(?=.*[A-Z])/.test(password));
    };

    const validateConfirmPassword = (pass: any) => {
        return pass == password;
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: '40px' }}>
                <Link to="/login" style={{ position: 'absolute', left: 0 }}>
                    <div className='back-button'>
                        <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center" }}><img src={RightArrow} alt="Logo" className='right-arrow' /></div>
                        <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center", marginLeft: "10px", color: 'black' }}>Back</div>
                    </div>
                    </Link>
                <div style={{ margin: '0 auto' }}>
                    <img src={Logo} alt="Logo" className='gaskon-logo' />
                </div>

            </div>
            <div className="signup-container">
                <h1 className="reset-title">RESET YOUR PASSWORD</h1>
                <span className='head-desc'>Almost done. Enter your new password and you’re good to go.</span>
                <div className="form-container">
                    <div className="input-group">
                        <label className="label">New Password*</label>
                        {/* <div className="input-value">**********</div> */}
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setInvalidField('');
                                    setPassword(e.target.value);
                                    validatePassword(e.target.value);
                                }
                                }
                            />
                            <div style={{cursor:'pointer'}} onClick={() => {setShowPassword((prev: Boolean) => !prev)}}>
                                <img src={EyeIcon} alt ='' />
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="input-group">
                        <label className="label">Confirm New Password*</label>
                        {/* <div className="input-value">**********</div> */}
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => {
                                    setInvalidField('');
                                    setConfirmPassword(e.target.value);
                                    validateConfirmPassword(e.target.value);
                                }
                                }
                            />
                            <div style={{cursor:'pointer'}} onClick={() => {setShowConfirmPassword((prev: Boolean) => !prev)}}>
                                <img src={EyeIcon} alt ='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="password-strength">
                    <span style={{ marginBottom: '17px' }}>Password Strength: </span>
                    <span
                        style={{
                            color:
                                rule1 && rule2 && rule3
                                    ? 'green' // All rules are true
                                    : rule1 && rule2 || rule1 && rule3 || rule2 && rule3
                                    ? '#A78837' // Two rules are true
                                    : 'red',   // Less than two rules are true
                        }}
                        className="strength-status"
                    >
                        {rule1 && rule2 && rule3
                            ? 'Good'
                            : rule1 && rule2 || rule1 && rule3 || rule2 && rule3
                            ? 'Average'
                            : 'Bad'} 
                    </span>
                    <ul>
                        <li>
                            {
                                rule1 ?
                                <img src={GreenTick} alt="GreenTick" />
                                :
                                <img src={RedTick} alt="RedTick" />
                            }
                             At least 8 characters</li>
                        <li>
                            {
                                rule2 ?
                                <img src={GreenTick} alt="GreenTick" /> 
                                :
                                <img src={RedTick} alt="RedTick" /> 
                            }
                            Contain a number or symbol</li>
                        <li>
                            {
                            rule3 ?
                            <img src={GreenTick} alt="GreenTick" />
                            :
                            <img src={RedTick} alt="RedTick" />
                            }
                            Include a mix of uppercase letters & lowercase letters</li>
                    </ul>
                </div>
                <div className="button-container">
                    {/* <Link to="/verify"> */}
                    <button
                    disabled={password && password !== confirmPassword}
                    className="continue-button" 
                    style={{background: !loading && (password && password == confirmPassword) ? '#1E1E1E' : ''}}>{loading && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                          }}
                        >
                          <div className="loader"></div>
                        </div>
                      )} Reset Password</button>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;