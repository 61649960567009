import sustainabilityBg from '../../../../assets/images/century/sustainabilityBg.svg'
const SustainabilitySection = () => {
    return (
        <div className="relative h-screen w-full bg-cover bg-center" style={{ backgroundImage: `url(${sustainabilityBg})` }}>
            <div className="absolute inset-0 bg-opacity-50 flex flex-col md:flex-row h-[70%] items-stretch justify-between px-4 md:max-w-[80%] m-auto">
                <div className='flex-0 md:p-10'>
                    {/* Main Title */}
                    <h1 className="text-white text-4xl md:text-6xl font-bold mb-8 text-start flex-1">
                        Sustainability & <br /> Community Impact
                    </h1>
                </div>


                {/* Option boxes */}
                <div className='flex-1 flex flex-col justify-end items-end md:p-10'>
                    <div className="space-y-4 md:space-y-6 w-full md:w-[4/5]">
                        <div className="bg-black bg-opacity-50 hover:bg-opacity-70 transition-colors duration-300 text-white text-start py-4 px-6 rounded-md"
                            style={{ borderLeft: '2px solid #E5A811' }}>
                            EED-certified buildings, renewable energy installations, and green spaces
                        </div>
                        <div className="bg-black bg-opacity-50 hover:bg-opacity-70 transition-colors duration-300 text-white text-start py-4 px-6 rounded-md"
                            style={{ borderLeft: '2px solid #E5A811' }}>
                            Partnering with local organizations for community development
                        </div>
                        <div className="bg-black bg-opacity-50 hover:bg-opacity-70 transition-colors duration-300 text-white text-start py-4 px-6 rounded-md"
                            style={{ borderLeft: '2px solid #E5A811' }}>
                            Offering affordable housing options
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SustainabilitySection;
