// import image1 from '../../../../assets/images/lusso/success-section/1.png'
// import image2 from '../../../../assets/images/lusso/success-section/2.png'
// import image3 from '../../../../assets/images/lusso/success-section/3.png'
// import image4 from '../../../../assets/images/lusso/success-section/4.png'
import image1 from '../../../../assets/images/lusso/news1.svg'
import image2 from '../../../../assets/images/lusso/news2.svg'
import image3 from '../../../../assets/images/lusso/news3.svg'
import image4 from '../../../../assets/images/lusso/news4.svg'
import blackLine from '../../../../assets/images/evolveEnergy/blackLine.svg'
import playIcon from '../../../../assets/images/lusso/playAnimate.png'
import bg from '../../../../assets/images/lusso/newsbg.svg'
interface SuccessCardType {
    index: number
    image: any
    hasVideo: boolean
    isRight?: boolean
    link: string
}
const SuccessCard = ({ index, image, hasVideo, isRight = false, link }: SuccessCardType) => {
    return (
        <div
            onClick={() => window.open(link, '_blank')}
            className={`relative group overflow-hidden cursor-pointer
                        ${index === 0 && 'md:w-7/12 md:pe-5'} 
                        ${index === 1 && 'md:w-5/12'} 
                        ${index === 2 && 'md:w-5/12 md:pe-5'} 
                        ${index === 3 && 'md:w-7/12'} 
                        pt-10`}
        >
            <img src={image} alt={'image'}
                className={`w-full md:h-full md:object-cover object-contain
                            ${index === 0 && 'md:rounded-tl-2xl'} 
                            ${index === 1 && 'md:rounded-tr-2xl'}
                            h-42`} />
        </div>
    );
};

const NewsSection = () => {
    const successStories = [
        { image: image1, hasVideo: true, link: 'https://coincheckup.com/blog/lusso-labs-a-catalyst-for-your-business-success/' },
        { image: image2, hasVideo: false, link: 'https://techbullion.com/lusso-labs-a-reliable-partner-for-your-business-success/' },
        { image: image3, hasVideo: false, link: 'https://www.thecryptoupdates.com/lusso-labs-helping-your-business-reach-its-potential/' },
        { image: image4, hasVideo: true, isRight: true, link: 'https://cryptomode.com/news/finance/lusso-labs-empowering-your-business-for-growth/' },
    ];

    return (
        <div className='bg-[#010101] text-white'>
            <div className='bg-cover bg-center bg-no-repeat w-full min-h-screen p-8 md:py-[100px]' style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'top' }}>
                <div className='md:max-w-[80%] md:mx-auto'>
                    <h2 className="text-3xl md:text-5xl font-bold text-center uppercase">
                        Lusso.ai in the News
                    </h2>
                    <div className="flex flex-wrap mt-[20px]">
                        {successStories.map((story, index) => (
                            <SuccessCard key={index} index={index} image={story.image} hasVideo={story.hasVideo} isRight={story.isRight} link={story?.link} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default NewsSection